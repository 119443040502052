import sharedApi from './SharedApi.js';

class ConfigurationApi {
    async retrieveConfiguration() {
        try {
            return await sharedApi.get('/api/configuration');
        } catch (error) {
            console.error(error);
            return error.message;
        }
    }

    async uploadFile(file, fileType, onError) {
        return await sharedApi.postSpreadsheet('/api/configuration/uploadFile/' + fileType, file, onError);
    }
}

export default new ConfigurationApi();
