import React from 'react';

function titleCase(str) {
    if (str) {
        return str.trim().toLowerCase().split(' ').map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
        }).join(' ');
    } else {
        return str;
    }
}

export default function PortfolioGridCell(cellData) {
    const id = cellData.data.id;
    var href = "";
    var cellText = "";

    if (cellData.column.name == "shortInstitution") {
        href = '/grantdetails/' + id;
        if (cellData.data.institution === cellData.data.shortInstitution) //TODO temp fix to allow short institution override to display intended case
            cellText = titleCase(cellData.data.shortInstitution);
        else
            cellText = cellData.data.shortInstitution;

        return (
            <div>
                <a href={href}>{cellText}</a>
            </div>
        );
    }
    else if (cellData.column.name == "gms") {
        cellText = titleCase(cellData.data.gms);

        return (
            cellText
        );
    }
    else if (cellData.column.name == "po") {
        cellText = titleCase(cellData.data.po);

        return (
            cellText
        );
    }
};


