import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Button as ReactButton } from 'devextreme-react/button';
import { handleError, FILETYPE, MESSAGES, STEPS, FILEACTION } from '../shared/Utils.js';
import { PHASESTEPS, ROLE } from '../shared/Utils.js';
import { Autocomplete } from 'devextreme-react/autocomplete';
import List from 'devextreme-react/list';
import grantApi from '../../api/GrantApi.js';
import CustomStore from 'devextreme/data/custom_store';
import { Validator, AsyncRule, EmailRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import '../css/GrantDetailsExternalUsers.css';
import Guid from 'devextreme/core/guid';
import { AppContext } from '../shared/AppContext.js';

const GrantDetailsFileUpload = ({ phaseStep, uploadFilesChanged, selectedUploadFiles, setSelectedUploadFiles, grantId, errorMessage, setErrorMessage }) => {
    const context = useContext(AppContext);

    const getUploadFile = (fileType) => {
        let foundFile = selectedUploadFiles.find(f => f.phaseStep.NAME === phaseStep.NAME && f.fileType == fileType && f.fileAction == FILEACTION.NEW);

        if (foundFile === undefined || foundFile == null) {
            foundFile = selectedUploadFiles.find(f => f.phaseStep.NAME === phaseStep.NAME && f.fileType == fileType && f.fileAction == FILEACTION.EXISTING);
        }

        return foundFile;
    }

    const setUploadFile = (phaseStep, fileType, file) => {
        const newSelectedUploadFiles = structuredClone(selectedUploadFiles);

        const addedFileIndex = newSelectedUploadFiles.findIndex(f => f.fileType === fileType && f.fileAction == FILEACTION.NEW);

        if (addedFileIndex > -1) {
            newSelectedUploadFiles.splice(addedFileIndex, 1);
        }

        const fileIndex = newSelectedUploadFiles.findIndex(f => f.fileType === fileType);

        if (fileIndex > -1) {
            newSelectedUploadFiles[fileIndex].fileAction = FILEACTION.DELETE;
        }

        newSelectedUploadFiles.push({ phaseStep, phaseStep, fileType: fileType, file: file, fileAction: FILEACTION.NEW, userId: context.userId });

        setSelectedUploadFiles(newSelectedUploadFiles);
    }

    if (phaseStep === STEPS.A) {
        return (
            <div className="container-fluid panelBody">
                <GrantDetailsFileUploader phaseStep={phaseStep} uploadFilesChanged={uploadFilesChanged} fileLabel={FILETYPE.DRAWINGS} selectedUploadFile={getUploadFile(FILETYPE.DRAWINGS)} setSelectedUploadFile={setUploadFile} fileNameTemplate="Drawings" fileType="application/pdf" allowedFileType="pdf" setErrorMessage={setErrorMessage} />
                <GrantDetailsFileUploader phaseStep={phaseStep} uploadFilesChanged={uploadFilesChanged} fileLabel={FILETYPE.SPECS} selectedUploadFile={getUploadFile(FILETYPE.SPECS)} setSelectedUploadFile={setUploadFile} fileNameTemplate="Specs" fileType="application/pdf" allowedFileType="pdf" setErrorMessage={setErrorMessage} />
                <GrantDetailsFileUploader phaseStep={phaseStep} uploadFilesChanged={uploadFilesChanged} fileLabel={FILETYPE.BOD} selectedUploadFile={getUploadFile(FILETYPE.BOD)} setSelectedUploadFile={setUploadFile} fileNameTemplate="BOD" fileType="application/pdf" allowedFileType="pdf" setErrorMessage={setErrorMessage} />
                <div className="uploadErrorMessage">{errorMessage}</div>
            </div>
        );
    } else if (phaseStep === STEPS.B) {
        return (
            <div className="container-fluid panelBody">
                <GrantDetailsFileUploader phaseStep={phaseStep} uploadFilesChanged={uploadFilesChanged} fileLabel={FILETYPE.REVIEWCOMMENTS} selectedUploadFile={getUploadFile(FILETYPE.REVIEWCOMMENTS)} setSelectedUploadFile={setUploadFile} fileNameTemplate="Review" fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" allowedFileType="xlsx" setErrorMessage={setErrorMessage} />
                <div className="uploadErrorMessage">{errorMessage}</div>
            </div>
        );
    } else if (phaseStep === STEPS.C) {
        return (
            <div className="container-fluid panelBody">
                <GrantDetailsFileUploader phaseStep={phaseStep} uploadFilesChanged={uploadFilesChanged} fileLabel={FILETYPE.AERESPONSE} selectedUploadFile={getUploadFile(FILETYPE.AERESPONSE)} setSelectedUploadFile={setUploadFile} fileNameTemplate="AEResponse" fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" allowedFileType="xlsx" setErrorMessage={setErrorMessage} />
                <div className="uploadErrorMessage">{errorMessage}</div>
            </div>
        );
    } else if (phaseStep === STEPS.D) {
        return (
            <div className="container-fluid panelBody">
                <GrantDetailsFileUploader phaseStep={phaseStep} uploadFilesChanged={uploadFilesChanged} fileLabel={FILETYPE.RESPONSEREVIEW} selectedUploadFile={getUploadFile(FILETYPE.RESPONSEREVIEW)} setSelectedUploadFile={setUploadFile} fileNameTemplate="ResponseReview" fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" allowedFileType="xlsx" setErrorMessage={setErrorMessage} />
                <div className="uploadErrorMessage">{errorMessage}</div>
            </div>
        );
    }
}

export default GrantDetailsFileUpload;


const GrantDetailsFileUploader = ({ phaseStep, uploadFilesChanged, fileLabel, selectedUploadFile, setSelectedUploadFile, fileNameTemplate, fileType, allowedFileType, setErrorMessage }) => {
    const fileId = new Guid().toString();
    const context = useContext(AppContext);

    const changeButtonDisabled = (userId) => {
        return (!context.userPermissions.allowDeleteAll && userId != context.userId);
    }

    const onSelectedFilesChanged = async (e) => {
        const file = e.target.files[0];

        // Reset target so that if the same file is selected again, this event will still fire.
        e.target.value = null;

        if (file.type !== fileType) {
            const permittedFileType = fileType.substring(fileType.indexOf('/') + 1);
            setErrorMessage(allowedFileType + MESSAGES.FILEUPLOADTYPES);
        } else {
            setErrorMessage("");
            setSelectedUploadFile(phaseStep, fileLabel, file);
            uploadFilesChanged(true)
        }
    }

    if (selectedUploadFile) {
        return (
            <div className="fileUploaderRow">
                <input
                    type="file"
                    onChange={onSelectedFilesChanged.bind(this)}
                    height={20}
                    style={{ display: 'none' }}
                    accept={fileType}
                    id={fileId + 'change'}
                />
                <div style={{ width: '510px' }}>
                    <div className="row">
                        <div className="col-4">
                            <b>{fileLabel}</b>
                        </div>
                        <div className="col-5">
                            {selectedUploadFile && <div>   {selectedUploadFile.file.name}</div>}
                        </div>
                        <div className="col-3">
                            <div style={{ float: 'right' }}>
                                <button disabled={changeButtonDisabled(selectedUploadFile.userId)} onClick={() => document.getElementById(fileId + 'change').click()}>
                                    Change File
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="fileUploaderRow">
                <input
                    type="file"
                    onChange={onSelectedFilesChanged.bind(this)}
                    height={20}
                    style={{ display: 'none' }}
                    accept={fileType}
                    id={fileId}
                />
                <div style={{ width: '510px' }}>
                    <div className="row">
                        <div className="col-4">
                            <b>{fileLabel}</b>
                        </div>
                        <div className="col-5">
                            {selectedUploadFile && <span>   {selectedUploadFile.file.name}</span>}
                        </div>
                        <div className="col-3">
                            <div style={{ float: 'right' }}>
                                <button onClick={() => document.getElementById(fileId).click()}>
                                    Choose File
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}