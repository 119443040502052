import sharedApi from './SharedApi.js';

class UserApi {
    async getUserAttr(onError) {
        return sharedApi.get('/api/user/attr', onError);
    }

    async oidcSignInRedirect(configuration, redirectUrl, onError) {
        try {
            const url = configuration.oidcConfig.authority
                + configuration.oidcConfig.authorizationEndpointUrl
                + '?client_id=' + configuration.oidcConfig.clientId
                + '&response_type=code'
                + '&state=' + redirectUrl
                + '&redirect_uri=' + encodeURIComponent(sharedApi.trim(document.location.origin, '/') + '/' + sharedApi.trim(configuration.oidcConfig.clientCallbackurl, '/'))
                + '&scope=' + configuration.oidcConfig.scope.replace(/[ ]/g, '+');

            document.location.href = url;
        } catch (error) {
            console.error(error);
            return error.message;
        }
    }

    async processOIDCAuth(config, code, onError) {
        try {
            const authPayload = {
                Code: code,
                RedirectUrl: sharedApi.trim(document.location.origin, '/') + '/' + sharedApi.trim(config.oidcConfig.clientCallbackurl, '/')
            };

            const response = await sharedApi.post('/api/oidc/authorize', authPayload, true, onError);

            if (response) {
                if (response.oidcIdToken) {
                    this.setIDToken(response.oidcIdToken);
                }

                if (response.accessToken) {
                    return response.accessToken;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.error(error);
            return error.message;
        }
    }

    async postOIDCLoginRequest(url, formBody, onError) {
        try {
            const settings = {
                method: 'POST',
                body: formBody,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            };

            const response = await fetch(url, settings);

            if (response.ok) {
                return (await response.json());
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        } finally {
            //console.log(`fetch finished`);
        }
    }

    async logoutOIDC(token) {
        try {
            const url = sharedApi.generateURL(`/api/oidc/logout`);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token })
            });

            if (!response.ok) {
                return response.statusText;
            }


            const data = await response.json();

            if (data.isValid) {
                return '';
            } else {
                console.error(data);
                return data.errorMessage;
            }
        } catch (error) {
            console.error(error);
            return error.message;
        }
    }

    async checkUserOIDC(token) {
        try {
            const url = sharedApi.generateURL(`/api/oidc/userInfo`);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token })
            });

            if (!response.ok) {
                return response.statusText;
            }

            const data = await response.json();
     
            if (data.isValid) {
                const userName = data.userName;

                localStorage.setItem('token', JSON.stringify({ userName, token: data.token }));
                return '';
            } else {
                console.error(data);
                return data.errorMessage;
            }
        } catch (error) {
            console.error(error);
            return error.message;
        }
    }

    async checkUserName(userName) {
        try {
            const url = sharedApi.generateURL(`/api/user/info/${userName}`);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                return response.statusText;
            }

            const data = await response.json();

            if (data.isValid) {
                localStorage.setItem('token', JSON.stringify({ userName, token: data.token }));
                return '';
            } else {
                console.error(data);
                return data.errorMessage;
            }
        } catch (error) {
            console.error(error);
            return error.message;
        }
    }

    async checkGuid(guid) {
        try {
            const url = sharedApi.generateURL(`/api/guid/${guid}`);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                return response.statusText;
            }

            const data = await response.json();

            if (data.isValid) {
                localStorage.setItem('token', JSON.stringify({ guid, token: data.token }));
                return '';
            } else {
                console.error(data);
                return data.errorMessage;
            }
        } catch (error) {
            console.error(error);
            return error.message;
        }
    };

    clearToken() {
        try {
            localStorage.setItem('token', JSON.stringify({ userName: "", token: "" }));
        } catch (error) {
            console.error(error);
        }
    }

    getAccessToken() {
        const user = JSON.parse(localStorage.getItem('token'));
        return user ? user.token : null;
    }

    getIDToken() {
        const idToken = localStorage.getItem('id_token');
        return idToken ? idToken : null;
    }

    setIDToken(id_Token) {
        localStorage.setItem('id_token', id_Token);
    }

}

export default new UserApi();
