import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Popup } from 'devextreme-react/popup';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { AppContext } from './AppContext.js';
import Header from './Header';
import NavMenu from './NavMenu';
import userApi from '../../api/UserApi.js';
import configurationApi from '../../api/ConfigurationApi.js';
import { Button as ReactButton } from 'devextreme-react/button';
import { Toast } from 'devextreme-react/toast';
import '../css/Layout.css';

const Layout = (props) => {
    const pathname = useLocation().pathname.toLowerCase();
    const originalPath = useLocation().pathname;
    const hash = useLocation().hash;

    const navigate = useNavigate();

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'error',
        displayTime: 5000,
        message: '',
    });

    const onHiding = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    const [userName, setUserName] = useState('');

    const [state, setState] = useState({
        configuration: {},
        loginUser: undefined,
        userId: undefined,
        useRole:0,
        menuKind: 'none',
    });

    const [login, setLogin] = useState({
        formVisible: false,
        password: '',
        userName: '',
        passMode: 'password',
    });

    const [popupMessage, setPopupMessage] = useState({ message: '', class: 'row popupRow hideMessage', height: 260 });
    const [url, setUrl] = useState(pathname);
    const [errorMessage, setErrorMessage] = useState("");

    const popupRef = React.createRef();

    useEffect(() => {
        if (errorMessage) { onError(errorMessage); }
    }, [errorMessage]);

    useEffect(() => {
        popupRef.current.instance.registerKeyHandler('enter', function (e) {
            onOkClick(e);
        });

    }, []);

    useEffect(() => {
        const splited = pathname.split('/');

        if (pathname == "/error") {
            setState({ menuKind: 'none', configuration: {} });
            return;
        }

        if (pathname.startsWith("/grantdetails/")) {
            getUser(pathname + hash);
            return;
        }

        if (pathname.startsWith("/configuration")) {
            getUser(pathname);
            return;
        }

        if (pathname.startsWith("/portfolio")) {
            getUser(pathname);
            return;
        }

        if (pathname.startsWith("/dashboard")) {
            getUser(pathname);
            return;
        }

        if (splited.length > 2) {
            userApi.clearToken();

            if (splited[1] === 'guid') {
                const guid = splited[2];
                checkGuid(guid);
                return;
            }

            if (splited[1] === 'testlogin') {
                const userName = splited[2];
                checkUser(userName, pathname);
                return;
            }

            if (splited[1] === 'token') {
                // Logging in via OIDC with token
                const token = originalPath.split('/')[2];
                const url = originalPath.split('/')[3];

                if (url && url !== undefined && url !== 'undefined') {
                    const decodedUrl = decodeURIComponent(url);
                    checkUserOIDC(token, decodedUrl);
                } else {
                    checkUserOIDC(token);
                }

                return;
            }

            if (splited[2] !== 'oidc-callback' && splited[1] !== 'token' && splited[1] !== 'grantdetails') {
                setErrorMessage("User name is not recognized.");
            }

        } else {
            if (pathname == "/logout") {
                setState({ menuKind: 'none', configuration: {} });
                setUrl(pathname);
                return;
            }

            if (pathname == "/oidcerror") {
                userApi.clearToken();
                hideForm();
                navigate('/error');
                return;
            }

            if (pathname == "/oidcnotfound") {
                userApi.clearToken();
                hideForm();
                navigate('/oidcnotfound');
                return;
            }

            if (pathname == "/" || pathname == "/portfolio") {
                if (userApi.getAccessToken()) {
                    getUser(pathname).then(res => {
                        if (res)
                            navigate('/portfolio');
                        else
                            doLogin();

                    });
                    
                } else {
                    doLogin();
                }

                return;
            }

            if (!state.loginUser) {
                getUser(pathname);
                return;
            }
            if (state.loginUser && !state.siteReady) {
                navigate(pathname);
            }
            setUrl(pathname);
        }
    }, [pathname]);

    const doLogin = async (path) => {
        let config = state.configuration;

        if (!state.configuration.oidcConfig) {
            config = await configurationApi.retrieveConfiguration();

            setState({
                ...state,
                configuration: config
            });
        }

        if (config.useNIHLogin) {
            userApi.oidcSignInRedirect(config, encodeURIComponent(path), onError);
        } else {
            showForm();
        }
    };

    const checkGuid = async (guid, path) => {
        const result = await userApi.checkGuid(guid);
        if (!result) {
            processingUser(path);
        } else {
            setErrorMessage(result);
        }
    }

    const checkUserOIDC = async (token, path) => {
        if (token && token !== '') {
            const result = await userApi.checkUserOIDC(token);

            if (!result) {
                processingUser(path);
            } else if (result === 'Not Found') {
                navigate('/oidcnotfound');
            } else {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    message: result,
                });
            }
        }
    }

    const checkUser = async (userName, path) => {
        if (userName && userName !== '') {
            const result = await userApi.checkUserName(userName);

            if (!result) {
                processingUser(path);
            } else {
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    message: result,
                });
            }
        }
    }

    const checkUserLoggedIn = async () => {
        const userAttr = await userApi.getUserAttr(onError);

        return (userAttr && userAttr.userInfo);
    }

    const getUser = async (path) => {
        const userAttr = await userApi.getUserAttr();

        if (userAttr && userAttr.userInfo && userAttr.userInfo.fullName) {
            const userInfo = userAttr.userInfo;
            const menuKind = getMenuKind(pathname, userInfo.permissions);
            const userName = (userAttr.userInfo.userName != null) ? userAttr.userInfo.userName : userAttr.userInfo.email;

            setPopupMessage({ message: '', class: 'row popupRow hideMessage', height: 260 });

            setState({
                ...state,
                loginUser: (userInfo.fullName != null && userInfo.fullName.trim() != '') ? userInfo.fullName : userInfo.email,
                userEmailAddress: userInfo.email,
                userName: userName,
                userId: userInfo.userId,
                userRole: userInfo.role,
                userProfiles: userInfo.userProfiles,
                userPermissions: userInfo.permissions,
                externalUserRoles: userInfo.externalUserRoles,
                menuKind
            });

            setUrl(pathname);
            return true;
        } else {
            doLogin(path).then(res => {
                if (res) {
                    getUser(path);
                }
            });
        }
        return false;
    }

    const onError = async (error) => {
        const message = handleError(error);


        if (error == "HTTP error: 401") {
            setPopupMessage({ message: 'Please log in to continue', class: 'row popupRow showMessage', height: 280 });

            navigate('/', { state: { props: 'Please log in to continue' } });
        } else {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: error,
            });
        }
    }

    const handleError = (error) => {
        if (error == "HTTP error: 401") {
            return "Error authenticating user."
        }  

        if (error == "Bad Request") {
            return "Bad Request."
        } 
        return error;
    }

    const getMenuKind = (pathname, permissions) => {
        switch (pathname) {
            case "/logout":
                return 'none';

            default:
                return (permissions.allowConfiguration) ? 'admin' : 'main';
        }
    }

    const getNextPage = (path) => {
        if (path !== undefined && path.startsWith("/grantdetails/")) {
            return path + hash;
        }

        if (path !== undefined) {
            return path;
        } else {
            return '/portfolio';
        }
    }

    const processingUser = async (path) => {
        const userAttr = await userApi.getUserAttr(onError);

        if (!userAttr) {
            return;
        }
        const userInfo = userAttr.userInfo;
        const userName = (userAttr.userInfo.userName != null) ? userAttr.userInfo.userName : userAttr.userInfo.email;
        const nextPage = getNextPage(path);
        const menuKind = getMenuKind(nextPage, userInfo.permissions);

        setState({
            ...state,
            loginUser: (userInfo.fullName != null && userInfo.fullName.trim() != '') ? userInfo.fullName : userInfo.email,
            userEmailAddress: userInfo.email,
            userName: userName,
            userId: userInfo.userId,
            userRole: userInfo.role,
            userProfiles: userInfo.userProfiles,
            userPermissions: userInfo.permissions,
            externalUserRoles: userInfo.externalUserRoles,
            siteReady: true, 
            menuKind
        });

        setPopupMessage({ message: '', class: 'row popupRow hideMessage', height: 260 });

        hideForm();
        navigate(nextPage);
        setUrl(pathname);
    }

    const showForm = () => {
        setLogin({
            password: '',
            formVisible: true,
        });
    }

    const hideForm = () => {
        setLogin({
            password: '',
            formVisible: false,
        });
    }

    const passRef = useRef(null);

    const onPassChanged = (e) => {
        setLogin({
            ...login,
            password: e.value,
        });
    }

    const onChangeMode = () => {
        setLogin({
            ...login,
            passMode: (login.passMode === 'text' ? 'password' : 'text'),
        });
    }

    const onCancelClick = () => {
        userApi.clearToken();
        hideForm();
        navigate('/logout');
    }

    const cancelFormOptions = {
        text: 'Cancel',
        onClick: onCancelClick,
    };

    const onUseNameChanged = useCallback((data) => {
        setUserName(data);
    }, []);


    const onOkClick = (e) => {
        checkUser(userName.value, pathname);
    };

    const onKeyDown = (e) => {
        if (e.event.originalEvent.key === 'Enter') {
            if (e.event.currentTarget.name == 'password') {
                checkUser(userName.value, pathname);
            } else {
                checkUser(e.event.currentTarget.value, pathname);
            }
            
        }
    };

    return (
        <div style={{ background: '#fff' }} >
            <Header user={state.loginUser}>
                <NavMenu kind={state.menuKind} url={url} />
            </Header>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={1000}
            />
            <Popup
                ref={popupRef}
                visible={login.formVisible}
                onHiding={hideForm}
                dragEnabled={false}
                position="center"
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Welcome to eCamera"
                width={400}
                height={popupMessage.height}>
                <div className="container-fluid formReqPanelTop">
                    <div className={popupMessage.class}>
                        <div className="dx-field col-12">
                            <span style={{ marginLeft: '60px', color: 'red', fontSize: '12pt' }}> {popupMessage.message}</span>
                        </div>
                    </div>
                    <div className="row popupRow">
                        <div className="dx-field col-12">
                            <div className="dx-field-label">
                                User Name:
                            </div>
                            <div className="popup-name-value">
                                <TextBox 
                                    onKeyDown={onKeyDown}
                                    onValueChanged={onUseNameChanged}
                                >
                                </TextBox>
                            </div>
                            <div className="dx-field-label">
                                Password:
                            </div>
                            <div className="popup-name-value">
                                <TextBox
                                    name='password'
                                    onKeyDown={onKeyDown}
                                    mode="password"
                                >
                                </TextBox>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="container-fluid formReqPanelTop">
                    <div className="row popupRow">
                        <div className="dx-field col-4">
                            <ReactButton
                                width={100}
                                text="Ok"
                                onClick={onOkClick} />
                        </div>
                    </div>
                </div>
            </Popup>
            <Container tag="main">
                <AppContext.Provider value={{
                    loginUser: state.loginUser,
                    userEmailAddress: state.userEmailAddress,
                    userId: state.userId,
                    userName: state.userName,
                    userRole: state.userRole,
                    userProfiles: state.userProfiles,
                    userPermissions: state.userPermissions,
                    externalUserRoles: state.externalUserRoles
                }}>
                    {props.children}
                </AppContext.Provider>
            </Container>
        </div>
    );
}

export default Layout;
