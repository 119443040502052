import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { handleError, STATUS, DATES, PHASES, TIMELINE } from '../shared/Utils.js';
import { Chart } from 'react-google-charts';
import { Slider, Label, Tooltip } from 'devextreme-react/slider';
import $ from 'jquery';
import { Button } from 'devextreme-react/button';

const GrantDetailsTimeline = ({ phaseData }) => {
    const [state, setState] = useState({});
    const [showLabels, setShowLabels] = useState(false);
    const [timelineData, setTimelineData] = useState({});
    const chartWidth = 1430;
    const timelineMin = (phaseData.schematicDesign.submission) ? new Date(phaseData.schematicDesign.submission) : new Date(phaseData.schematicDesign.Start);
    const timelineMax = new Date(phaseData.construction.Projected);

    const getPhaseStatus = (start, projected, Submission, Completion) => {
        // Status date is today or Completion date, whichever is earlier
        let statusDate = moment();
        let state = STATUS.SCHEDULED;
        let endDate = projected;

        // Status date is today or Completion, whichever is earlier
        if (Completion) {
            state = STATUS.COMPLETED;
        } else {
            if (Submission) {
                if (moment().startOf('day').isAfter(projected)) {
                    const duration = moment().startOf('day').diff(moment(projected), 'months', true);
                    state = (duration <= 2) ? STATUS.SLIGHTLYLATE : STATUS.LATE;
                } else {
                    state = STATUS.INPROGRESS;
                }
            }
        }

        return {
            start: start,
            end: endDate,
            state: state
        };
    }

    const getPhaseRows = (phaseName, start, projected, Submission, Completion) => {
        let phaseRows = [];

        // Start Date
        let startDate = new Date(start);
        if (Submission) {
            startDate = new Date(Submission);
        }

        // End Date
        let endDate = null;

        if (Completion) {
            endDate = new Date(Completion);
        } else if (Submission && moment().isAfter(moment(projected))) {
            endDate = new Date();
        } else {
            endDate = new Date(projected);
        }

        // Handle case where start date is after end date
        if (startDate > endDate) {
            endDate = startDate;
        }

        // Get Phase Status
        let phaseStatus = getPhaseStatus(start, projected, Submission, Completion);

        if (phaseStatus.state == STATUS.COMPLETED) {
            const row = [phaseName, phaseStatus.state, null, startDate, endDate];
            phaseRows.push(row);
        } else if (phaseStatus.state == STATUS.SCHEDULED) {
            const row = [phaseName, phaseStatus.state, null, startDate, endDate];
            phaseRows.push(row);
        } else if (phaseStatus.state == STATUS.INPROGRESS) {
            if (start && !Completion && moment().isBefore(projected)) {
                const row1 = [phaseName, phaseStatus.state, null, startDate, (moment().isBefore(startDate)) ? startDate : new Date()];
                const row2 = [phaseName, STATUS.SCHEDULED, null, (moment().isBefore(startDate)) ? startDate : new Date(), endDate];
                phaseRows.push(row1);
                phaseRows.push(row2);
            } else {
                const row = [phaseName, phaseStatus.state, null, startDate, endDate];
                phaseRows.push(row);
            }

        } else if (phaseStatus.state == STATUS.SLIGHTLYLATE || phaseStatus.state == STATUS.LATE) {
            // We need two blocks
            const row = [phaseName, phaseStatus.state, null, startDate, endDate];
            phaseRows.push(row);
        }

        return phaseRows;
    }

    const setTimelineTasks = () => {
        let phaseRows = [];
        let skipSD = false;
        let skipDD = false;
        let cdSubmission = phaseData.constructionDocuments.submission;
        let ddSubmission = phaseData.designDevelopment.submission;

        if (phaseData.schematicDesign.Start) {
            // Check for exception cases
            if ((moment(phaseData.schematicDesign.submission).format(DATES.FORMAT) == moment(phaseData.schematicDesign.completion).format(DATES.FORMAT)) &&
                phaseData.schematicDesign.submission &&
                phaseData.schematicDesign.completion) {
                // combine SD and DD
                skipSD = true;

                ddSubmission = phaseData.schematicDesign.Start;
            }

            if ((moment(phaseData.designDevelopment.submission).format(DATES.FORMAT) === moment(phaseData.designDevelopment.completion).format(DATES.FORMAT)) &&
                phaseData.designDevelopment.submission && phaseData.designDevelopment.completion) {
                // combine DD and CD
                skipDD = true;
                cdSubmission = phaseData.designDevelopment.Start;
            }

            if ((moment(phaseData.schematicDesign.submission).format(DATES.FORMAT) === moment(phaseData.schematicDesign.completion).format(DATES.FORMAT)) &&
                (moment(phaseData.designDevelopment.submission).format(DATES.FORMAT) === moment(phaseData.designDevelopment.completion).format(DATES.FORMAT)) &&
                phaseData.schematicDesign.submission &&
                phaseData.schematicDesign.completion &&
                phaseData.designDevelopment.submission &&
                phaseData.designDevelopment.completion) {
                // combine SD DD and CD
                skipSD = true;
                skipDD = true;

                cdSubmission = phaseData.schematicDesign.Start;
            }

            let newGrantDetailsTimelineData = [
                [
                    { type: "string", label: "Status" },
                    { type: "string", label: "Name" },
                    { type: "string", role: "tooltip" },
                    { type: "date", label: "Start" },
                    { type: "date", label: "End" },
                ]];

            // Schematic Design
            if (!skipSD) {
                phaseRows = getPhaseRows(PHASES.SD.NAME, phaseData.schematicDesign.Start, phaseData.schematicDesign.Projected, phaseData.schematicDesign.submission, phaseData.schematicDesign.completion);
                newGrantDetailsTimelineData.push(...phaseRows);
            }

            // Desin Development
            if (!skipDD) {
                phaseRows = getPhaseRows(PHASES.DD.NAME, phaseData.designDevelopment.Start, phaseData.designDevelopment.Projected, ddSubmission, phaseData.designDevelopment.completion);
                newGrantDetailsTimelineData.push(...phaseRows);
            }

            // Construction Documents
            phaseRows = getPhaseRows(PHASES.CD.NAME, phaseData.constructionDocuments.Start, phaseData.constructionDocuments.Projected, cdSubmission, phaseData.constructionDocuments.completion);
            newGrantDetailsTimelineData.push(...phaseRows);

            // Bidding
            phaseRows = getPhaseRows(PHASES.B.NAME, phaseData.bidding.Start, phaseData.bidding.Projected, phaseData.bidding.submission, phaseData.bidding.completion);
            newGrantDetailsTimelineData.push(...phaseRows);

            // Construction
            phaseRows = getPhaseRows(PHASES.C.NAME, phaseData.construction.Start, phaseData.construction.Projected, phaseData.construction.submission, phaseData.construction.completion);
            newGrantDetailsTimelineData.push(...phaseRows);

            setTimelineData(newGrantDetailsTimelineData);
        }
    }

    useEffect(() => {
        setState({
            tasks: [{}],
            phasesOptions: {
                text: 'Show Phases',
                stylingMode: 'text',
                showPhases: false
            },
            sliderValue: chartWidth,
            google: null,
            options: {
                height: 275,
                width: chartWidth,
                enableInteractivity: false,
                timeline: {
                    groupByRowLabel: true,
                    colorByRowLabel: false,
                    showBarLabels: false,
                    showRowLabels: false,
                },
                alternatingRowStyle: false,
                tooltip: {
                    isHtml: true,
                },
                colors: [TIMELINE.FONTCOLOR],
                fontName: TIMELINE.FONTNAMES,
                hAxis: {
                    minValue: timelineMin,
                    maxValue: timelineMax
                }
            }
        });

        setTimelineTasks();

    }, [phaseData]);

    let getBarData = (chartData, index) => {
        return chartData[index].c;
    }

    let getRowCount = (chartData) => {
        let phaseName = "";
        let rowCount = 0;

        for (let i = 1; i < chartData.length; i++) {
            if (chartData[i].c[0].v != phaseName) {
                rowCount++;
                phaseName = chartData[i].c[0].v;
            }
        }

        return rowCount;
    }

    let getChartStart = (chartData) => {
        return moment(chartData[0].c[3].v).format(DATES.FORMAT);
    }

    let getChartEnd = (chartData) => {
        return moment(chartData[0].c[3].v).add(56, 'M').format(DATES.FORMAT);
    }

    const chartEvents = [
        {
            eventName: 'ready',
            callback({ chartWrapper }) {
                const chartData = chartWrapper.Bea.Wf;

                // Add vertical blue line and current date
                let chartSVGs = $(TIMELINE.SVG);
                let chartSVG = chartSVGs[0];
                var container = document.getElementById('chart_div');

                const startDate = getChartStart(chartData);
                const endDate = getChartEnd(chartData);

                let timeRange = moment(endDate).diff(moment(startDate));
                let timeRangeDays = moment(endDate).diff(moment(startDate), 'days', true);
                let today = moment().diff(moment(startDate));
                let todayDays = moment().diff(moment(startDate), 'days', true) + (moment().date() - moment(startDate).date());
                let chartWidthViewable = chartWrapper.m.width;
                let chartTicks = chartWidthViewable / timeRangeDays;
                let offset = todayDays * chartTicks - 10;

                let pathX = offset + (TIMELINE.PATHXOFFSET * chartWidthViewable);

                if (chartWrapper.m.timeline.showRowLabels) {
                    pathX += 123;
                }

                // Blue Line
                const rowCount = getRowCount(chartData) + 1;

                let newPath = document.createElementNS(TIMELINE.W3SVGURL, "path");
                newPath.setAttribute("d", "M " + pathX + " 0 L " + pathX + " " + (rowCount * 50));
                newPath.style.stroke = "blue";
                newPath.style.strokeWidth = "1";
                newPath.style.fillOpacity = "1";
                newPath.style.fill = "none";
                chartSVG.appendChild(newPath);

                // Date
                let newText = document.createElementNS(TIMELINE.W3SVGURL, "text");
                newText.textContent = moment().format(DATES.FORMAT);

                if (today < timeRange / 2) {
                    newText.setAttribute("x", pathX + 10);
                } else {
                    newText.setAttribute("x", pathX - 70);
                }

                newText.setAttribute("y", (rowCount * 54));
                newText.style.fill = TIMELINE.CURRENTDATECOLOR;
                newText.style.fontSize = TIMELINE.CURRENDATEFONTSIZE;
                newText.style.fontWeight = TIMELINE.CURRENDATEFONTWEIGHT;
                newText.style.fontFamily = TIMELINE.CURRENDATEFONT;
                chartSVG.appendChild(newText);

                // Set Phase Data
                var rects = container.getElementsByTagName('rect');

                const showTooltip = (e) => {
                    // Get tooltip
                    const tooltip = document.querySelector(TIMELINE.TOOLTIPSELECTOR);

                    // Position tooltip
                    var x = e.clientX, y = e.clientY;

                    tooltip.style.top = (y - 195) + 'px';
                    tooltip.style.left = (x - 100 - ((x > 1450) ? 220 : 0)) + 'px';

                    // Set tooltip contents
                    let phaseName = e.target.getAttribute('phaseName');
                    let phaseState = e.target.getAttribute('state');
                    let start = e.target.getAttribute('start');
                    let end = e.target.getAttribute('end');

                    tooltip.innerHTML = "<div style='" + TIMELINE.TOOLTIPSTYLE + "'><div><b>" + phaseName + "</b></div><br /><div>" + phaseState + "</div><div>" + moment(start).format(DATES.FORMAT) + " to " + moment(end).format(DATES.FORMAT) + "</div></div>";

                    // Show tooltip
                    tooltip.style.visibility = 'visible';
                }

                const hideTooltip = (e) => {
                    const tooltip = document.querySelector(".phaseTipText");
                    tooltip.style.visibility = 'hidden';
                }

                let dataIndex = 0;
                Array.prototype.forEach.call(rects, function (rect, index) {
                    if (dataIndex < chartData.length) {
                        // Set fill color
                        if (rect.getAttribute('fill') === TIMELINE.FONTCOLOR) {
                            const barData = getBarData(chartData, dataIndex++);

                            if (barData) {
                                const phaseName = barData[0].v;
                                const state = barData[1].v
                                const toolTip = barData[2].v;
                                const start = barData[3].v;
                                const end = barData[4].v;

                                rect.setAttribute('phaseName', phaseName);
                                rect.setAttribute('state', state);
                                rect.setAttribute('start', start);
                                rect.setAttribute('end', end);

                                rect.addEventListener('mouseover', function (e) {
                                    e.stopPropagation();
                                    showTooltip(e);
                                });

                                rect.addEventListener('mouseout', function (e, index) {
                                    e.stopPropagation();
                                    hideTooltip(e);
                                });


                                let stateColor = "";

                                console.log(phaseName);
                                console.log(state);
                                switch (state) {
                                    case STATUS.COMPLETED:
                                        stateColor = TIMELINE.COMPLETEDCOLOR;
                                        break;

                                    case STATUS.SLIGHTLYLATE:
                                        stateColor = TIMELINE.SLIGHTLYLATECOLOR;
                                        break;

                                    case STATUS.LATE:
                                        stateColor = TIMELINE.LATECOLOR;
                                        break;

                                    case STATUS.INPROGRESS:
                                        stateColor = TIMELINE.INPROGRESSCOLOR;
                                        break;

                                    case STATUS.SCHEDULED:
                                        stateColor = TIMELINE.SCHEDULEDCOLOR;
                                        break;

                                }

                                rect.setAttribute('fill', stateColor);
                            }
                        }
                    }
                })

                // Add Phase Label
                var toContainer = $(TIMELINE.SVG);
                var toolTipG = toContainer[0].lastChild;
                let i = 0;
                let label = '';

                let newG = document.createElementNS(TIMELINE.W3SVGURL, "g");
                newG.style.transformOrigin = "0 0";
                toolTipG.parentNode.insertBefore(newG, toolTipG.previousSibling);

                let savedY = 0;
                let savedPhaseName = "";
                dataIndex = 0;

                $("#chart_div g:eq(3) rect").each(function () {
                    if (dataIndex < chartData.length) {
                        const rectY = parseInt($(this).attr('y'));

                        if (rectY != savedY) {
                            const x = parseInt($(this).attr('x')) + 5;
                            const y = parseInt($(this).attr('y')) + 18;
                            const w = parseInt($(this).attr('width')) - 10;

                            let newText = document.createElementNS(TIMELINE.W3SVGURL, "text");
                            newText.setAttribute('x', x);
                            newText.setAttribute('y', y);
                            newText.setAttribute('dx', '0px');
                            newText.setAttribute('fill', '#000000');

                            newText.style.cursor = 'default';
                            newText.style.pointerEvents = 'none';

                            const barData = getBarData(chartData, dataIndex++);

                            if (barData) {
                                const phaseName = barData[0].v;
                                const state = barData[1].v
                                const start = barData[3].v;
                                const end = barData[4].v;

                                newText.setAttribute('phaseName', phaseName);
                                newText.setAttribute('state', state);
                                newText.setAttribute('start', start);
                                newText.setAttribute('end', end);
                                newText.textContent = phaseName;

                                newText.addEventListener('mouseover', function (e) {
                                    e.stopPropagation();
                                    showTooltip(e);
                                });

                                newText.addEventListener('mouseout', function (e, index) {
                                    e.stopPropagation();
                                    hideTooltip(e);
                                });


                                if (phaseName != savedPhaseName) {
                                    newG.appendChild(newText);
                                    savedPhaseName = phaseName;
                                }
                            }
                        } else {
                            dataIndex++;
                        }

                        if (savedY === 0 || savedY !== rectY) {
                            savedY = rectY;
                            i++;
                        }
                    }
                });
            }
        }
    ];

    const showHideLabels = () => {
        let updatedOptions = state.options;
        updatedOptions.timeline.showRowLabels = !showLabels

        setState({
            ...state,
            options: updatedOptions
        });

        setShowLabels(!showLabels);
    }

    const getTooltip = (phaseName, status, start, projected, Submission, Completion) => {
        return null;
        const end = (Completion) ? Completion : projected;
        return "<div style='" + TIMELINE.TOOLTIPSTYLE + "'><div><b>" + phaseName + "</b></div><br /><div>" + status + "</div><div>" + moment(start).format("MM-DD-YYYY") + " to " + moment(end).format("MM-DD-YYYY") + "</div></div>";
    }

    const onSliderValueChanged = (e) => {
        setState({
            ...state,
            sliderValue: e.value
        });

        const newValue = (6000 - chartWidth) * e.value * .01;

        let updatedOptions = state.options;
        updatedOptions.width = chartWidth + newValue;

        setState({
            ...state,
            options: updatedOptions
        });
    }

    return (
        <div className="container-fluid panelBody">
            <div className="phaseTip">
                <span className="phaseTipText"></span>
            </div>
            <div className="row grantVisualizationRow">
                <div className="dx-field col-12">
                    <br />
                    <Slider
                        min={0}
                        max={100}
                        defaultValue={0}
                        onValueChanged={onSliderValueChanged.bind(this)}
                    >
                    </Slider>
                    <div className="widget-container" id="chart_div" style={{ overflowX: 'auto', overflowY: 'hidden' }}>

                        <br style={{ marginBottom: '10%' }} />
                        <Button
                            className="ganttLabelButton"
                            width={155}
                            height={25}
                            text="Show/Hide Labels"
                            type="normal"
                            stylingMode="contained"
                            onClick={showHideLabels.bind(this)} />
                        <div id="chartCaptions"></div>
                        <Chart
                            chartType="Timeline"
                            height="50%"
                            data={timelineData}
                            options={state.options}
                            chartEvents={chartEvents}
                        />
                    </div>
                    <div id="chartLegend" style={{ height: 35 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="none" strokeWidth="0" className="dxc dxc-chart" width="1016" height="400" style={{ lineHeight: 'normal', userSelect: 'none', display: 'block', overflow: 'hidden' }} direction="ltr">
                            <defs>
                                <filter id="DevExpress_1" x="-50%" y="-50%" width="200%" height="200%" transform="translate(0,0)">
                                    <feGaussianBlur in="SourceGraphic" result="gaussianBlurResult" stdDeviation="3"></feGaussianBlur>
                                    <feOffset in="gaussianBlurResult" result="offsetResult" dx="2" dy="6"></feOffset>
                                    <feFlood result="floodResult" floodColor="#d3d3d3" floodOpacity="0.8"></feFlood>
                                    <feComposite in="floodResult" in2="offsetResult" operator="in" result="compositeResult"></feComposite>
                                    <feComposite in="SourceGraphic" in2="compositeResult" operator="over"></feComposite>
                                </filter>
                                <clipPath id="DevExpress_2">
                                    <rect x="0" y="0" width="1016" height="400" transform="translate(0,0)"></rect>
                                </clipPath>
                                <clipPath id="DevExpress_4">
                                    <rect x="-137" y="-10" width="961" height="57" transform="translate(0,0)"></rect>
                                </clipPath>
                                <clipPath id="DevExpress_5">
                                    <rect x="484" y="51" width="525" height="244" transform="translate(0,0)"></rect>
                                </clipPath>
                                <clipPath id="DevExpress_6">
                                    <rect x="484" y="51" width="525" height="244" transform="translate(0,0)"></rect>
                                </clipPath>
                            </defs>
                            <g className="dxc-legend" clipPath="url(#DevExpress_2)" transform="translate(0,0)">
                                <g transform="translate(520,0)">
                                    <g className="dxc-item" style={{ fill: "rgb(118, 118, 118)", fontFamily: 'Segoe UI', fontWight: 400, fontSize: 12 }}>
                                        <g>
                                            <g className="dxl-marker" transform="translate(22,20)" visibility="visible">
                                                <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="#83D18E" opacity="1"></rect>
                                            </g>
                                            <text x="0" y="0" transform="translate(27,13)" textAnchor="middle" style={{ whiteSpace: "pre" }} >Completion</text>
                                        </g>
                                        <g>
                                            <g className="dxl-marker" transform="translate(110,20)" visibility="visible">
                                                <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="yellow" opacity="1"></rect>
                                            </g>
                                            <text x="0" y="0" transform="translate(110,13)" textAnchor="middle" style={{ whiteSpace: "pre" }}>Slightly Late</text>
                                        </g>
                                        <g>
                                            <g className="dxl-marker" transform="translate(200,20)" visibility="visible">
                                                <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="#C80112" opacity="1"></rect>
                                            </g>
                                            <text x="0" y="0" transform="translate(205,13)" textAnchor="middle" style={{ whiteSpace: "pre" }}>Late (> 2 months)</text>
                                        </g>
                                        <g>
                                            <g className="dxl-marker" transform="translate(290,20)" visibility="visible">
                                                <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="#f2a600" opacity="1"></rect>
                                            </g>
                                            <text x="0" y="0" transform="translate(297,13)" textAnchor="middle" style={{ whiteSpace: "pre" }}>In Progress</text>
                                        </g>
                                        <g>
                                            <g className="dxl-marker" transform="translate(370,20)" visibility="visible">
                                                <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="#d3d3d3" opacity="1"></rect>
                                            </g>
                                            <text x="0" y="0" transform="translate(377,13)" textAnchor="middle" style={{ whiteSpace: "pre" }}>Scheduled</text>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );

    const onError = async (error) => {
        const message = handleError(error);
        props.navigate('/error', { state: { props: message } });
    }
}

export default GrantDetailsTimeline;

