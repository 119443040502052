import moment from 'moment';
import sharedApi from './SharedApi.js';
import userApi from './UserApi.js';

class GrantApi {
    async getGrants(onError) {
        return sharedApi.get('/api/grantsList', onError);
    }

    async getGrantData(id, onError) {
        return sharedApi.get('/api/grant/data/' + id, onError);
    }

    getGrantExternalUsersFiltered(grantId, role, skip, take, filter, onError) {
        return fetch(sharedApi.generateURL('api/grant/data/allexternalusers/') + grantId + '/' + role + '/' + skip + '/' + take + '/' + filter, {
                headers: {
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                }
            })
            .then((response) => response.json())
            .then((data) => ({
                data: data.data,
            }))
            .catch(() => {
                throw new Error('Data Loading Error');
            });
    }

    
    async externalUserEmailExists(emailAddress, grantId, externalUserRole, onError) {
        return sharedApi.get('/api/grant/data/externaluseremailexists/' + emailAddress + '/' + grantId + '/' + externalUserRole, onError);
    }

    async getGrantExternalUsers(grantId, onError) {
        return sharedApi.get('/api/grant/data/externalusers/' + grantId, onError);
    }

    async deleteGrantFile(fileId, onError) {
        return sharedApi.delete('/api/grant/file/delete/' + fileId, onError);
    }

    async deleteGrantExternalUser(grantId, userId, externalUserRole, onError) {
        return sharedApi.delete('/api/grant/data/deleteexternaluser/' + grantId + '/' + userId + '/' + externalUserRole, onError);
    }

    async addGrantExternalUser(grantId, emailAddress, role, onError) {
        const payload = { grantId: grantId, emailAddress: emailAddress, role: role };
        return sharedApi.post('/api/grant/data/addexternaluser', payload, false, onError);
    }

    async getPhaseData(id, onError) {
        return sharedApi.get('/api/grant/data/phases/' + id, onError);
    }

    async resetGrantTitle(id, onError) {
        return sharedApi.get('/api/grant/resetTitle/' + id, onError);
    }

    async deleteGrantStep(id, onError) {
        return await sharedApi.delete('/api/grant/deleteStep/' + id, onError);
    }

    async updatePhaseComments(phaseId, phaseComments, onError) {
        const updatedPhase = {
            PhaseId: phaseId,
            PhaseComments: phaseComments
        };

        return await sharedApi.setJson('/api/grant/updatePhaseComments', updatedPhase, onError);
    }

    async updateGrantStep(id, grantId, phaseId, phaseName, fileId, stepDate, fileName, name, uploadDate, comments, phaseComments, onError) {
        const updatedGrantStep = {
            Id: id,
            GrantId: grantId,
            PhaseId: phaseId,
            PhaseName: phaseName,
            FileId: fileId,
            FileName: fileName,
            FileDate: stepDate,
            UploadDate: uploadDate,
            Name: name,
            Comments: comments,
            PhaseComments: phaseComments
        };

        return await sharedApi.setJson('/api/grant/updateStep', updatedGrantStep, onError);

    }

    async updateGrant(grantData, phaseData, onError) {
        const updateGrantModel = {
            Id: grantData.id,
            Override: grantData.override,
            BudgetEndDate: (grantData.budgetEndDate && grantData.budgetEndDate !== '') ? moment(grantData.budgetEndDate).format() : null,
            ShortTitle: grantData.shortTitle,
            ShortInstitution: grantData.shortInstitution
        }

        if (!!phaseData) { 
            //console.log('phaseData');

            const schematicDesign = {
                Submission: (phaseData.schematicDesign.submission && phaseData.schematicDesign.submission !== '') ? moment(phaseData.schematicDesign.submission).format() : null,
                Completion: (phaseData.schematicDesign.completion && phaseData.schematicDesign.completion !== '') ? moment(phaseData.schematicDesign.completion).format() : null,
                Comments: phaseData.schematicDesign.comments
            };

            const designDevelopment = {
                Submission: (phaseData.designDevelopment.submission && phaseData.designDevelopment.submission !== '') ? moment(phaseData.designDevelopment.submission).format() : null,
                Completion: (phaseData.designDevelopment.completion && phaseData.designDevelopment.completion !== '') ? moment(phaseData.designDevelopment.completion).format() : null,
                Comments: phaseData.designDevelopment.comments
            }

            const constructionDocuments = {
                Submission: (phaseData.constructionDocuments.submission && phaseData.constructionDocuments.submission !== '') ? moment(phaseData.constructionDocuments.submission).format() : null,
                Completion: (phaseData.constructionDocuments.completion && phaseData.constructionDocuments.completion !== '') ? moment(phaseData.constructionDocuments.completion).format() : null,
                Comments: phaseData.constructionDocuments.comments
            }

            const recordDocuments = {
                Comments: phaseData.recordDocuments.comments
            }
            const designCompletion = {
                Submission: (phaseData.designCompletion.submission && phaseData.designCompletion.submission !== '') ? moment(phaseData.designCompletion.submission).format() : null,
                Comments: phaseData.designCompletion.comments
            }
            const bidding = {
                Submission: (phaseData.bidding.submission && phaseData.bidding.submission !== '') ? moment(phaseData.bidding.submission).format() : null,
                Completion: (phaseData.bidding.completion && phaseData.bidding.completion !== '') ? moment(phaseData.bidding.completion).format() : null,
                Comments: phaseData.bidding.comments
            }
            const construction = {
                Submission: (phaseData.construction.submission && phaseData.construction.submission !== '') ? moment(phaseData.construction.submission).format() : null,
                Completion: (phaseData.construction.completion && phaseData.construction.completion !== '') ? moment(phaseData.construction.completion).format() : null,
                Comments: phaseData.construction.comments,
            }

            Object.assign(updateGrantModel,
                {
                    SchematicDesign: schematicDesign,
                    DesignDevelopment: designDevelopment,
                    ConstructionDocuments: constructionDocuments,
                    RecordDocuments: recordDocuments,
                    DesignCompletion: designCompletion,
                    Bidding: bidding,
                    Construction: construction
                });
        }

        return await sharedApi.setJson('/api/grant/update', updateGrantModel, onError);
    }

    uploadGrantFile(file, grantId, stepId, stepName, phaseName, fileName, description, creationDate, onError) {
        return this.postGrantFile('/api/grant/file/upload', file, grantId, stepId, stepName, phaseName, fileName, description, creationDate, onError);
    }

    async getStepFile(fileId, onError) {
        const url = sharedApi.generateURL('api/grant/file/get/' + fileId);
        let fileName = "";

        //TODO add a loading indicator/overlay
        try {
            await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                },
            }).then(function (response) {
                if (response?.ok) {
                    const disposition = response.headers.get('Content-Disposition');
  
                    fileName = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];

                    fileName = fileName.replace(/['"]/g, '');
                    fileName = fileName.substring(0, fileName.indexOf(";"))
                    return response.blob();
                }
                //TODO display popup with error
            }).then(function (fileBlob) {
                const fileURL = URL.createObjectURL(fileBlob);
                const alink = document.createElement("a");
                document.body.appendChild(alink);
                alink.style = "display: none";
                alink.href = fileURL;
                alink.target = '_blank';
                alink.download = fileName;
    
                alink.click();
                setTimeout(() => {
                    URL.revokeObjectURL(fileURL);
                    document.body.removeChild(alink);
                });

                //window.open(fileURL, '_blank');
                //setTimeout(() => {
                //    URL.revokeObjectURL(fileURL);
                //});
            });
        } catch (error) {
            console.error(error);
            //TODO display popup with error
        }
    }

    async getGrantReport(id, grantNumber, onError) {
        const url = sharedApi.generateURL('api/grant/report/' + id);
        //TODO add a loading indicator/overlay
        try {
            await fetch(url).then(function (response) {
                if (response?.ok)
                    return response.blob();

                //TODO display popup with error
            }).then(function (pdfBlob) {
                const fileURL = URL.createObjectURL(pdfBlob);
                /*const alink = document.createElement("a");
                document.body.appendChild(alink);
                alink.style = "display: none";
                alink.href = fileURL;
                alink.target = '_blank';
                alink.download = grantNumber + '_report.pdf';
    
                alink.click();
                setTimeout(() => {
                    URL.revokeObjectURL(fileURL);
                    document.body.removeChild(alink);
                });*/

                window.open(fileURL, '_blank');
                setTimeout(() => {
                    URL.revokeObjectURL(fileURL);
                });
            });
        } catch (error) {
            console.error(error);
            //TODO display popup with error
        }
    }

    async postGrantFile(path, file, grantId, stepId, stepName, phaseName, fileName, description, creationDate, onError) {
        try {
            const url = sharedApi.generateURL(path);

            const formData = new FormData();
            formData.append('GrantId', grantId);
            formData.append('PhaseName', phaseName);
            formData.append('StepId', stepId);
            formData.append('StepName', stepName);
            formData.append('FileName', fileName);
            formData.append('Description', description);
            formData.append('CreationDate', moment(creationDate).format());
            formData.append('file', file);

            const settings = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                },
                body: formData
            };

            const response = await fetch(url, settings);

            if (response.ok) {
                return (response.json());
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        } finally {
            //console.log(`fetch finished`);
        }

    }
}

export default new GrantApi();
