import React from 'react';
import { Link } from 'react-router-dom';
import '../css/NavMenu.css';

const Header = (props) => {

    return (
        <div className="navbar-header">
            <div className="top-bar">
                <div className="row">
                    <div className="col-md-4">
                        <img src="hhs_icon.png" height="auto" width="auto" alt=""></img>
                        <span>
                            <strong style={{ paddingLeft: '10px' }}>U.S. Department of Health &amp; Human Services</strong>
                        </span>
                    </div>
                    <div className="col-md-3">
                        <img src="nih_icon.png" height="auto" width="auto" alt=""></img>
                        <span>
                            <strong>National Institutes of Health</strong>
                        </span>
                    </div>
                    <div className="col-md-5 text-right" style={{ paddingTop: '4px' }}>
                        <span>
                            <strong>Division of Program Coordination, Planning, and Strategic Initiatives </strong>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row logo-holder">
                <div className="col-md-5">
                    <img src="odp_logo_2.gif" height="auto" width="auto" alt="National Institutes of Health"></img>
                    <div style={{ float: 'right', marginTop: '1px' }}><img src="eCAMERA_logo.png" height="62" width="auto" alt="eCamera"></img></div> 
                </div>
                <div className="col-md-5" style={{ fontSize: '28px', color: '#555' }}>       
                    <div style={{ float: 'left', width: '20px' }}>eCamera</div>
                </div>
                <div className="col-md-2">
                    <div style={{ fontSize: '12px', textAlign: 'end', marginTop: '5px', paddingRight: '12px' }}>
                        {(props.user?.length > 0) && (
                            <>
                                {props.user + ' | '}
                                <Link to="/logout">Log Out</Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    );
}

export default Header;
