import React, { useState, useContext } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { AppContext } from '../shared/AppContext.js';
import { withRouter } from '../shared/WithRouter.js';
import '../css/GrantVisualization.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-gantt/dist/dx-gantt.min.css';
import DashboardTimeline from './DashboardTimeline.js';


const Dashboard = (props) => {
    const context = useContext(AppContext);

    const[state, setState] = useState({
        grant: {
        },
        loading: true,
    });


    if (!context.loginUser) {
        return (<div></div>);
    } else {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12" style={{ marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className="grantVisualizationCanvas">
                                <div className="grantVisualizationPanelTop">
                                    <div className="panelTitle">
                                        Grant Visualization
                                    </div>

                                    <hr />

                                    <TabPanel id='grantVisualizationsPanel'>
                                        <Item title='&nbsp;Projects by FY&nbsp;'>
                                            <DashboardTimeline />
                                        </Item>
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    const onSaveClick = (e) => {
        window.location.href = '/portfolio';
    }

    const onCancelClick = (e) => {
        window.location.href = '/portfolio';
    }

    const onError =  async(error) => {
        const message = handleError(error);
        props.navigate('/error', { state: { props: message } });
    }
}

export default withRouter(Dashboard);

