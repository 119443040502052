import React, { useState, useEffect } from 'react';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import moment from 'moment';
import { getFirstDate, getLastDate, PHASES, FIELDS, DATES, MESSAGES } from '../shared/Utils.js';

function GrantDetailTitle({ grantData, phaseData, updateValues, showStaticEntries, disableFields, phaseIndex }) {
    const userDisabled = (disableFields) ? disableFields : null;

    const [state, setState] = useState({});
    const scrollToHash = async () => {
        const hash = window.location.hash;

        if (hash) {
            const targetElement = document.getElementById(hash.substring(1));
            const targetButton = document.getElementById(hash.substring(1) + '-button');
            const targetPanel = targetButton.nextElementSibling;

            if (targetPanel.style.display !== "block") {
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }

                if (targetButton) {
                    targetButton.click();
                }
            }
        }
    }

    useEffect(() => {
        scrollToHash();
    }, [])

    const setValues = (data) => {
        //console.log('setValues');
        //console.log(data);

        if (!data) {
            return;
        }

        if (Array.isArray(data)) {
            if (data.length == 0) {
                return;
            }
            else {
                data = data[0];
            }
        }

        let phase = "";
        let offset = "";
        let start = "";
        let projected = "";
        let submission = "";
        let completion = "";
        let comments = "";
        let dataFields = {};

        switch (data.Phase) {
            case PHASES.SD.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = 6;
                start = data.DataFields.schematicDesign.Start;
                projected = data.DataFields.schematicDesign.Projected;
                submission = data.DataFields.schematicDesign.submission;
                completion = data.DataFields.schematicDesign.completion;
                comments = data.DataFields.schematicDesign.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });
                break;

            case PHASES.DD.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = 10;
                start = data.DataFields.designDevelopment.Start;
                projected = data.DataFields.designDevelopment.Projected;
                submission = data.DataFields.designDevelopment.submission;
                completion = data.DataFields.designDevelopment.completion;
                comments = data.DataFields.designDevelopment.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });
                break;

            case PHASES.CD.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = 16;
                start = data.DataFields.constructionDocuments.Start;
                projected = data.DataFields.constructionDocuments.Projected;
                submission = data.DataFields.constructionDocuments.submission;
                completion = data.DataFields.constructionDocuments.completion;
                comments = data.DataFields.constructionDocuments.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });
                break;

            case PHASES.BS.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = 16;
                start = data.DataFields.backcheckSubmission.Start;
                projected = data.DataFields.backcheckSubmission.Projected;
                submission = data.DataFields.backcheckSubmission.submission;
                completion = data.DataFields.backcheckSubmission.completion;
                comments = data.DataFields.backcheckSubmission.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });

                break;
            case PHASES.RD.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = data.DataFields.recordDocuments.Offset;
                start = data.DataFields.recordDocuments.Start;
                projected = data.DataFields.recordDocuments.Projected;
                completion = data.DataFields.recordDocuments.completion;
                comments = data.DataFields.recordDocuments.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });
                break;

            case PHASES.DC.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = data.DataFields.designCompletion.Offset;
                start = data.DataFields.designCompletion.Start;
                projected = data.DataFields.designCompletion.Projected;
                submission = data.DataFields.designCompletion.submission;
                completion = data.DataFields.designCompletion.completion;
                comments = data.DataFields.designCompletion.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });
                break;


            case PHASES.B.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = 19;
                start = data.DataFields.bidding.Start;
                projected = data.DataFields.bidding.Projected;
                submission = data.DataFields.bidding.submission;
                completion = data.DataFields.bidding.completion;
                comments = data.DataFields.bidding.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });
                break;

            case PHASES.C.NAME:
                phase = data.Phase
                dataFields = data.DataFields;
                offset = 56;
                start = data.DataFields.construction.Start;
                projected = data.DataFields.construction.Projected;
                submission = data.DataFields.construction.submission;
                completion = data.DataFields.construction.completion;
                comments = data.DataFields.construction.comments;

                setState({ ...state, dataFields: dataFields, offset: offset, start: start, projected: projected, submission: submission, completion: completion, comments: comments, phase: phase });
                break;
        }

    };

    useEffect(() => {
        if (phaseData) {
            setValues(phaseData.grantPhases[phaseIndex]);
        }
    }, [phaseData, grantData])

    function onClick(e) {
        e.event.stopPropagation();
    }

    function showComments(phase) {
        let showComments = false;

        if (phase) {
            switch (phase) {
                case PHASES.BS.NAME:
                    showComments = false;
                    break;

                default:
                    showComments = true;
                    break;
            }
        }

        return showComments;
    }

    function showCompletion(phase) {
        let showCompletion = false;

        if (phase) {
            switch (phase) {
                case PHASES.BS.NAME:
                case PHASES.DC.NAME:
                case PHASES.RD.NAME:
                    showCompletion = false;
                    break;

                default:
                    showCompletion = true;
                    break;
            }
        }

        return showCompletion;
    }

    function DisableFields(phase, field, dataFields) {
        if (userDisabled) {
            return true;
        } else if (grantData.override) {
            if (field == FIELDS.SUBMISSION ||
                field == FIELDS.COMPLETION) {
                switch (phase) {
                    case PHASES.SD.NAME:
                        return false;
                        break;

                    case PHASES.DD.NAME:
                        const ddFieldValue = (field == FIELDS.SUBMISSION) ? dataFields.designDevelopment.submission : dataFields.designDevelopment.completion;

                        if (dataFields.schematicDesign.completion) {
                            return false;
                        } else {
                            return (ddFieldValue) ? false : true;
                        }

                        break;

                    case PHASES.CD.NAME:
                        const cdFieldValue = (field == FIELDS.SUBMISSION) ? dataFields.constructionDocuments.submission : dataFields.constructionDocuments.completion;

                        if (dataFields.designDevelopment.completion) {
                            return false;
                        } else {
                            return (cdFieldValue) ? false : true;
                        }
                        break;

                    case PHASES.BS.NAME:
                        return true;

                    default:
                        return false;
                        break;
                }

                return true;
            } else {
                return false;
            }
        } else if (phase == PHASES.C.NAME && field == FIELDS.COMPLETION) {
            return false;
        } else {
            return true;
        }
    }

    function getFieldClass(phase, column, grantOverridden) {
        if (phase) {
            if (column == FIELDS.COMMENTS) {
                return (grantOverridden) ? "grantDetails-editable" : "grantDetails-computed";
            } else if ((phase == PHASES.BS.NAME || phase == PHASES.DC.NAME || phase == PHASES.RD.NAME) && column == FIELDS.SUBMISSION) {
                return "grantDetails-hidden";
            } else if (phase == PHASES.C.NAME && column == FIELDS.COMPLETION) {
                return "grantDetails-editable";
            } else {
                return (grantOverridden) ? "grantDetails-editable" : "grantDetails-computed";
            }
        } else {
            return "grantDetails-hidden";
        }
    }

    function showProgress(phase) {
        let showProgressBoxes = false;

        if (phase) {
            switch (phase) {
                case PHASES.DC.NAME:
                case PHASES.RD.NAME:
                    showProgressBoxes = false;
                    break;

                default:
                    showProgressBoxes = true;
                    break;
            }
        }

        return showProgressBoxes;
    }

    function resetButtonVisible(value) {
        if (grantData.override && value) {
            return true;
        } else {
            return false;
        }
    }

    function getMinSubmissionDate() {
        if (phaseData.grantPhases[phaseIndex].DataFields) {
            return phaseData.grantPhases[phaseIndex].DataFields.schematicDesign.Start;
        }
    }

    function getMaxSubmissionDate() {
        if (state && phaseData.grantPhases[phaseIndex].DataFields) {
            let maxDate = null;

            switch (phaseData.grantPhases[phaseIndex].Phase) {
                case PHASES.SD.NAME:
                    maxDate = (phaseData.grantPhases[phaseIndex].DataFields.schematicDesign.completion && phaseData.grantPhases[phaseIndex].DataFields.schematicDesign.completion != '') ? phaseData.grantPhases[phaseIndex].DataFields.schematicDesign.completion : null;
                    break;

                case PHASES.DD.NAME:
                    maxDate = (phaseData.grantPhases[phaseIndex].DataFields.designDevelopment.completion && phaseData.grantPhases[phaseIndex].DataFields.designDevelopment.completion != '') ? phaseData.grantPhases[phaseIndex].DataFields.designDevelopment.completion : null;
                    break;

                case PHASES.CD.NAME:
                    maxDate = (phaseData.grantPhases[phaseIndex].DataFields.constructionDocuments.completion && phaseData.grantPhases[phaseIndex].DataFields.constructionDocuments.completion != '') ? phaseData.grantPhases[phaseIndex].DataFields.constructionDocuments.completion : null;
                    break;

                case PHASES.B.NAME:
                    maxDate = (phaseData.grantPhases[phaseIndex].DataFields.bidding.completion && phaseData.grantPhases[phaseIndex].DataFields.bidding.completion != '') ? phaseData.grantPhases[phaseIndex].DataFields.bidding.completion : null;
                    break;

                case PHASES.C.NAME:
                    maxDate = (phaseData.grantPhases[phaseIndex].DataFields.construction.completion && phaseData.grantPhases[phaseIndex].DataFields.construction.completion != '') ? phaseData.grantPhases[phaseIndex].DataFields.construction.completion : null;
                    break;
            }

            return maxDate;
        }
    }

    function getMinCompletionDate() {
        if (phaseData.grantPhases[phaseIndex].DataFields) {
            let minDate = phaseData.grantPhases[phaseIndex].DataFields.schematicDesign.Start;

            switch (phaseData.grantPhases[phaseIndex].Phase) {
                case PHASES.SD.NAME:
                    minDate = (phaseData.grantPhases[phaseIndex].DataFields.schematicDesign.ubmission) ? phaseData.grantPhases[phaseIndex].DataFields.schematicDesign.submission : minDate;
                    break;

                case PHASES.DD.NAME:
                    minDate = (phaseData.grantPhases[phaseIndex].DataFields.designDevelopment.submission) ? phaseData.grantPhases[phaseIndex].DataFields.designDevelopment.submission : minDate;
                    break;

                case PHASES.CD.NAME:
                    minDate = (phaseData.grantPhases[phaseIndex].DataFields.constructionDocuments.submission) ? phaseData.grantPhases[phaseIndex].DataFields.constructionDocuments.submission : minDate;
                    break;

                case PHASES.B.NAME:
                    minDate = (phaseData.grantPhases[phaseIndex].DataFields.bidding.submission) ? phaseData.grantPhases[phaseIndex].DataFields.bidding.submission : minDate;
                    break;

                case PHASES.C.NAME:
                    minDate = (phaseData.grantPhases[phaseIndex].DataFields.construction.submission) ? phaseData.grantPhases[phaseIndex].DataFields.construction.submission : minDate;
                    break;
            }

            return minDate;
        }
    }

    function getDateValue(originalDateValue) {
        return (originalDateValue) ? moment(originalDateValue).startOf('day').format(DATES.FORMAT) : null;
    }

    const onValueChanged = async (e) => {
        let completion = "";
        let submission = "";
        let projected = "";
        let updatedDataFields = phaseData.grantPhases[phaseIndex].DataFields;
        let valueChanged = false;

        if (grantData.override && e.element.id != '' && state && phaseData.grantPhases[phaseIndex].DataFields) {
            const dateValue = (e.value) ? moment(e.value).startOf('day').format(DATES.FORMAT) : null;

            switch (e.element.id) {
                case PHASES.SD.NAME + "_" + FIELDS.SUBMISSION:
                    if (getDateValue(updatedDataFields.schematicDesign.submission) != dateValue) {
                        valueChanged = true;
                        submission = dateValue;
                        updatedDataFields.schematicDesign.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: submission });
                    }
                    break;

                case PHASES.SD.NAME + "_" + FIELDS.COMPLETION:
                    if (getDateValue(updatedDataFields.schematicDesign.completion) != dateValue) {
                        valueChanged = true;
                        completion = dateValue;
                        updatedDataFields.schematicDesign.completion = dateValue;

                        if (!updatedDataFields.schematicDesign.submission) {
                            submission = dateValue;
                            updatedDataFields.schematicDesign.submission = dateValue;

                            setState({ ...state, dataFields: updatedDataFields, completion: completion, projected: projected });
                        } else {
                            setState({ ...state, dataFields: updatedDataFields, completion: completion, projected: projected });
                        }
                    }
                    break;

                case PHASES.SD.NAME + "_" + FIELDS.COMMENTS:
                    if (updatedDataFields.schematicDesign.comments != e.value) {
                        valueChanged = true;
                        updatedDataFields.schematicDesign.comments = e.value;
                        setState({ ...state, comments: e.value, dataFields: updatedDataFields });
                    }
                    break;

                case PHASES.DD.NAME + "_" + FIELDS.SUBMISSION:
                    if (getDateValue(updatedDataFields.designDevelopment.submission) != dateValue) {
                        valueChanged = true;
                        submission = dateValue;
                        updatedDataFields.designDevelopment.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: submission });
                    }
                    break;

                case PHASES.DD.NAME + "_" + FIELDS.COMPLETION:
                    if (getDateValue(updatedDataFields.designDevelopment.completion) != dateValue) {
                        valueChanged = true;
                        completion = dateValue;
                        updatedDataFields.designDevelopment.completion = dateValue;

                        if (!updatedDataFields.designDevelopment.submission) {
                            submission = dateValue;
                            updatedDataFields.designDevelopment.submission = dateValue;

                            setState({ ...state, dataFields: updatedDataFields, completion: completion, submission: submission, projected: projected });
                        } else {
                            setState({ ...state, dataFields: updatedDataFields, completion: completion, projected: projected });
                        }
                    }
                    break;

                case PHASES.DD.NAME + "_" + FIELDS.COMMENTS:
                    if (updatedDataFields.designDevelopment.comments != e.value) {
                        valueChanged = true;
                        updatedDataFields.designDevelopment.comments = e.value;
                        setState({ ...state, comments: e.value, dataFields: updatedDataFields });
                    }
                    break;

                case PHASES.CD.NAME + "_" + FIELDS.SUBMISSION:
                    if (getDateValue(updatedDataFields.constructionDocuments.submission) != dateValue) {
                        valueChanged = true;
                        submission = dateValue;
                        updatedDataFields.constructionDocuments.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: submission });
                    }
                    break;

                case PHASES.CD.NAME + "_" + FIELDS.COMPLETION:
                    if (getDateValue(updatedDataFields.constructionDocuments.completion) != dateValue) {
                        valueChanged = true;
                        completion = dateValue;
                        updatedDataFields.constructionDocuments.completion = dateValue;

                        if (!updatedDataFields.constructionDocuments.submission) {
                            submission = dateValue;
                            updatedDataFields.constructionDocuments.submission = dateValue;

                            setState({ ...state, dataFields: updatedDataFields, completion: completion, submission: submission, projected: projected });
                        } else {
                            setState({ ...state, dataFields: updatedDataFields, completion: completion, projected: projected });
                        }
                    }
                    break;

                case PHASES.CD.NAME + "_" + FIELDS.COMMENTS:
                    if (updatedDataFields.constructionDocuments.comments != e.value) {
                        valueChanged = true;
                        updatedDataFields.constructionDocuments.comments = e.value;
                        setState({ ...state, comments: e.value, dataFields: updatedDataFields });
                    }
                    break;

                case PHASES.RD.NAME + "_" + FIELDS.COMMENTS:
                    if (updatedDataFields.recordDocuments.comments != e.value) {
                        valueChanged = true;
                        updatedDataFields.recordDocuments.comments = e.value;
                        setState({ ...state, comments: e.value, dataFields: updatedDataFields });
                    }
                    break;

                case PHASES.DC.NAME + "_" + FIELDS.SUBMISSION:
                    if (getDateValue(updatedDataFields.designCompletion.submission) != dateValue) {
                        valueChanged = true;
                        submission = dateValue;
                        updatedDataFields.designCompletion.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: submission });
                    }
                    break;

                case PHASES.DC.NAME + "_" + FIELDS.COMMENTS:
                    if (updatedDataFields.designCompletion.comments != e.value) {
                        valueChanged = true;
                        updatedDataFields.designCompletion.comments = e.value;
                        setState({ ...state, comments: e.value, dataFields: updatedDataFields });
                    }
                    break;

                case PHASES.B.NAME + "_" + FIELDS.SUBMISSION:
                    if (getDateValue(updatedDataFields.bidding.submission) != dateValue) {
                        valueChanged = true;
                        submission = dateValue;
                        updatedDataFields.bidding.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: submission });
                    }
                    break;

                case PHASES.B.NAME + "_" + FIELDS.COMPLETION:
                    if (getDateValue(updatedDataFields.bidding.completion) != dateValue) {
                        valueChanged = true;
                        completion = dateValue;
                        updatedDataFields.bidding.completion = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, completion: completion });
                    }
                    break;

                case PHASES.B.NAME + "_" + FIELDS.COMMENTS:
                    if (updatedDataFields.bidding.comments != e.value) {
                        valueChanged = true;
                        updatedDataFields.bidding.comments = e.value;
                        setState({ ...state, comments: e.value, dataFields: updatedDataFields });
                    }
                    break;

                case PHASES.C.NAME + "_" + FIELDS.SUBMISSION:
                    if (getDateValue(updatedDataFields.construction.submission) != dateValue) {
                        valueChanged = true;
                        submission = dateValue;
                        updatedDataFields.construction.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: submission });
                    }
                    break;

                case PHASES.C.NAME + "_" + FIELDS.COMPLETION:
                    if (getDateValue(updatedDataFields.construction.completion) != dateValue) {
                        valueChanged = true;
                        completion = dateValue;
                        updatedDataFields.construction.completion = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, completion: completion });
                    }
                    break;

                case PHASES.C.NAME + "_" + FIELDS.COMMENTS:
                    if (updatedDataFields.construction.comments != e.value) {
                        valueChanged = true;
                        updatedDataFields.construction.comments = e.value;
                        setState({ ...state, comments: e.value, dataFields: updatedDataFields });
                    }
                    break;
            }

            if (valueChanged) {
                await updateValues(updatedDataFields);
            }
        } else if (!grantData.override && e.element.id != '' && state && phaseData.grantPhases[phaseIndex].DataFields) {
            const dateValue = (e.value) ? moment(e.value).startOf('day').format(DATES.FORMAT) : null;

            switch (e.element.id) {
                case PHASES.C.NAME + "_" + FIELDS.COMPLETION:
                    completion = dateValue;
                    updatedDataFields.construction.completion = dateValue;
                    setState({ ...state, dataFields: updatedDataFields, completion: completion });
                    break;
            }
        }
    }

    function getViewClasses(showStaticEntries, persist, widthContracted, widthExpanded) {
        if (showStaticEntries) {
            return "dx-field col-" + widthContracted;
        } else if (persist) {
            return "dx-field col-" + widthExpanded;
        } else {
            return "hideFields";
        }
    }

    const getPhaseDocuments = (documents, phaseName) => {
        let phaseDocuments = null;

        for (let i = 0; i < documents.length; i++) {
            if (documents[i].name == phaseName) {
                phaseDocuments = documents[i];
                break;
            }
        }

        return (phaseDocuments && phaseDocuments.items && phaseDocuments.items.length > 0) ? phaseDocuments : null;
    }

    const resetFieldValue = (phase, field) => {
        console.log('resetFieldValue');
        console.log(phase);
        console.log(field);

        let sdSubmission = null;
        let sdCompletion = null;
        let ddSubmission = null;
        let ddCompletion = null;
        let cdSubmission = null;
        let cdCompletion = null;

        // Reset Phase Dates
        const sdDocuments = getPhaseDocuments(grantData.documents, PHASES.SD.NAME);
        const ddDocuments = getPhaseDocuments(grantData.documents, PHASES.DD.NAME);
        const cdDocuments = getPhaseDocuments(grantData.documents, PHASES.CD.NAME);
        const odDocuments = getPhaseDocuments(grantData.documents, PHASES.RD.NAME);

        // Schematic Design
        if (phaseHasDocuments(sdDocuments)) {
            sdSubmission = grantData.noa;

            if (ddDocuments) {
                sdCompletion = getLastDate(sdDocuments.items, DATES.MINIMUM);
            } else {
                sdCompletion = null;
            }
        } else if (phaseHasDocuments(ddDocuments)) {
            sdSubmission = getFirstDate(ddDocuments.items, DATES.MAXIMUM);
            sdCompletion = getFirstDate(ddDocuments.items, DATES.MAXIMUM);;
        } else if (phaseHasDocuments(cdDocuments)) {
            sdSubmission = getFirstDate(cdDocuments.items, DATES.MAXIMUM);
            sdCompletion = getFirstDate(cdDocuments.items, DATES.MAXIMUM);;
        } else {
            sdSubmission = grantData.noa;
            sdCompletion = null;
        }

        // Design Development
        if (phaseHasDocuments(ddDocuments)) {
            ddSubmission = sdCompletion;

            if (phaseHasDocuments(cdDocuments)) {
                ddCompletion = getLastDate(ddDocuments.items, DATES.MINIMUM);
            } else {
                ddCompletion = null;
            }
        } else if (phaseHasDocuments(cdDocuments)) {
            ddSubmission = getFirstDate(cdDocuments.items, DATES.MAXIMUM);
            ddCompletion = getFirstDate(cdDocuments.items, DATES.MAXIMUM);
        } else {
            ddSubmission = null;
            ddCompletion = null;
        }

        // Construction Documents
        if (phaseHasDocuments(cdDocuments)) {
            cdSubmission = ddCompletion;
            cdCompletion = null;
        } else {
            cdSubmission = null;
            cdCompletion = null;
        }

        // Bidding
        let dcDocumentFound = false;
        let bdDocumentFound = false;

        let firstDCDocumentDate = moment();
        let lastDCDocumentDate = moment(DATES.MINIMUM);
        let lastBDDocumentDate = moment(DATES.MINIMUM);
        let lastCDocumentDate = moment(DATES.MINIMUM);

        let dcComment = null;
        let bComment = null;
        let cComment = null

        let bSubmission = null;
        let bCompletion = null;

        for (let i = 0; i < grantData.steps.length; i++) {
            if (grantData.steps[i].phaseName == PHASES.DC.NAME) {
                if (moment(grantData.steps[i].uploadDate).isBefore(firstDCDocumentDate)) {
                    dcDocumentFound = true;

                    firstDCDocumentDate = grantData.steps[i].uploadDate;
                }

                if (moment(grantData.steps[i].uploadDate).isAfter(lastDCDocumentDate)) {
                    dcDocumentFound = true;

                    lastDCDocumentDate = grantData.steps[i].uploadDate;
                }
            }

            if (grantData.steps[i].phaseName == PHASES.B.NAME) {
                if (moment(grantData.steps[i].uploadDate).isAfter(lastBDDocumentDate)) {
                    bdDocumentFound = true;

                    lastBDDocumentDate = grantData.steps[i].uploadDate;
                }
            }

            if (grantData.steps[i].phaseName == PHASES.C.NAME) {
                if (moment(grantData.steps[i].uploadDate).isAfter(lastCDocumentDate)) {
                    lastCDocumentDate = grantData.steps[i].uploadDate;
                    cComment = MESSAGES.LASTUPDATE + moment(lastCDocumentDate).format(DATES.FORMAT);
                }
            }
        }

        if (dcDocumentFound) {
            bSubmission = firstDCDocumentDate;
        }

        if (bdDocumentFound) {
            bCompletion = lastBDDocumentDate;
        }

        //Construction
        let cSubmission = null;
        let cCompletion = null;

        if (bdDocumentFound) {
            cCompletion = lastBDDocumentDate;
        }

        switch (phase) {
            case PHASES.SD.NAME:
                return (field == FIELDS.SUBMISSION) ? sdSubmission : sdCompletion;
                break;

            case PHASES.DD.NAME:
                return (field == FIELDS.SUBMISSION) ? ddSubmission : ddCompletion;
                break;

            case PHASES.CD.NAME:
                return (field == FIELDS.SUBMISSION) ? cdSubmission : cdCompletion;
                break;

            case PHASES.B.NAME:
                return (field == FIELDS.SUBMISSION) ? bSubmission : bCompletion;
                break;

            case PHASES.C.NAME:
                return (field == FIELDS.SUBMISSION) ? cSubmission : cCompletion;
                break;
        }

        return null;
    }

    const phaseHasDocuments = (phaseFolder) => {
        for (var i = 0; i < phaseFolder.items.length; i++) {
            if (!phaseFolder.items[i].isDirectory || (phaseFolder.items[i].isDirectory && phaseFolder.items[i].items.length > 0)) {
                return true;
            }
        }

        return false;
    }

    const resetDateButton = (value, phase, field) => {
        return {
            icon: 'revert',
            stylingMode: 'text',
            visible: resetButtonVisible(value),
            onClick: async () => {
                const dateValue = resetFieldValue(phase, field);

                //console.log(value);
                //console.log(phase);
                //console.log(field);
                //console.log(dateValue);

                let updatedDataFields = phaseData.grantPhases[phaseIndex].DataFields;

                switch (phase + '_' + field) {
                    case PHASES.SD.NAME + "_" + FIELDS.SUBMISSION:
                        updatedDataFields.schematicDesign.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: dateValue });
                        break;

                    case PHASES.SD.NAME + "_" + FIELDS.COMPLETION:
                        updatedDataFields.schematicDesign.completion = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, completion: dateValue });
                        break;

                    case PHASES.DD.NAME + "_" + FIELDS.SUBMISSION:
                        updatedDataFields.designDevelopment.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: dateValue });
                        break;

                    case PHASES.DD.NAME + "_" + FIELDS.COMPLETION:
                        updatedDataFields.designDevelopment.completion = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, completion: dateValue });
                        break;

                    case PHASES.CD.NAME + "_" + FIELDS.SUBMISSION:
                        updatedDataFields.constructionDocuments.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: dateValue });
                        break;

                    case PHASES.CD.NAME + "_" + FIELDS.COMPLETION:
                        updatedDataFields.constructionDocuments.completion = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, completion: dateValue });
                        break;

                    case PHASES.DC.NAME + "_" + FIELDS.SUBMISSION:
                        updatedDataFields.designCompletion.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: dateValue });
                        break;

                    case PHASES.B.NAME + "_" + FIELDS.SUBMISSION:
                        updatedDataFields.bidding.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: dateValue });
                        break;

                    case PHASES.B.NAME + "_" + FIELDS.COMPLETION:
                        updatedDataFields.bidding.completion = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, completion: dateValue });
                        break;

                    case PHASES.C.NAME + "_" + FIELDS.SUBMISSION:
                        updatedDataFields.construction.submission = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, submission: dateValue });
                        break;

                    case PHASES.C.NAME + "_" + FIELDS.COMPLETION:
                        updatedDataFields.construction.completion = dateValue;
                        setState({ ...state, dataFields: updatedDataFields, completion: dateValue });
                        break;

                }

                await updateValues(updatedDataFields);
            }
        }
    }

    return (
        <div className="row grantDetailsRowTimeline">
            <div className="dx-field col-2">
                <div className="row detailsRow">
                    <div className="dx-field col-12">
                        <div className="grantDetails-created-value">
                            <div className="dx-field-label-larger">{phaseData.grantPhases[phaseIndex].FullPhase}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={getViewClasses(showStaticEntries, false, 1)}>
                <div className="row detailsRow">
                    <div className="dx-field col-12">
                        <div className="grantDetails-created-value">
                            <NumberBox
                                id="schematicDesign_Offset"
                                className="grantDetails-readonly"
                                value={state.offset}
                                visible={showProgress(phaseData.grantPhases[phaseIndex].Phase)}
                                disabled={true} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={getViewClasses(showStaticEntries, true, 6, 3)}>
                <div className="row detailsRow">
                    <div className={getViewClasses(showStaticEntries, false, 3)}>
                        <div className="grantDetails-created-value">
                            <TextBox
                                className="grantDetails-computed"
                                value={state.start}
                                visible={showProgress(phaseData.grantPhases[phaseIndex].Phase)}
                                disabled={true} />
                        </div>
                    </div>
                    <div className={getViewClasses(showStaticEntries, false, 3)}>
                        <div className="grantDetails-created-value">
                            <TextBox
                                className="grantDetails-computed"
                                value={state.projected}
                                visible={showProgress(phaseData.grantPhases[phaseIndex].Phase)}
                                disabled={true} />
                        </div>
                    </div>
                    <div className={getViewClasses(showStaticEntries, true, 3, 6)}>
                        <div className="grantDetails-created-value">
                            <DateBox
                                type="date"
                                className={getFieldClass(phaseData.grantPhases[phaseIndex].Phase, FIELDS.SUBMISSION, grantData.override)}
                                value={state.submission}
                                displayFormat="MM-dd-yyyy"
                                disabled={DisableFields(phaseData.grantPhases[phaseIndex].Phase, FIELDS.SUBMISSION, phaseData.grantPhases[phaseIndex].DataFields)}
                                onClick={onClick}
                                id={phaseData.grantPhases[phaseIndex].Phase + '_Submission'}
                                min={getMinSubmissionDate()}
                                max={getMaxSubmissionDate()}
                                onValueChanged={onValueChanged.bind(this)}>
                                <DateBoxButton
                                    name="resetDate"
                                    location="after"
                                    options={resetDateButton(state.submission, phaseData.grantPhases[phaseIndex].Phase, FIELDS.SUBMISSION)}
                                />
                                <DateBoxButton name="dropDown" />
                            </DateBox>
                        </div>
                    </div>
                    <div className={getViewClasses(showStaticEntries, true, 3, 6)}>
                        <div className="grantDetails-created-value">
                            <DateBox
                                type="date"
                                displayFormat="MM-dd-yyyy"
                                className={getFieldClass(phaseData.grantPhases[phaseIndex].Phase, FIELDS.COMPLETION, grantData.override)}
                                value={state.completion}
                                visible={showCompletion(phaseData.grantPhases[phaseIndex].Phase)}
                                disabled={DisableFields(phaseData.grantPhases[phaseIndex].Phase, FIELDS.COMPLETION, phaseData.grantPhases[phaseIndex].DataFields)}
                                id={phaseData.grantPhases[phaseIndex].Phase + '_Completion'}
                                min={getMinCompletionDate()}
                                onValueChanged={onValueChanged.bind(this)}>
                                <DateBoxButton
                                    name="resetDate"
                                    location="after"
                                    options={resetDateButton(state.completion, phaseData.grantPhases[phaseIndex].Phase, FIELDS.COMPLETION)}
                                />
                                <DateBoxButton name="dropDown" />
                            </DateBox>
                        </div>
                    </div>
                </div>
            </div>
            <div className={getViewClasses(showStaticEntries, true, 3, 7)}>
                <div className="row detailsRow">
                    <div className="dx-field col-12">
                        <div className="grantDetails-created-value">
                            <TextBox
                                className={getFieldClass(phaseData.grantPhases[phaseIndex].Phase, FIELDS.COMMENTS, grantData.override)}
                                value={state.comments}
                                visible={showComments(phaseData.grantPhases[phaseIndex].Phase)}
                                id={phaseData.grantPhases[phaseIndex].Phase + '_Comments'}
                                disabled={DisableFields(phaseData.grantPhases[phaseIndex].Phase, FIELDS.COMMENTS, phaseData.grantPhases[phaseIndex].DataFields)}
                                onValueChanged={onValueChanged.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GrantDetailTitle;