import notify from 'devextreme/ui/notify';
import { convertToLocalTime } from 'date-fns-timezone';
import { getTimezoneOffset } from 'date-fns-tz';
import moment from 'moment';

export const ROLE = {
    ORF: 0,
    PI: 1,
    PO: 2,
    EXTERNAL_USER: 100,
    SU: 999,
}

export const EXTERNALUSERROLE = {
    REVIEWER: 3,
    AE_DESIGNER: 4,
    PROJECT_MANAGER: 5
}

export const GRANTSTATUS = {
    AWARDED: 5,
    APPLICATION: 7
}

export const ROTATE = {
    ZERO: 'rotate(0deg)',
    ONEEIGHTY: 'rotate(180deg)'
}

export const PHASESTEPS = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    BIDDING: 6,
    CONSTRUCTION: 7,
    ALL: 99
}

export const PHASES = {
    SD: {
        NAME: "Schematic Design",
        FULLNAME: "01 Schematic Design",
        ABBREV: "SD",
        NUMBER: 1
    },
    DD: {
        NAME: "Design Development",
        FULLNAME: "02 Design Development",
        ABBREV: "DD",
        NUMBER: 2
    },
    CD: {
        NAME: "Construction Documents",
        FULLNAME: "03 Construction Documents",
        ABBREV: "CD",
        NUMBER: 3
    },
    BS: {
        NAME: "Backcheck Submission",
        FULLNAME: "04 Backcheck Submission",
        ABBREV: "BS",
        NUMBER: 4
    },
    RD: {
        NAME: "Record Documents",
        FULLNAME: "05 Record Documents",
        ABBREV: "RD",
        NUMBER: 5
    },
    DC: {
        NAME: "Design Completion",
        FULLNAME: "06 Design Completion",
        ABBREV: "DC",
        NUMBER: 6
    },
    B: {
        NAME: "Bidding",
        FULLNAME: "07 Bidding",
        ABBREV: "B",
        NUMBER: 7
    },
    C: {
        NAME: "Construction",
        FULLNAME: "08 Construction",
        ABBREV: "C",
        NUMBER: 8
    },
    ALL: {
        NAME: "All",
        ABBREV: "ALL",
        NUMBER: 99
    },
};

export const FIELDS = {
    SUBMISSION: "Submission",
    COMPLETION: "Completion",
    COMMENTS: "Comments"
}

export const STATUS = {
    SUCCESS: "Success",
    ERROR: "Error",
    NOPERMISSIONS: "No Permissions",
    SCHEDULED: "Scheduled",
    COMPLETION: "Completion",
    COMPLETED: "Completed",
    INPROGRESS: "In Progress",
    SLIGHTLYLATE: "Slightly Late",
    LATE: "Late"
}

export const FY = {
    ALL: "All FY",
    ACTIVE: "All Active"
}

export const TIMELINE = {
    W3SVGURL: "http://www.w3.org/2000/svg",
    FONTNAMES: "Helvetica Neue, Segoe UI, helvetica",
    FONTCOLOR: "#bbbbbb",
    BACKGROUNDCOLOR: "#ffffff",
    SVG: "div[id^=reactgooglegraph] > div > div > div > svg",
    LEGENDSVG: "div[id^=reactgooglegraph] > div > div > div > div > svg",
    PATHXOFFSET: 0.0000108,
    CURRENTDATECOLOR: "blue",
    CURRENDATEFONTSIZE: "13",
    CURRENDATEFONTWEIGHT: "Bold",
    CURRENDATEFONT: "Arial",
    TOOLTIPSELECTOR: ".phaseTipText",
    TOOLTIPSTYLE: "width: 170px; margin: 10px;",
    TOOLTIPFILL: "#4d4d4d",
    SCHEDULEDCOLOR: "#d3d3d3",
    COMPLETEDCOLOR: "#83d18e",
    INPROGRESSCOLOR: "#f2a600",
    SLIGHTLYLATECOLOR: "#ffff00",
    LATECOLOR: "#c80112",
    PHASEFILL: "#efefef",
    GRIDLINECOLOR1: "#ffffff",
    GRIDLINECOLOR1: "#b7b7b7",
    GRIDLINECOLOR3: "#e6e6e6",
    BORDERCOLOR: "#9a9a9a",
}

export const MESSAGES = {
    UPDATESSAVED: "Your updates have been saved.",
    EXITPAGECONFIRMATION: "You have unsaved changes. Are you sure you want to exit this page ?",
    FILEUPLOADERROR: "Error during file upload",
    FILEUPLOADSUCCESS: "File successfully uploaded",
    FILEUPLOADTYPES: " files are permitted for this step.  Please select a different file.",
    FILEDELETEERROR: "There was an error deleting the file.",
    FILEDELETESUCCESS: "The file was successfully deleted.",
    FILEDELETENOPERMISSIONS: "You do not have permissions to delete this file.",
    ERRORDELETINGSTEP: "Error deleting step",
    LASTUPDATE: "Last Update: ",
	NOLONGERLOGGEDIN: "You are no longer logged in to the system. Please re-login.",
	MESSAGES: "Server cannot process your action. You may not be logged in to the system. Please re-login.",
	BADREQUEST: "Bad Request"
}

export const HTTP = {
    BADREQUEST: "Bad Request",
    HTTP_401: "HTTP error: 401",
    HTTP_500: "HTTP error: 500"
}

export const ALLOWEDFILEEXTENSIONS = ['.pdf', '.docx', '.xlsx', '.pptx', '.png', '.jpg', '.jpeg'];

export const STEPS = {
    A: {
        NAME: "a - Submission",
        ABBREV: "a",
        NUMBER: 1
    },
    B: {
        NAME: "b - Review Comments",
        ABBREV: "b",
        NUMBER: 2
    },
    C: {
        NAME: "c - AE Responses",
        ABBREV: "c",
        NUMBER: 3
    },
    D: {
        NAME: "d - Comment Response Review",
        ABBREV: "d",
        NUMBER: 4
    },
    E: {
        NAME: "e - Other Documents",
        ABBREV: "e",
        NUMBER: 5
    },
    BIDDING: {
        NAME: "Bidding",
        NUMBER: 6
    },
    CONSTRUCTION: {
        NAME: "Construction",
        NUMBER: 7
    },
    ALL: {
        NAME: "All",
        NUMBER: 99
    }
}

export const FILEACTION = {
    EXISTING: 'existing',
    NEW: 'new',
    DELETE: 'delete'
};

export const FILETYPE = {
    DRAWINGS: "Drawings",
    SPECS: "Specifications",
    BOD: "Basis of Design",
    REVIEWCOMMENTS: "Review Comments",
    AERESPONSE: "AE Response",
    RESPONSEREVIEW: "Comment Response Review",
}

export const FILEABBREV = {
    DRAWINGS: "Drawings",
    SPECS: "Specs",
    BOD: "BOD",
    REVIEWCOMMENTS: "Review",
    AERESPONSE: "AEResponse",
    RESPONSEREVIEW: "ResponseReview",
}

export const FILETYPES = [
    {
        Name: FILETYPE.DRAWINGS,
        Abbrev: FILEABBREV.DRAWINGS
    },
    {
        Name: FILETYPE.SPECS,
        Abbrev: FILEABBREV.SPECS
    },
    {
        Name: FILETYPE.BOD,
        Abbrev: FILEABBREV.BOD
    },
    {
        Name: FILETYPE.RESPONSEREVIEW,
        Abbrev: FILEABBREV.RESPONSEREVIEW
    },
    {
        Name: FILETYPE.REVIEWCOMMENTS,
        Abbrev: FILEABBREV.REVIEWCOMMENTS
    },
    {
        Name: FILETYPE.AERESPONSE,
        Abbrev: FILEABBREV.AERESPONSE
    }
];

export const CLASSES = {
    DETAILSLOADING: "detailsLoadingClass",
    DETAILSLOADED: "detailsLoadedClass",
    DETAILSLOADEDTIMELINE: "detailsLoadedClass row grantDetailsRowTimeline",
    PHASESHOW: "showPhase",
    PHASEHIDE: "hidePhase"
}


export const DATES = {
    MINIMUM: "1/1/1900",
    MAXIMUM: "1/1/9999",
    FORMAT: "MM-DD-YYYY"
}

export const OFFSET = {
    SCHEMATICDESIGN: 6,
    DESIGNDEVELOPMENT: 4,
    CONSTRUCTIONDOCUMENTS: 6,
    BACKCHECKSUBMISSION: 6,
    BIDDING: 3,
    CONSTRUCTION: 36
};

export const UPLOADFILETYPE = {
    TRIMBLE: 0,
    EXTERNALUSERS: 1
};

export const UPLOADSTATUS = {
    SUCCESS: 1,
    ERROR: 2,
    INVALIDDATA: 3
};


export const views = ['day', 'workWeek', 'week', 'month'];

export const hours = ['12 am', '01 am', '02 am', '03 am', '04 am', '05 am', '06 am', '07 am', '08 am', '09 am', '10 am', '11 am', '12 pm', '01 pm', '02 pm', '03 pm', '04 pm', '05 pm', '06 pm', '07 pm', '08 pm', '09 pm', '10 pm', '11 pm'];
export const hours_end = ['01 am', '02 am', '03 am', '04 am', '05 am', '06 am', '07 am', '08 am', '09 am', '10 am', '11 am', '12 pm', '01 pm', '02 pm', '03 pm', '04 pm', '05 pm', '06 pm', '07 pm', '08 pm', '09 pm', '10 pm', '11 pm', '12 am'];

export const timeZones = [
    { id: "EST", label: "Eastern", iana: "America/New_York" },
    { id: "CST", label: "Central", iana: "America/Chicago" },
    { id: "MST", label: "Mountain", iana: "America/Denver" },
    { id: "MST-AZ", label: "Mountain (Arizona)", iana: "America/Phoenix" },
    { id: "PST", label: "Pacific", iana: "America/Los_Angeles" },
    { id: "AKST", label: "Alaska", iana: "America/Juneau" },
    { id: "HST-WA", label: "Western Aleutians", iana: "America/Adak" },
    { id: "HST", label: "Hawaii", iana: "Pacific/Honolulu" },
    { id: "CHST", label: "Guam", iana: "Pacific/Guam" },
    { id: "AST", label: "Puerto Rico", iana: "America/Puerto_Rico" }
];

export const recurring = [{ title: 'Yes', value: true }, { title: 'No', value: false }];

export const recurringType = ['Weekly', 'Monthly'];

export const month = (date) => {
    const w = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const n = ["first", "second", "third", "fourth", "last", "last"];
    const dayOfMonth = date.getDate();
    const weekOfMonth = (0 | dayOfMonth / 7);
    const dayOfWeek = date.getDay();
    const weekTitle = "Every " + n[weekOfMonth] + ' ' + w[dayOfWeek];
    const monthTitle = "On the same date: " + ('0' + dayOfMonth).slice(-2);
    return [{ title: monthTitle, value: true }, { title: weekTitle, value: false }];
};

export const checkViewAccess = (grantId, context, phaseNumber) => {
    if (context.userRole == ROLE.EXTERNAL_USER) {
        for (let i = 0; i < context.externalUserRoles[grantId].length; i++) {
            const userPermission = context.externalUserRoles[grantId][i];

            if (userPermission.viewAccess.includes(phaseNumber)) {
                return true;
            }
        }

        return false;
    } else {
        if (context.userPermissions.viewAccess.includes(PHASES.ALL.NUMBER)) {
            return true;
        } else {
            return (context.userPermissions.viewAccess.includes(phaseNumber));
        }
    }
}

export const uploadAccessAllowed = (grantId, context, step) => {
    let uploadAllowed = false;

    if (context.userRole == ROLE.EXTERNAL_USER) {
        for (let i = 0; i < context.externalUserRoles[grantId].length; i++) { 
            const userPermission = context.externalUserRoles[grantId][i];

            if (userPermission.uploadAccess.includes(step)) {
                uploadAllowed = true;
                break;
            } else if (step == PHASES.B.NAME && userPermission.uploadAccess.includes(STEPS.BIDDING.NUMBER)) {
                uploadAllowed = true;
                break;
            } else if (step == PHASES.C.NAME && userPermission.uploadAccess.includes(STEPS.CONSTRUCTION.NUMBER)) {
                uploadAllowed = true;
                break;
            }
        }
    } else {
        if (context.userPermissions.uploadAccess.includes(STEPS.ALL.NUMBER)) {
            uploadAllowed = true;
        } else if (context.userPermissions.uploadAccess.includes(step)) {
            uploadAllowed = true;
        } else if (step == PHASES.B.NAME && context.userPermissions.uploadAccess.includes(STEPS.BIDDING.NUMBER)) {
            uploadAllowed = true;
        } else if (step == PHASES.C.NAME && context.userPermissions.uploadAccess.includes(STEPS.CONSTRUCTION.NUMBER)) {
            uploadAllowed = true;
        }
    }

    return uploadAllowed;
}

export const getValidStartTime = (incr, offset) => {
    const now = getCurTimeinInstrLocation(offset);
    const nowMinutes = now.getHours() * 60 + now.getMinutes();
    return nextTick(nowMinutes, incr);
};

export const getIntervalDate = (interval) => {
    const date = new Date(interval.start);
    date.setHours(0, 0, 0, 0);
    return date;
}

export const getDateMinutes = (date) => {
    return date.getHours() * 60 + date.getMinutes();
}

export const selectableHours = (hours, start, end) => {
    if (start && end) {
        return hours.slice(start, end + 1);
    } else {
        return hours;
    }
};

export const nextTick = (minutes, incr) => {
    return (1 + (minutes / incr) >> 0) * incr;
};

export const getValidStartDate = (instrStartDate, workEnd, minDuration, incr, offset) => {
    var now = getCurTimeinInstrLocation(offset);
    if (instrStartDate > now) {
        return instrStartDate;
    }

    const nowMinutes = now.getHours() * 60 + now.getMinutes();
    if (nextTick(nowMinutes, incr) + minDuration > workEnd * 60) {
        now.setHours(24, 0, 0, 0);
        return now;
    }

    now.setHours(0, 0, 0, 0);
    return now;

};

export const getWorkweekDate = (date, workWeekend) => {
    if (workWeekend) {
        return date;
    } else {
        const dayOfWeek = date.getDay();
        switch (dayOfWeek) {
            case 0:
                date.setDate(date.getDate() + 1);
                return date;
            case 6:
                date.setDate(date.getDate() + 2);
                return date;
            default:
                return date;
        }
    }
};

export const shortenDate = (inputDate) => {
    var formattedDate = inputDate;

    if (inputDate) {
        formattedDate = inputDate.substring(0, 10);
    }

    return formattedDate;
}

export const formatTime = (value) => {
    var hours = (value / 60) >> 0;
    var ampm = (hours < 12 || hours == 24) ? 'am' : 'pm';
    var minutes = value % 60;

    hours = hours % 12;
    hours = hours ? hours : 12;

    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ' ' + ampm;
}

export const maxDate = (date1, date2) => {
    return date1 > date2 ? date1 : date2;
};

export const dayOfWeek = (date, day) => {
    const dayOfWeek = date.getDay();
    return (day == dayOfWeek);
};

const buildItems = (data, timeZone) => {
    switch (data.instrumentType) {
        case 1:
            return data.requests.map((request) => {
                const item = {
                    startDate: buildTime(request.startDate, request.startTime, timeZone),
                    endDate: buildTime(request.startDate, request.endTime, timeZone),
                    recurrence: buildRecurrence(request),
                };
                return item;
            });
        case 2:
            return data.requests.map((request) => {
                const times = parseSlot(request.reqTimeSlot);
                const item = {
                    startDate: buildDate(request.reqDate, times[0], timeZone, false),
                    endDate: buildDate(request.reqDate, times[1], timeZone, true),
                    recurrence: buildRecurrence(request),
                };
                return item;
            });
        case 3:
            return data.requests.map((request) => {
                const item = {
                    startDate: new Date(request.unitDate),
                    unitCount: request.unitCount,
                    recurrence: null,
                };
                return item;
            });
    }
}

export const prepareItems = (data, timeZone, isBlock) => {

    const requestItems = buildItems(data, timeZone);

    return (isBlock)
        ? {
            requestType: data.requestType,
            submitComments: data.comments,
            requestItems,
        }
        : {
            requestType: data.requestType,
            submitComments: data.comments,
            teamMemberId: data.teamMemberId,
            userGrantId: data.userGrantId,
            newMember: data.newMember,
            publications: data.publications,
            validation: data.validation,
            requestItems,
        };
};

const buildDate = (date, time, timeZoneCode, isEnd) => {
    var tempDate = new Date(date);
    if (isEnd) {
        tempDate.setHours(hours_end.indexOf(time) + 1, 0, 0, 0);
    } else {
        tempDate.setHours(hours.indexOf(time), 0, 0, 0);
    }
    return convertToLocalTime(tempDate, { timeZone: ianaTimeZone(timeZoneCode) });
};


const buildTime = (date, time, timeZoneCode) => {
    var tempDate = new Date(date); //in local time
    tempDate.setHours(0, time, 0, 0);
    return convertToLocalTime(tempDate, { timeZone: ianaTimeZone(timeZoneCode) });
};

export const parseSlot = (slot) => {
    const parts = slot.split(" - ");
    return (parts.length == 2) ? parts : ["12 am", "12 am"];
}

const buildRecurrence = (request) => {
    if (request.recurring) {
        return {
            isMonthly: request.recurringType == "Monthly",
            sameDateOfMonth: request.recurringDay,
            weeklyMask: ((request.recurringSun) ? 1 : 0) +
                ((request.recurringMon) ? 2 : 0) +
                ((request.recurringTue) ? 4 : 0) +
                ((request.recurringWed) ? 8 : 0) +
                ((request.recurringThu) ? 16 : 0) +
                ((request.recurringFri) ? 32 : 0) +
                ((request.recurringSat) ? 64 : 0),
            endDate: request.recurringEnd,
        };
    }
    return null;
};

export const formatDuration = (minutes) => {
    if (minutes < 60) {
        return `${minutes} minutes`;
    }

    if (minutes > 60) {
        return `${minutes / 60} hours`;
    }

    return `1 hour`;
}

export const formatName = (givenName, surName) => {
    return `${givenName} ${surName}`;
}


export const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: '2-digit', month: '2-digit', day: '2-digit'
    }).format(date);
}

export const formatDateTime = (date) => {
    if (!date) {
        return '';
    }

    if (typeof (date) == "string") {
        date = Date.parse(date);
    }

    const dateText = new Intl.DateTimeFormat('en-US', {
        year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true
    }).format(date);

    return dateText.slice(0, 8) + dateText.slice(9, 15) + dateText.slice(16, 18).toLowerCase();
}

export const ianaTimeZone = (code) => {
    const timeZone = timeZones.find((timeZone) => {
        return timeZone.id == code;
    });
    return (timeZone) ? timeZone.iana : "UTC";
}

export const timeZoneLabel = (code) => {
    const timeZone = timeZones.find((timeZone) => {
        return timeZone.id == code;
    });
    return (timeZone) ? timeZone.label : "";
}

export const timeZoneCode = (offset) => {
    const timeZone = timeZones.find((timeZone) => {
        return getCurOffsetinInstrLocation(timeZone.id) == offset;
    });
    return (timeZone) ? timeZone.id : "EST";
}

export const getCurOffsetinInstrLocation = (code) => {
    var date = new Date();
    const offset = getTimezoneOffset(ianaTimeZone(code), date) / 60 / 60 / 1000;
    return offset;
}

export const getCurTimeinInstrLocation = (offset) => {
    var date = new Date();
    const localOffset = date.getTimezoneOffset(); //getTinezoneOffset returns offset with revserse sign. E.g. UTC-5 will return 300
    const delta = offset * 60 + localOffset;
    date.setMinutes(date.getMinutes() + delta);
    return date;
}

export const shiftByLocalOffset = (date) => {
    var date = new Date(date);
    const localOffset = date.getTimezoneOffset(); //getTinezoneOffset returns offset with revserse sign. E.g. UTC-5 will return 300
    date.setMinutes(date.getMinutes() - localOffset);
    return date;
}

export const getLocalTimeZoneCode = () => {
    var date = new Date();
    const localOffset = date.getTimezoneOffset() / (-60); //getTinezoneOffset returns offset with revserse sign. E.g. UTC-5 will return 300
    return timeZoneCode(localOffset);
}

export const shiftInstrTimeIntoLocalTime = (date, offset) => {
    var date = new Date(date);
    const localOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - localOffset - offset * 60);
    return date;
}

export const getMinDateForCalendar = (offset, start) => {
    const now = getCurTimeinInstrLocation(offset);
    return maxDate(now, new Date(start));
}

export const notification = (options, stack) => {
    if (!options.displayTime)
        options.displayTime = 5000;
    if (!options.width)
        options.width = '1200px';
    if (!stack)
        stack = { position: 'bottom center', direction: 'up-push' }

    notify(options, stack);
}

export const validateEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export const handleError = (error) => {
    if (error == HTTP.HTTP_401) {
        return MESSAGES.NOLONGERLOGGEDIN
    }

    if (error == HTTP.HTTP_500) {
        return MESSAGES.SERVERCANNOTPROCESSACTION
    }

    if (error == HTTP.BADREQUEST) {
        return MESSAGES.BADREQUEST
    }
    return error;
}

export const getFirstDate = (items, firstDate) => {
    for (let i = 0; i < items.length; i++) {
        let item = items[i];

        if (!item.isDirectory && moment(item.dateModified).isBefore(moment(firstDate))) {
            firstDate = item.dateModified;
        }

        if (item.isDirectory) {
            firstDate = getFirstDate(item.items, firstDate);
        }
    }

    return (firstDate === DATES.MAXIMUM) ? null : firstDate;
}

export const getLastDate = (items, lastDate) => {
    for (let i = 0; i < items.length; i++) {
        let item = items[i];

        if (!item.isDirectory && ((lastDate == null && item.dateModified  != null) || moment(item.dateModified).isAfter(moment(lastDate)))) {
            lastDate = item.dateModified;
        }

        if (item.isDirectory) {
            lastDate = getLastDate(item.items, lastDate);
        }
    }

    return (lastDate === DATES.MINIMUM) ? null : lastDate;
}

const getGrantSteps = (phaseName, steps) => {
    return steps.filter(function (step) {
        return step.phaseName === phaseName;
    });
}

export const getGrantPhase = (grant, dataFields, index, disableFields, grantId) => {

    if (!grant || !grant.documents) {
        return;
    }

    let DocumentDetails = [];

    if (index < 5) {
        const phaseNames = [PHASES.SD.NAME, PHASES.DD.NAME, PHASES.CD.NAME, PHASES.BS.NAME, PHASES.RD.NAME];
        const phaseName = phaseNames[index];

        for (let i = 0; i < grant.documents.length; i++) {
            if (grant.documents[i].name == phaseName) {
                DocumentDetails = [{
                    Documents: grant.documents[i],
                }];

                break;
            }
        }
    }

    var grantPhases = [
        [
            {
                "Phase": PHASES.SD.NAME,
                "FullPhase": PHASES.SD.FULLNAME,
                "DataFields": dataFields,
                "Steps": getGrantSteps(PHASES.SD.NAME, grant.steps),
                "PhaseId": dataFields.schematicDesign.phaseId,
                "AllowRootUpload": false
            }
        ],
        [
            {
                "Phase": PHASES.DD.NAME,
                "FullPhase": PHASES.DD.FULLNAME,
                "DataFields": dataFields,
                "Steps": getGrantSteps(PHASES.DD.NAME, grant.steps),
                "PhaseId": dataFields.designDevelopment.phaseId,
                "AllowRootUpload": false
            }
        ],
        [
            {
                "Phase": PHASES.CD.NAME,
                "FullPhase": PHASES.CD.FULLNAME,
                "DataFields": dataFields,
                "Steps": getGrantSteps(PHASES.CD.NAME, grant.steps),
                "PhaseId": dataFields.constructionDocuments.phaseId,
                "AllowRootUpload": false
            }
        ],
        [
            {
                "Phase": PHASES.BS.NAME,
                "FullPhase": PHASES.BS.FULLNAME,
                "DataFields": dataFields,
                "Steps": getGrantSteps(PHASES.BS.NAME, grant.steps),
                "PhaseId": dataFields.backcheckSubmission.phaseId,
                "AllowRootUpload": false
            }
        ],
        [
            {
                "Phase": PHASES.RD.NAME,
                "FullPhase": PHASES.RD.FULLNAME,
                "DataFields": dataFields,
                "Steps": getGrantSteps(PHASES.RD.NAME, grant.steps),
                "PhaseId": dataFields.recordDocuments.phaseId,
                "AllowRootUpload": true
            }
        ],
        [
            {
                "Phase": PHASES.DC.NAME,
                "FullPhase": PHASES.DC.FULLNAME,
                "DataFields": dataFields,
                "GrantId": grant.id,
                "Steps": getGrantSteps(PHASES.DC.NAME, grant.steps),
                "PhaseId": dataFields.designCompletion.phaseId,
                "AllowRootUpload": true
            }
        ],
        [
            {
                "Phase": PHASES.B.NAME,
                "FullPhase": PHASES.B.FULLNAME,
                "DataFields": dataFields,
                "GrantId": grant.id,
                "Steps": getGrantSteps(PHASES.B.NAME, grant.steps),
                "PhaseId": dataFields.bidding.phaseId,
                "AllowRootUpload": false
            }
        ],
        [
            {
                "Phase": PHASES.C.NAME,
                "FullPhase": PHASES.C.FULLNAME,
                "DataFields": dataFields,
                "GrantId": grant.id,
                "Steps": getGrantSteps(PHASES.C.NAME, grant.steps),
                "PhaseId": dataFields.construction.phaseId,
                "AllowRootUpload": false
            }
        ]
    ];

    let grantPhase = grantPhases[index];
    grantPhase[0].DocumentDetails = DocumentDetails;
    grantPhase[0].GrantId = grantId;
    grantPhase[0].DisableFields = disableFields;
    grantPhase[0].ConstructionDocuments = grant.documents[2];

    return grantPhase[0];
}