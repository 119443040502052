import React, { Component, useCallback } from 'react';
import { AppContext } from '../shared/AppContext.js';
import moment from 'moment';
import { handleError, TIMELINE, PHASES, STATUS, DATES } from '../shared/Utils.js';
import { Chart } from 'react-google-charts';
import $ from 'jquery';
import SelectBox from 'devextreme-react/select-box';
import dashboardApi from '../../api/DashboardApi.js';
import '../css/DashboardTimeline.css';

class DashboardTimeline extends Component {
    static contextType = AppContext;
    static state = {};

    constructor(props) {
        super(props);

        this.ganttRef = React.createRef();

        const chartWidth = 1399;
        const maxMonths = 56;

        this.state = {
            tasks: [{}],
            startDate: null,
            dataFields: props.data,
            phasesOptions: {
                text: 'Show Phases',
                stylingMode: 'text',
                showPhases: false
            },
            sliderValue: 1133,
            google: null,
            fiscalYears: [''],
            selectedFiscalYear: '',
            data: [
                [
                    { type: "string", label: "Institution" },
                    { type: "string", label: "Phase" },
                    { type: "string", role: "tooltip" },
                    { type: "number", label: "Start" },
                    { type: "number", label: "End" },
                ],
            ],

            dataSource: []
        };

        this.showLabels = false;

        this.dataLabels = [
            [
                { type: "string", label: "Institution" },
                { type: "string", label: "Phase" },
                { type: "string", role: "tooltip" },
                { type: "number", label: "Start" },
                { type: "number", label: "End" },
            ],
        ];

        this.options = {
            height: 400,
            width: chartWidth,
            timeline: {
                groupByRowLabel: true,
                colorByRowLabel: false,
                showBarLabels: false,
                showRowLabels: true,
                barLabelStyle: {
                    fontSize: 11
                },
                rowLabelStyle: {
                    fontSize: 20
                }
            },
            enableInteractivity: false,
            backgroundColor: TIMELINE.BACKGROUNDCOLOR,
            alternatingRowStyle: false,
            colors: [],
            hAxis: {
                minValue: 0,
                maxValue: maxMonths,
                gridlines: {
                    color: 'none'
                }
            },
            fontName: TIMELINE.FONTNAMES,

        };

        let rowLabelClick = (e, chartWrapper) => {
            let rawHTML = e.target.innerHTML;

            const startIndex = rawHTML.indexOf('>') + 1;
            const endIndex = rawHTML.indexOf('/') - 1;

            const institutionAndGrantNumber = rawHTML.substring(startIndex, endIndex);
            const institution = institutionAndGrantNumber.substring(0, institutionAndGrantNumber.indexOf("("));
            const grantNumber = institutionAndGrantNumber.substring(institutionAndGrantNumber.indexOf("(") + 1, institutionAndGrantNumber.indexOf(")"));

            for (let i = 0; i < this.state.dataSource[0].projectData.length; i++) {
                if (this.state.dataSource[0].projectData[i]['grantNumber'] == grantNumber) {
                    const grantId = this.state.dataSource[0].projectData[i]['grantId'].toString();

                    if (grantId) {
                        window.location.href = '/grantdetails/' + grantId;
                    }
                }
            }
        }

        let getBarData = (index) => {
            return this.state.dataSource[0].projectData[index];
        }

        let getDataRecord = (textIndex, skipRows) => {
            const dataIndex = textIndex - skipRows;

            let index = -1;
            let lastGrantNumber = "";

            for (let i = 0; i < this.state.dataSource[0].projectData.length; i++) {
                let dataRecord = this.state.dataSource[0].projectData[i];

                if (dataRecord.grantNumber != lastGrantNumber) {
                    index++;
                    lastGrantNumber = dataRecord.grantNumber;
                }

                if (index == dataIndex) {
                    return dataRecord;
                }
            }

            return null;
        }

        let getChartStart = () => {
            return moment(this.state.startDate).format("MM-DD-YYYY");
        }

        let dataSourceSet = () => {
            return (this.state.dataSource !== null && this.state.dataSource.length > 0);
        }

        let getChartEnd = () => {
            return moment(this.state.startDate).add(maxMonths, 'M').format(DATES.FORMAT);
        }

        this.chartEvents = [
            {
                eventName: 'ready',
                callback({ chartWrapper }) {
                    if (!dataSourceSet()) {
                        return;
                    }

                    let googleGraphs = $(TIMELINE.SVG);

                    if (googleGraphs.length == 0) {
                        return;
                    }

                    let chartSVGs = $(TIMELINE.SVG);
                    let chartSVG = chartSVGs[0];

                    let legendSVGs = $(TIMELINE.LEGENDSVG);
                    let legendSVG = legendSVGs[0];

                    var container = document.getElementById('chart_div');


                    //  Add Labels
                    var labels = container.getElementsByTagName('text');
                    let foundStart = false;
                    //TODO fix this, it needs to be calculated without using constants so that the chart width can be changed without further adjustments
                    const increment = 19;

                    Array.prototype.forEach.call(labels, function (label) {
                        // find x-axis labels by fill attribute
                        if (!foundStart && label.getAttribute('fill') === '#000000' && label.textContent === '0') {
                            let currentX = parseInt(label.getAttribute("x")) + increment;
                            const startY = parseInt(label.getAttribute("y"));

                            for (let i = 1; i <= maxMonths; i++) {
                                if (i == 4 || i == 10 || i == 16 || i == maxMonths) {
                                    let newText = document.createElementNS(TIMELINE.W3SVGURL, "text");
                                    newText.textContent = i;
                                    newText.setAttribute("x", currentX);
                                    newText.setAttribute("y", startY);
                                    newText.style.fill = "#000000";
                                    newText.style.fontSize = TIMELINE.CURRENDATEFONTSIZE;
                                    newText.style.fontFamily = TIMELINE.CURRENTDATEFONT;
                                    chartSVG.appendChild(newText);
                                }

                                currentX += increment;
                            }

                            foundStart = true;
                        }
                    });

                    // Left Justify Labels           
                    var texts = container.getElementsByTagName('text');
                    var paths = container.getElementsByTagName('path');
                    var firstPath = paths[0];

                    let maxWidth = 0;

                    if (firstPath) {
                        let pathD = firstPath.getAttribute("d");
                        maxWidth = parseInt(pathD.substring(pathD.indexOf("M") + 1, pathD.indexOf(","))) - 10;
                    }

                    const showTooltip = (e) => {
                        // Get tooltip
                        const tooltip = document.querySelector(".phaseTipText");

                        // Position tooltip
                        var x = e.clientX, y = e.clientY;
                        tooltip.style.top = (y - 250) + 'px';
                        tooltip.style.left = (x - 250) + 'px';

                        // Set tooltip contents
                        let phaseName = e.target.getAttribute('phaseName');
                        let phaseState = e.target.getAttribute('state');

                        let duration = e.target.getAttribute('duration');
                        tooltip.innerHTML = "<div style='" + TIMELINE.TOOLTIPSTYLE + "'><div><b>" + phaseName + "</b></div><br /><div>" + phaseState + "</div><div>Duration: " + duration + " months</div></div>";

                        // Show tooltip
                        tooltip.style.visibility = 'visible';
                    }

                    const hideTooltip = (e) => {
                        const tooltip = document.querySelector(".phaseTipText");
                        tooltip.style.visibility = 'hidden';
                    }

                    let barDataIndex = 0;
                    let skipRows = 0;
                    Array.prototype.forEach.call(texts, function (text, index) {
                        if (text.textContent == PHASES.SD.ABBREV || text.textContent == PHASES.DD.ABBREV || text.textContent == PHASES.CD.ABBREV || text.textContent == PHASES.B.ABBREV || text.textContent == PHASES.C.ABBREV) {
                            const barData = getBarData(barDataIndex++);

                            if (barData) {
                                const seriesName = barData.seriesName;
                                const duration = barData.seriesValue;
                                const phaseName = (seriesName) ? seriesName.split(",")[0] : "";
                                const state = (seriesName) ? seriesName.split(",")[1] : "";

                                text.setAttribute('phaseName', phaseName);
                                text.setAttribute('state', state);
                                text.setAttribute('duration', duration);
                                text.setAttribute('fill', '#000000');
                                text.style.cursor = 'default';

                                text.addEventListener('mouseenter', function (e) {
                                    // Stop default handling
                                    e.stopPropagation();
                                    showTooltip(e);
                                });

                                text.addEventListener('mouseleave', function (e) {
                                    e.stopPropagation();
                                    hideTooltip(e);
                                });
                            }
                        }

                        if (text.getAttribute('fill') === TIMELINE.TOOLTIPFILL) {
                            if (text.textContent && text.textContent != "undefined") {
                                var originalWidth = text.getComputedTextLength();

                                text.setAttribute('x', '10');
                                text.setAttribute('text-anchor', 'start');
                                text.setAttribute('id', index);
                                text.style.fontSize = '11pt';
                                text.style.cursor = 'pointer';

                                let dataRecord = getDataRecord(index, skipRows);

                                let newContent = dataRecord.institution;
                                text.textContent = newContent;

                                let width = text.getComputedTextLength();

                                while (width > maxWidth) {
                                    newContent = newContent.slice(0, -1);
                                    text.textContent = newContent + '...';

                                    width = text.getComputedTextLength();
                                }

                                let newTitle = document.createElementNS(TIMELINE.W3SVGURL, "title");
                                newTitle.textContent = dataRecord.institution + " (" + dataRecord.grantNumber + ")";
                                text.appendChild(newTitle);
                            }
                        } else {
                            skipRows++;
                        }
                    })

                    // Add Phase Label
                    let dataIndex = 0;

                    var rects = container.getElementsByTagName('rect');
                    Array.prototype.forEach.call(rects, function (rect, index) {
                        if (rect.getAttribute('fill') == TIMELINE.PHASEFILL) {
                            const barData = getBarData(dataIndex++);

                            if (barData) {
                                const seriesName = barData.seriesName;
                                const duration = barData.seriesValue;
                                const phaseName = (seriesName) ? seriesName.split(",")[0] : "";
                                const state = (seriesName) ? seriesName.split(",")[1] : "";

                                let label = "";

                                switch (phaseName) {
                                    case PHASES.SD.NAME:
                                        label = PHASES.SD.ABBREV;
                                        break;

                                    case PHASES.DD.NAME:
                                        label = PHASES.DD.ABBREV;
                                        break;

                                    case PHASES.CD.NAME:
                                        label = PHASES.CD.ABBREV;
                                        break;

                                    case PHASES.B.NAME:
                                        label = PHASES.B.ABBREV;
                                        break;

                                    case PHASES.C.NAME:
                                        label = PHASES.C.ABBREV;
                                        break;
                                }

                                const x = parseInt($(rect).attr('x')) + 5;
                                const y = parseInt($(rect).attr('y')) + 17;

                                let newText = document.createElementNS(TIMELINE.W3SVGURL, "text");
                                newText.setAttribute('x', x);
                                newText.setAttribute('y', y);
                                newText.setAttribute('dx', '0px');
                                newText.setAttribute('fill', '#000000');
                                newText.textContent = label;
                                newText.style.cursor = 'default';
                                newText.style.pointerEvents = 'none';

                                newText.addEventListener('mouseenter', function (e) {
                                    e.stopPropagation();
                                    showTooltip(e);
                                });

                                newText.addEventListener('mouseleave', function (e, index) {
                                    e.stopPropagation();
                                    hideTooltip(e);
                                });

                                rect.setAttribute('stroke', '#ffffff');
                                rect.setAttribute('stroke-width', '1');
                                rect.parentNode.appendChild(newText);
                            }
                        }
                    });

                    // remove gridlines
                    var paths = container.getElementsByTagName('path');
                    Array.prototype.forEach.call(paths, function (path) {
                        if ((path.getAttribute('stroke') === TIMELINE.GRIDLINECOLOR1) || 
                            (path.getAttribute('stroke') === TIMELINE.GRIDLINECOLOR1) ||
                            (path.getAttribute('stroke') === TIMELINE.GRIDLINECOLOR3) 
                        ) {
                            // remove border
                            path.setAttribute('stroke-width', '0');
                        }
                    })

                    var rects = container.getElementsByTagName('rect');

                    dataIndex = 0;
                    Array.prototype.forEach.call(rects, function (rect, index) {
                        // Set fill color
                        if (rect.getAttribute('fill') === TIMELINE.PHASEFILL) {
                            const barData = getBarData(dataIndex++);

                            if (barData) {
                                const seriesName = barData.seriesName;
                                const duration = barData.seriesValue;
                                const phaseName = (seriesName) ? seriesName.split(",")[0] : "";
                                const state = (seriesName) ? seriesName.split(",")[1] : "";

                                //console.log(seriesName);
                                //console.log(duration);
                                //console.log(phaseName);
                                //console.log(state);

                                let stateColor = "";

                                rect.setAttribute('phaseName', phaseName);
                                rect.setAttribute('state', state);
                                rect.setAttribute('duration', duration);

                                rect.addEventListener('mouseenter', function (e) {
                                    e.stopPropagation();
                                    //console.log('mouseover');

                                    showTooltip(e);
                                });

                                rect.addEventListener('mouseleave', function (e, index) {
                                    e.stopPropagation();
                                    //console.log('mouseout');
                                    hideTooltip(e);
                                });



                                switch (state) {
                                    case STATUS.COMPLETED:
                                        stateColor = TIMELINE.COMPLETEDCOLOR;
                                        break;

                                    case STATUS.SLIGHTLYLATE:
                                        stateColor = TIMELINE.SLIGHTLYLATECOLOR;
                                        break;

                                    case STATUS.LATE:
                                        stateColor = TIMELINE.LATECOLOR;
                                        break;

                                    case STATUS.INPROGRESS:
                                        stateColor = TIMELINE.INPROGRESSCOLOR;
                                        break;

                                    case STATUS.SCHEDULED:
                                        stateColor = TIMELINE.SCHEDULEDCOLOR;
                                        break;

                                }

                                rect.setAttribute('fill', stateColor);
                            }
                        }

                        if (rect.getAttribute('stroke') === TIMELINE.BORDERCOLOR) {
                            // Remove border
                            rect.setAttribute('stroke-width', '0');
                        }
                    })

                    // Add click to row label
                    $(chartWrapper.getContainer()).find('text[text-anchor="start"]').on('click', (e) => { rowLabelClick(e, chartWrapper); });

                    // Add vertical blue line and current date
                    const startDate = getChartStart();
                    const endDate = getChartEnd();

                    const timeRange = moment(endDate).diff(moment(startDate));
                    const today = moment().diff(moment(startDate));
                    const todayMonths = Math.ceil(moment().diff(moment(startDate), 'months', true));

                    //TODO fix this, it needs to be calculated without using constants so that the chart width can be changed without further adjustments
                    const ratio = (chartWidth - maxWidth - 30) / maxMonths;
                    let pathX = (todayMonths * ratio) + maxWidth + 10;

                    if (legendSVG === undefined) {
                        pathX += 5;
                    }

                    const rowCount = 10;

                    let newPath = document.createElementNS(TIMELINE.W3SVGURL, "path");
                    newPath.setAttribute("d", "M " + pathX + " 0 L " + pathX + " " + (rowCount * 56));
                    newPath.style.stroke = "blue";
                    newPath.style.strokeWidth = "1";
                    newPath.style.fillOpacity = "1";
                    newPath.style.fill = "none";

                    if (legendSVG !== undefined) {
                        legendSVG.appendChild(newPath);
                    } else {
                        chartSVG.appendChild(newPath);
                    }

                    // Date and line in legend
                    let newPath2 = document.createElementNS(TIMELINE.W3SVGURL, "path");
                    newPath2.setAttribute("d", "M " + pathX + " " + rowCount * 40 + " L " + pathX + " " + 100);
                    newPath2.style.stroke = "blue";
                    newPath2.style.strokeWidth = "1";
                    newPath2.style.fillOpacity = "1";
                    newPath2.style.fill = "none";
                    chartSVG.appendChild(newPath2);

                    let newText = document.createElementNS('http://www.w3.org/2000/svg', "text");
                    newText.textContent = moment().format(DATES.FORMAT);

                    if (today < timeRange / 2) {
                        newText.setAttribute("x", pathX + 10);
                    } else {
                        newText.setAttribute("x", pathX - 70);
                    }

                    newText.setAttribute("y", (rowCount * 40));
                    newText.style.fill = TIMELINE.CURRENTDATECOLOR;
                    newText.style.fontSize = TIMELINE.CURRENDATEFONTSIZE;
                    newText.style.fontWeight = TIMELINE.CURRENDATEFONTWEIGHT;
                    newText.style.fontFamily = TIMELINE.CURRENDATEFONT;
                    chartSVG.appendChild(newText);

                }
            }
        ];

    }

    componentDidMount() {
        this.populateData();
    }

    setPhasePallet(state) {
        switch (state) {
            case STATUS.COMPLETED:
                this.options.colors.push(TIMELINE.COMPLETEDCOLOR);
                break;

            case STATUS.SLIGHTLYLATE:
                this.options.colors.push(TIMELINE.SLIGHTLYLATECOLOR);
                break;

            case STATUS.LATE:
                this.options.colors.push(TIMELINE.LATECOLOR);
                break;

            case STATUS.INPROGRESS:
                this.options.colors.push(TIMELINE.INPROGRESSCOLOR);
                break;

            case STATUS.SCHEDULED:
                this.options.colors.push(TIMELINE.SCHEDULEDCOLOR);
                break;
        }
    }

    fiscalYearsLabel = { 'aria-label': 'Fiscal Years' };

    render() {
        return (
            <div className="container-fluid panelBody">
                <div className="phaseTip">
                    <span className="phaseTipText">aaaa</span>
                </div>
                <div className="row grantVisualizationRow">
                    <div className="dx-field col-12">
                        <br />
                        <div className="widget-container" id="chart_div" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                            <div className="col-4 mx-auto">
                                <SelectBox
                                    items={this.state.fiscalYears}
                                    inputAttr={this.fiscalYearsLabel}
                                    value={this.state.selectedFiscalYear}
                                    onValueChanged={this.onFiscalYearChanged}
                                />
                            </div>
                            <br style={{ marginBottom: '10%' }} />
                            <div id="chartCaptions"></div>
                            <Chart
                                chartType="Timeline"
                                data={this.state.data}
                                options={this.options}
                                chartEvents={this.chartEvents}
                            />
                        </div>
                        <div id="chartLegend" style={{ height: 35, marginBottom: 10 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="none" strokeWidth="0" className="dxc dxc-chart" width="1016" height="400" style={{ lineHeight: 'normal', userSelect: 'none', display: 'block', overflow: 'hidden' }} direction="ltr">
                                <defs>
                                    <filter id="DevExpress_1" x="-50%" y="-50%" width="200%" height="200%" transform="translate(0,0)">
                                        <feGaussianBlur in="SourceGraphic" result="gaussianBlurResult" stdDeviation="3"></feGaussianBlur>
                                        <feOffset in="gaussianBlurResult" result="offsetResult" dx="2" dy="6"></feOffset>
                                        <feFlood result="floodResult" floodColor="#d3d3d3" floodOpacity="0.8"></feFlood>
                                        <feComposite in="floodResult" in2="offsetResult" operator="in" result="compositeResult"></feComposite>
                                        <feComposite in="SourceGraphic" in2="compositeResult" operator="over"></feComposite>
                                    </filter>
                                    <clipPath id="DevExpress_2">
                                        <rect x="0" y="0" width="1016" height="400" transform="translate(0,0)"></rect>
                                    </clipPath>
                                    <clipPath id="DevExpress_4">
                                        <rect x="-137" y="-10" width="961" height="57" transform="translate(0,0)"></rect>
                                    </clipPath>
                                    <clipPath id="DevExpress_5">
                                        <rect x="484" y="51" width="525" height="244" transform="translate(0,0)"></rect>
                                    </clipPath>
                                    <clipPath id="DevExpress_6">
                                        <rect x="484" y="51" width="525" height="244" transform="translate(0,0)"></rect>
                                    </clipPath>
                                </defs>
                                <g className="dxc-legend" clipPath="url(#DevExpress_2)" transform="translate(0,0)">
                                    <g transform="translate(640,0)">
                                        <g className="dxc-item" style={{ fill: "rgb(118, 118, 118)", fontFamily: 'Segoe UI', fontWight: 400, fontSize: 12 }}>
                                            <g>
                                                <g className="dxl-marker" transform="translate(2,20)" visibility="visible">
                                                    <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="#83D18E" opacity="1"></rect>
                                                </g>
                                                <text x="0" y="0" transform="translate(7,13)" textAnchor="middle" style={{ whiteSpace: "pre" }} >Completed</text>
                                            </g>
                                            <g>
                                                <g className="dxl-marker" transform="translate(90,20)" visibility="visible">
                                                    <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="yellow" opacity="1"></rect>
                                                </g>
                                                <text x="0" y="0" transform="translate(90,13)" textAnchor="middle" style={{ whiteSpace: "pre" }}>Slightly Late</text>
                                            </g>
                                            <g>
                                                <g className="dxl-marker" transform="translate(180,20)" visibility="visible">
                                                    <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="#C80112" opacity="1"></rect>
                                                </g>
                                                <text x="0" y="0" transform="translate(185,13)" textAnchor="middle" style={{ whiteSpace: "pre" }}>Late (> 2 months)</text>
                                            </g>
                                            <g>
                                                <g className="dxl-marker" transform="translate(270,20)" visibility="visible">
                                                    <rect x="0" y="0" width="12" height="12" transform="translate(0,0)" fill="#f2a600" opacity="1"></rect>
                                                </g>
                                                <text x="0" y="0" transform="translate(277,13)" textAnchor="middle" style={{ whiteSpace: "pre" }}>In Progress</text>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onFiscalYearChanged = val => {
        this.setState({ selectedFiscalYear: val.value });
        this.populateData(val.value);
    }

    async populateData(fiscalYear) {
        const fiscalYears = await dashboardApi.getFiscalYears();
        const dataSource = await dashboardApi.getData((fiscalYear) ? fiscalYear : fiscalYears[0]);
        const projectDataArray = (dataSource && dataSource.length > 0) ? dataSource[0].projectData : null;
        const startDate = (dataSource && dataSource.length > 0) ? dataSource[0].startDate : null;


        let saveInstitution = "";
        let start = 0;
        let institutionList = [];
        let data = [];
        data.push(...this.dataLabels);
        this.options.colors = [];

        if (projectDataArray) {
            for (let i = 0; i < projectDataArray.length; i++) {
                const projectData = projectDataArray[i];
                let institution = projectData.institution + " (" + projectData.grantNumber + ")";
                const seriesName = (projectData.seriesName) ? projectData.seriesName.split(',') : "";
                const seriesValue = projectData.seriesValue;
                const phaseName = (seriesName) ? seriesName[0] : "";

                let phaseAbbrev = "";

                switch (phaseName) {
                    case PHASES.SD.NAME:
                        phaseAbbrev = PHASES.SD.ABBREV;
                        break;

                    case PHASES.DD.NAME:
                        phaseAbbrev = PHASES.DD.ABBREV;
                        break;

                    case PHASES.CD.NAME:
                        phaseAbbrev = PHASES.CD.ABBREV;
                        break;

                    case PHASES.B.NAME:
                        phaseAbbrev = PHASES.B.ABBREV;
                        break;

                    case PHASES.C.NAME:
                        phaseAbbrev = PHASES.C.ABBREV;
                        break;
                }

                const phaseState = (seriesName) ? seriesName[1] : "";

                if (saveInstitution == "" || institution != saveInstitution) {
                    start = 0;
                    saveInstitution = institution;
                }

                const end = start + seriesValue;

                //  Add rows here
                data.push([institution, phaseAbbrev, null, start, end]);
                start = end;
            }
        }

        this.options.colors.push(TIMELINE.PHASEFILL);

        this.setState({
            ...this.state,
            data: data,
            startDate: startDate,
            dataSource: dataSource,
            fiscalYears: fiscalYears, selectedFiscalYear: (fiscalYear) ? fiscalYear : fiscalYears[0]
        });
    }

    async onError(error) {
        const message = handleError(error);
        this.props.navigate('/error', { state: { props: message } });
    }
}

export default DashboardTimeline;

