import React, { useCallback, useState, useEffect } from 'react';
import { Button as ReactButton } from 'devextreme-react/button';
import { ROLE, EXTERNALUSERROLE, handleError } from '../shared/Utils.js';
import { Autocomplete } from 'devextreme-react/autocomplete';
import List from 'devextreme-react/list';
import grantApi from '../../api/GrantApi.js';
import CustomStore from 'devextreme/data/custom_store';
import { Validator, AsyncRule, EmailRule } from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import '../css/GrantDetailsExternalUsers.css';

const GrantDetailsExternalUsers = ({ grantId }) => {
    const [externalUsers, setExternalUsers] = useState([]);

    useEffect(() => {
        if (grantId !== undefined) {
            populateExternalUsers(grantId);
        }
    }, [grantId])

    const onError = async (error) => {
        console.log(error);

        const message = handleError(error);
    }

    const populateExternalUsers = async (grantId) => {
        const externalUsers = await grantApi.getGrantExternalUsers(grantId, onError);

        setExternalUsers(externalUsers);
    }

    const getExternalUsers = (role) => {
        return externalUsers.filter(externalUser => externalUser.externalUserRole == role);
    }

    const onExternalUserAdded = async (externalUser, role) => {
        await grantApi.addGrantExternalUser(grantId, externalUser, role, onError);
        populateExternalUsers(grantId);
    };


    const onExternalUserDeleted = async (e) => {
        await grantApi.deleteGrantExternalUser(e.itemData.grantId, e.itemData.externalUserId, e.itemData.externalUserRole, onError);

        await populateExternalUsers(e.itemData.grantId);
    };

    const getExternalUsersStore = (role) => {
        const externalUsersStore = new CustomStore({
            key: 'Value',
            useDefaultSearch: true,
            load: (loadOptions) => {
                if (loadOptions.filter !== undefined) {
                    const skip = loadOptions.skip;
                    const take = loadOptions.take;
                    const filter = (loadOptions.filter !== 'undefined') ? loadOptions.filter[0][2] : null;

                    return grantApi.getGrantExternalUsersFiltered(grantId, role, skip, take, filter, onError);
                } else {
                    return null;
                }
            },
        });

        return externalUsersStore;
    }


    const emailExists = async (email, grantId, externalUserRole) => {
        return await grantApi.externalUserEmailExists(email, grantId, externalUserRole, onError);
    }

    const sendRequest = (email, grantId, externalUserRole) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(emailExists(email, grantId, externalUserRole));
            }, 1000);
        });
    }

    return (
        <div className="container-fluid panelBody">
            <ExternalUserList headingLabel="Reviewers" externalUserType={EXTERNALUSERROLE.REVIEWER} singleUser={false} height={'auto'} grantId={grantId}
                onExternalUserAdded={onExternalUserAdded} onExternalUserDeleted={onExternalUserDeleted} getExternalUsers={getExternalUsers} getExternalUsersStore={getExternalUsersStore} validationRequest={sendRequest} />

            <ExternalUserList headingLabel="AE Designers" externalUserType={EXTERNALUSERROLE.AE_DESIGNER} singleUser={false} height={'auto'} grantId={grantId}
                onExternalUserAdded={onExternalUserAdded} onExternalUserDeleted={onExternalUserDeleted} getExternalUsers={getExternalUsers} getExternalUsersStore={getExternalUsersStore} validationRequest={sendRequest} />

            <ExternalUserList headingLabel="Project Manager" externalUserType={EXTERNALUSERROLE.PROJECT_MANAGER} singleUser={false} height={'auto'} grantId={grantId}
                onExternalUserAdded={onExternalUserAdded} onExternalUserDeleted={onExternalUserDeleted} getExternalUsers={getExternalUsers} getExternalUsersStore={getExternalUsersStore} validationRequest={sendRequest} />
        </div>
    );
}

export default GrantDetailsExternalUsers;

const ExternalUserList = ({ headingLabel, externalUserType, singleUser, height, grantId, onExternalUserAdded, onExternalUserDeleted, getExternalUsers, getExternalUsersStore, validationRequest }) => {

    const [externalUser, setExternalUser] = useState('');
    const [externalUserValid, setExternalUserValid] = useState(false);

    const onExternalUserChange = useCallback((e) => {
        setExternalUser(e.value);
    }, []);

    const onAddExternalUserClick = async (e) => {
        await onExternalUserAdded(externalUser, externalUserType);
        setExternalUser('');
    };

    const onExternalUserValidated = (validationInfo) => {
        setExternalUserValid(validationInfo.status === 'valid');
    }

    const addUserEnabled = () => {
        return !singleUser || getExternalUsers(externalUserType).length == 0;
    }

    const externalUserInfo = (externalUser) => {
        return externalUser.emailAddress;
    }

    return (<>
        <div className="row externalUsersCaptionRow">
            <div className="dx-field col-12">
                <div className="dx-field-label"><h5>{headingLabel}</h5></div>
            </div>
        </div>
        <div className="row externalUsersRow">
            <div className="dx-field col-12">
                <div className="container-fluid panelBody">
                    <div className="row externalUsersRow">
                        <div className="dx-field col-12">
                            <List
                                height={height}
                                keyExpr="id"
                                dataSource={getExternalUsers(externalUserType)}
                                allowItemDeleting={true}
                                itemDeleteMode={'toggle'}
                                itemRender={externalUserInfo}
                                onItemDeleted={onExternalUserDeleted}
                            />
                        </div>
                    </div>
                    {
                        addUserEnabled()
                            ?
                            <div className="row externalUsersRow">
                                <div className="dx-field col-10">
                                    <ValidationGroup>
                                        <Autocomplete
                                            dataSource={getExternalUsersStore(externalUserType)}
                                            value={externalUser}
                                            onValueChanged={onExternalUserChange}
                                            showClearButton={true}
                                            placeholder="Enter an email address..."
                                            validationMessagePosition="right" >
                                            <Validator
                                                onValidated={onExternalUserValidated}>
                                                <EmailRule ignoreEmptyValue={true}
                                                    message="Email is invalid" />
                                                <AsyncRule ignoreEmptyValue={true}
                                                    message="Email is already registered"
                                                    validationCallback={(params) => {
                                                        return validationRequest(params.value, grantId, externalUserType);
                                                    }}
                                                />
                                            </Validator>
                                        </Autocomplete>
                                    </ValidationGroup>
                                </div>
                                <div className="dx-field col-2">
                                    <ReactButton
                                        width={100}
                                        className="externalUsersAddButton"
                                        text="Add"
                                        disabled={!externalUserValid}
                                        onClick={onAddExternalUserClick} />

                                </div>
                            </div> : null}
                </div>
            </div>
        </div>
    </>);
}