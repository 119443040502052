import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Button as ReactButton } from 'devextreme-react/button';
import { AppContext } from '../shared/AppContext.js';
import moment from 'moment';
import { withRouter } from '../shared/WithRouter.js';
import grantApi from '../../api/GrantApi.js';
import '../css/Settings.css';
import '../css/GrantDetails.css';
import GrantDetailHeader from './GrantDetailHeader.js';
import GrantDetailPhases from './GrantDetailPhases.js';
import notify from 'devextreme/ui/notify';
import { Popup } from 'devextreme-react/popup';
import { handleError, getGrantPhase, getLastDate } from '../shared/Utils.js';
import { getFirstDate, shortenDate } from '../shared/Utils.js';
import useAsync from '../../hooks/useAsync.js';
import { ROLE, OFFSET, DATES, PHASES, MESSAGES } from '../shared/Utils.js';
import { LoadPanel } from 'devextreme-react/load-panel';

const GrantDetails = (props) => {
    const context = useContext(AppContext);

    const [state, setState] = useState({});
    const [grantData, setGrantData] = useState({});
    const [phaseData, setPhaseData] = useState({});
    const [disableSaving, setDisableSaving] = useState(true);
    const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false);

    const params = useParams();
    const grantId = params.id;

    const onError = async (error) => {
        const message = handleError(error);
        props.navigate('/error', { state: { props: message } });
    }

    const getComment = (phaseDocuments) => {
        if (phaseDocuments !== undefined) {
            if (phaseDocuments.items) {
                const lastDate = getLastDate(phaseDocuments.items, DATES.MINIMUM);

                if (lastDate === null || lastDate === DATES.MINIMUM) {
                    return "";
                } else {
                    return (phaseDocuments.items) ? "Last Update: " + moment(lastDate).format(DATES.FORMAT) : "";
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const populateData = async (disableSaving) => {
        if (grantId) {
            setLoadIndicatorVisible(true);

            const newGrantData = await grantApi.getGrantData(grantId, onError.bind(this));
            const newPhaseData = await grantApi.getPhaseData(grantId, onError.bind(this));

            if (!newGrantData.override) {
                newPhaseData.schematicDesign.comments = getComment(newGrantData.documents.find(d => d.name == PHASES.SD.NAME));
                newPhaseData.designDevelopment.comments = getComment(newGrantData.documents.find(d => d.name == PHASES.DD.NAME));
                newPhaseData.constructionDocuments.comments = getComment(newGrantData.documents.find(d => d.name == PHASES.CD.NAME));
                newPhaseData.backcheckSubmission.comments = getComment(newGrantData.documents.find(d => d.name == PHASES.BS.NAME));
                newPhaseData.recordDocuments.comments = getComment(newGrantData.documents.find(d => d.name == PHASES.RD.NAME));

                // Get later phase comments
                let lastDCDocumentDate = moment(DATES.MINIMUM);
                let lastBDDocumentDate = moment(DATES.MINIMUM);
                let lastCDocumentDate = moment(DATES.MINIMUM);

                let dcComment = null;
                let bComment = null;
                let cComment = null

                for (let i = 0; i < newGrantData.steps.length; i++) {
                    if (newGrantData.steps[i].phaseName == PHASES.DC.NAME) {
                        dcComment = newGrantData.steps[i].phaseComments;
                    } else if (newGrantData.steps[i].phaseName == PHASES.B.NAME) {
                        bComment = newGrantData.steps[i].phaseComments;
                    } else if (newGrantData.steps[i].phaseName == PHASES.C.NAME) {
                        cComment = newGrantData.steps[i].phaseComments;
                    }
                }

                newPhaseData.designCompletion.comments = (dcComment) ? dcComment : null;
                newPhaseData.bidding.comments = (bComment) ? bComment : null;
                newPhaseData.construction.comments = (cComment) ? cComment : null;
            }

            var noaDate = moment(newGrantData.noa).format(DATES.FORMAT);

            newPhaseData.schematicDesign.Offset = OFFSET.SCHEMATICDESIGN;
            newPhaseData.designDevelopment.Offset = OFFSET.DESIGNDEVELOPMENT;
            newPhaseData.constructionDocuments.Offset = OFFSET.CONSTRUCTIONDOCUMENTS;
            newPhaseData.backcheckSubmission.Offset = OFFSET.BACKCHECKSUBMISSION;
            newPhaseData.bidding.Offset = OFFSET.BIDDING;
            newPhaseData.construction.Offset = OFFSET.CONSTRUCTION;
            newPhaseData.schematicDesign.Start = moment(noaDate).format(DATES.FORMAT);
            newPhaseData.schematicDesign.Projected = moment(newPhaseData.schematicDesign.Start).add(newPhaseData.schematicDesign.Offset, 'months').format(DATES.FORMAT);
            newPhaseData.designDevelopment.Start = newPhaseData.schematicDesign.Projected;
            newPhaseData.designDevelopment.Projected = moment(newPhaseData.designDevelopment.Start).add(newPhaseData.designDevelopment.Offset, 'months').format(DATES.FORMAT);
            newPhaseData.constructionDocuments.Start = newPhaseData.designDevelopment.Projected;
            newPhaseData.constructionDocuments.Projected = moment(newPhaseData.constructionDocuments.Start).add(newPhaseData.constructionDocuments.Offset, 'months').format(DATES.FORMAT);
            newPhaseData.bidding.Start = newPhaseData.constructionDocuments.Projected;
            newPhaseData.bidding.Projected = moment(newPhaseData.bidding.Start).add(newPhaseData.bidding.Offset, 'months').format(DATES.FORMAT);
            newPhaseData.construction.Start = newPhaseData.bidding.Projected;
            newPhaseData.construction.Projected = moment(newPhaseData.construction.Start).add(newPhaseData.construction.Offset, 'months').format(DATES.FORMAT);

            let grantPhases = [];

            for (let i = 0; i <= 7; i++) {
                grantPhases[i] = getGrantPhase(newGrantData, newPhaseData, i, disableSaving, grantId);
            }

            newPhaseData.grantPhases = grantPhases;

            setGrantData(newGrantData);
            setPhaseData(newPhaseData);

            setLoadIndicatorVisible(false);
        }
    }

    useEffect(() => {
        const disableSaving = userSavingDisabled(context.userRole, context.userProfiles);
        setDisableSaving(disableSaving);

        if (context.loginUser && !grantData.grantId) {
            populateData(disableSaving);
        }

    }, [context])

    useEffect(() => {
        setState({
            ...state,
            grant: {},
            grantPhases: [],
            grantModified: false,
            showModifiedPopup: false,
            showOverridePopup: false,
            originalDataFields: {},
        });
    }, [])

    const { loading, error, value } = useAsync(async () => {
        if (context.loginUser) {
            await populateData();
        }
    });

    const onBackClick = (e) => {
        if (state.grantModified) {
            setState({
                ...state,
                showModifiedPopup: true
            });
        }
        else {
            history.back();
        }
    }

    const onOkClick = (e) => {
        setState({
            ...state,
            grantModified: false,
            showModifiedPopup: false,
            showOverridePopup: false,
        });

        history.back();
    };

    const onCancelPopupClick = (e) => {
        setState({
            ...state,
            showModifiedPopup: false,
            showOverridePopup: false,
        });
    };

    const userSavingDisabled = (userRole, userProfiles) => {
        if (userRole === ROLE.PO) {
            return false;
        } else if (userRole === ROLE.PI) {
            // POs
            const foundProfile = userProfiles.find((profile) => profile.profileId == state.grant.poProfileId);

            if (foundProfile) {
                return false;
            }
        } else if (userRole === ROLE.EXTERNAL_USER) {
            return true;
        }

        return true;
    }

    const onSaveClick = async (e) => {
        if (state.grantModified) {
            const response = await grantApi.updateGrant(grantData, phaseData);
        }

        setState({
            ...state,
            grantModified: false
        });

        notify(MESSAGES.UPDATESSAVED)
    }

    const getGrantDetailsTitle = () => {
        return (state.grant.fy) ? state.grant.fy + ' - ' + state.grant.institution : "";
    }

    const setShortInstitution = (shortInstitution) => {
        setGrantData({
            ...grantData,
            shortInstitution: shortInstitution
        });

        setState({
            ...state,
            grantModified: true
        });
    }

    const setShortTitle = (shortTitle) => {
        setGrantData({
            ...grantData,
            shortTitle: shortTitle
        });

        setState({
            ...state,
            grantModified: true
        });
    }
    
    const setData = async (updatedData, phaseName, phaseIndex) => {
        let newPhaseData = null;

        if (phaseIndex != null) {
            newPhaseData = { ...phaseData };
            newPhaseData.grantPhases[phaseIndex].DocumentDetails[0].Documents.items = updatedData;

            let newGrantData = { ...grantData };
            let grantDataDocuments = newGrantData.documents.find(d => d.name == phaseName);
            grantDataDocuments.items = updatedData;

            resetPhaseData(newGrantData);
            setGrantData(newGrantData);

            // Save changes to the db
            const response = await grantApi.updateGrant(newGrantData, newPhaseData);
        } else {
            newPhaseData = { ...updatedData };
        }

        setPhaseData(newPhaseData);

        setState({
            ...state,
            grantModified: true
        });
    }

    const setBudgetEnd = (budgetEndDate) => {
        if (moment(shortenDate(state.grant.budgetEndDate)).format(DATES.FORMAT) !== budgetEndDate) {
            setGrantData({
                ...grantData,
                budgetEndDate: budgetEndDate
            });

            setState({
                ...state,
                grantModified: true,
            });
        }
    }

    const getPhaseDocuments = (documents, phaseName) => {
        let phaseDocuments = null;

        for (let i = 0; i < documents.length; i++) {
            if (documents[i].name == phaseName) {
                phaseDocuments = documents[i];
                break;
            }
        }

        return (phaseDocuments && phaseDocuments.items && phaseDocuments.items.length > 0) ? phaseDocuments : null;
    }

    const phaseHasDocuments = (phaseFolder) => {
        for (var i = 0; i < phaseFolder.items.length; i++) {
            if (!phaseFolder.items[i].isDirectory || (phaseFolder.items[i].isDirectory && phaseFolder.items[i].items.length > 0)) {
                return true;
            }
        }

        return false;
    }

    const resetPhaseData = (gd) => {
        let grantPhases;
        let updatedPhaseData = phaseData;

        // Reset Phase Dates
        const sdDocuments = gd.documents.find(d => d.name == PHASES.SD.NAME);
        const ddDocuments = gd.documents.find(d => d.name == PHASES.DD.NAME);
        const cdDocuments = gd.documents.find(d => d.name == PHASES.CD.NAME);
        const bsDocuments = gd.documents.find(d => d.name == PHASES.BS.NAME);
        const odDocuments = gd.documents.find(d => d.name == PHASES.RD.NAME);

        // Schematic Design
        if (phaseHasDocuments(sdDocuments)) {
            updatedPhaseData.schematicDesign.submission = gd.noa;

            if (phaseHasDocuments(ddDocuments)) {
                updatedPhaseData.schematicDesign.completion = getLastDate(sdDocuments.items, DATES.MINIMUM);
            } else {
                updatedPhaseData.schematicDesign.completion = null;
            }
        } else if (phaseHasDocuments(ddDocuments)) {
            updatedPhaseData.schematicDesign.submission = getFirstDate(ddDocuments.items, DATES.MAXIMUM);
            updatedPhaseData.schematicDesign.completion = getFirstDate(ddDocuments.items, DATES.MAXIMUM);;
        } else if (phaseHasDocuments(cdDocuments)) {
            updatedPhaseData.schematicDesign.submission = getFirstDate(cdDocuments.items, DATES.MAXIMUM);
            updatedPhaseData.schematicDesign.completion = getFirstDate(cdDocuments.items, DATES.MAXIMUM);;
        } else {
            updatedPhaseData.schematicDesign.submission = gd.noa;
            updatedPhaseData.schematicDesign.completion = null;
        }

        // Design Development
        if (phaseHasDocuments(ddDocuments)) {
            updatedPhaseData.designDevelopment.submission = updatedPhaseData.schematicDesign.completion;

            if (phaseHasDocuments(cdDocuments)) {
                updatedPhaseData.designDevelopment.completion = getLastDate(ddDocuments.items, DATES.MINIMUM);
            } else {
                updatedPhaseData.designDevelopment.completion = null;
            }
        } else if (phaseHasDocuments(cdDocuments)) {
            updatedPhaseData.designDevelopment.submission = getFirstDate(cdDocuments.items, DATES.MAXIMUM);
            updatedPhaseData.designDevelopment.completion = getFirstDate(cdDocuments.items, DATES.MAXIMUM);;
        } else {
            updatedPhaseData.designDevelopment.submission = null;
            updatedPhaseData.designDevelopment.completion = null;
        }

        // Construction Documents
        if (phaseHasDocuments(cdDocuments)) {
            updatedPhaseData.constructionDocuments.submission = updatedPhaseData.designDevelopment.completion;
            updatedPhaseData.constructionDocuments.completion = null;
        } else {
            updatedPhaseData.constructionDocuments.submission = null;
            updatedPhaseData.constructionDocuments.completion = null;
        }

        // Bidding
        let dcDocumentFound = false;
        let bdDocumentFound = false;

        let firstDCDocumentDate = moment();
        let lastDCDocumentDate = moment(DATES.MINIMUM);
        let lastBDDocumentDate = moment(DATES.MINIMUM);
        let lastCDocumentDate = moment(DATES.MINIMUM);

        let dcComment = null;
        let bComment = null;
        let cComment = null

        updatedPhaseData.bidding.submission = null;
        updatedPhaseData.bidding.completion = null;

        for (let i = 0; i < gd.steps.length; i++) {
            if (gd.steps[i].phaseName == PHASES.DC.NAME) {
                if (moment(gd.steps[i].uploadDate).isBefore(firstDCDocumentDate)) {
                    dcDocumentFound = true;

                    firstDCDocumentDate = gd.steps[i].uploadDate;
                }

                if (moment(gd.steps[i].uploadDate).isAfter(lastDCDocumentDate)) {
                    dcDocumentFound = true;

                    lastDCDocumentDate = gd.steps[i].uploadDate;
                    dcComment = "Last Update: " + moment(lastDCDocumentDate).format(DATES.FORMAT);
                }
            }

            if (gd.steps[i].phaseName == PHASES.B.NAME) {
                if (moment(gd.steps[i].uploadDate).isAfter(lastBDDocumentDate)) {
                    bdDocumentFound = true;

                    lastBDDocumentDate = gd.steps[i].uploadDate;
                    bComment = "Last Update: " + moment(lastBDDocumentDate).format(DATES.FORMAT);
                }
            }

            if (gd.steps[i].phaseName == PHASES.C.NAME) {
                if (moment(gd.steps[i].uploadDate).isAfter(lastCDocumentDate)) {
                    lastCDocumentDate = gd.steps[i].uploadDate;
                    cComment = "Last Update: " + moment(lastCDocumentDate).format(DATES.FORMAT);
                }
            }
        }

        if (dcDocumentFound) {
            updatedPhaseData.bidding.submission = firstDCDocumentDate;
        }

        //Construction
        updatedPhaseData.construction.submission = null;
        updatedPhaseData.construction.completion = null;

        updatedPhaseData.schematicDesign.comments = getComment(sdDocuments);
        updatedPhaseData.designDevelopment.comments = getComment(ddDocuments);
        updatedPhaseData.constructionDocuments.comments = getComment(cdDocuments);
        updatedPhaseData.backcheckSubmission.comments = getComment(bsDocuments);
        updatedPhaseData.recordDocuments.comments = getComment(odDocuments);

        updatedPhaseData.designCompletion.comments = (dcComment) ? dcComment : null;
        updatedPhaseData.bidding.comments = (bComment) ? bComment : null;
        updatedPhaseData.construction.comments = (cComment) ? cComment : null;

        grantPhases = [];

        for (let i = 0; i <= 7; i++) {
            grantPhases[i] = getGrantPhase(gd, updatedPhaseData, i, disableSaving);
        }

        setPhaseData(updatedPhaseData);
    }

    const setOverride = (override) => {
        if (phaseData.grantPhases && phaseData.grantPhases.length == 0) {
            return;
        }

        if (override != grantData.override) {
            if (!override) {
                resetPhaseData(grantData);
            }

            setGrantData({
                ...grantData,
                override: override
            });

            setState({
                ...state,
                grantModified: true
            });
        }
    }

    if (!context.loginUser) {
        return (<div></div>);
    } else {
        return (
            <>
                <Popup
                    visible={state.showOverridePopup}
                    dragEnabled={false}
                    position="center"
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={true}
                    title={MESSAGES.EXITPAGECONFIRMATION}
                    width={705}
                    height={120}>
                    <br />
                    <div className="container-fluid formReqPanelTop">
                        <div className="row popupRow">
                            <div className="dx-field col-3">
                            </div>
                            <div className="dx-field col-3">
                                <ReactButton
                                    width={100}
                                    text="Continue"
                                    onClick={onOkClick} />
                            </div>
                            <div className="dx-field col-3">
                                <ReactButton
                                    width={100}
                                    text="Cancel"
                                    onClick={onCancelPopupClick} />
                            </div>
                            <div className="dx-field col-3">
                            </div>
                        </div>
                    </div>
                </Popup>
                <Popup
                    visible={state.showModifiedPopup}
                    dragEnabled={false}
                    position="center"
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={true}
                    title="Unsaved Changes"
                    width={525}
                    height={180}>
                    <br />
                    <div style={{ marginLeft: '30px' }}><b>{MESSAGES.EXITPAGECONFIRMATION}</b></div>
                    <br />
                    <div className="container-fluid formReqPanelTop">
                        <div className="row popupRow">
                            <div className="dx-field col-3">
                            </div>
                            <div className="dx-field col-3">
                                <ReactButton
                                    width={100}
                                    text="Continue"
                                    onClick={onOkClick} />
                            </div>
                            <div className="dx-field col-3">
                                <ReactButton
                                    width={100}
                                    text="Cancel"
                                    onClick={onCancelPopupClick} />
                            </div>
                            <div className="dx-field col-3">
                            </div>
                        </div>
                    </div>
                </Popup>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={loadIndicatorVisible}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" style={{ marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                                <div className="grantDetailsCanvas">
                                    <div className="grantDetailsPanelTop">
                                        <GrantDetailHeader grantData={grantData} phaseData={phaseData} disableSaving={disableSaving} setShortInstitution={setShortInstitution} setShortTitle={setShortTitle} setBudgetEnd={setBudgetEnd} />
                                        <GrantDetailPhases grantData={grantData} phaseData={phaseData} disableSaving={disableSaving} setData={setData} setOverride={setOverride} />
                                    </div>
                                    <br />
                                    <div className="settingsButtons">
                                        <ReactButton
                                            className="backButton"
                                            width={100}
                                            text="Back"
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onBackClick.bind(this)} />
                                        <ReactButton
                                            className="saveButton"
                                            width={100}
                                            text="Save"
                                            type="default"
                                            stylingMode="contained"
                                            disabled={disableSaving}
                                            onClick={onSaveClick.bind(this)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }

}

export default withRouter(GrantDetails);