import userApi from './UserApi.js';

class SharedApi {
    serverBaseURL = process.env.REACT_APP_API_URL;

    generateURL(path) {
        if (this.serverBaseURL && this.serverBaseURL != "" && this.serverBaseURL != "/") {
            if (path.startsWith('/') && this.serverBaseURL.endsWith('/'))
                path = path.substring(1);

            return new URL(path, this.serverBaseURL).href;
        } else {
            return path;
        }
    }

    async postSpreadsheet(path, file, onError) {
        try {
            const url = this.generateURL(path);

            const formData = new FormData();
            formData.append('file', file);

            const settings = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                },
                body: formData
            };

            const response = await fetch(url, settings);

            if (response.ok) {
                return (await response.json());
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        } finally {
            console.log(`fetch finished`);
        }
    }

    async post(path, payload, returnJson, onError) {
        try {
            const url = this.generateURL(path);

            const settings = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            };

            const response = await fetch(url, settings);

            if (response.ok) {
                if (returnJson) {
                    return await response.json();
                } else {
                    return (await response.text()).replace(/"/g, '');
                }
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        } finally {
            console.log(`fetch finished`);
        }
    }

    async delete(path, onError) {
        try {
            const url = this.generateURL(path);

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        }
    }

    async get(path, onError, params) {
        try {
            const url = this.generateURL(path);

            if (params) {
                Object.entries(params).forEach(([key, value]) => {
                    if (value !== undefined) {
                        url.searchParams.append(key, value.toISOString());
                    }
                });
            }

            const response = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        }
    }

    async setText(path, data, onError) {
        try {
            const url = this.generateURL(path);
            const settings = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                },
                body: JSON.stringify(data)
            };

            const response = await fetch(url, settings);

            if (response.ok) {
                return await response.text();
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        } finally {
            //console.log(`fetch finished`);
        }
    }

    async setJson(path, data, onError) {
        try {
            const url = this.generateURL(path);
            const settings = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userApi.getAccessToken(),
                },
                body: JSON.stringify(data)
            };

            const response = await fetch(url, settings);

            if (response.ok) {
                return await response.json();
            } else {
                const error = `HTTP error: ${response.status}`;
                console.error(error);
                onError && onError(error);
                return null;
            }
        } catch (error) {
            console.error(error);
            onError && onError(error.message);
            return null;
        } finally {
            //console.log(`fetch finished`);
        }
    }

    hasSubstringAt(str, substr, pos) {
        let idx = 0;
        const len = substr.length;

        for (const max = str.length; idx < len; ++idx) {
            if ((pos + idx) >= max || str[pos + idx] !== substr[idx])
                break;
        }

        return idx === len;
    }

    trim(input, word) {
        let start = 0;
        let end = input.length;
        const len = word.length;

        while (start < end && this.hasSubstringAt(input, word, start))
            start += word.length;

        while (end > start && this.hasSubstringAt(input, word, end - len))
            end -= word.length

        return (start > 0 || end < input.length) ? input.substring(start, end) : input;
    }
}

export default new SharedApi();
