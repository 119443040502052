import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../css/NavMenu.css';

const NavMenu = (props) => {
    const url = props.url;

    const navMenuMain =
        <Nav fill pills>
            <NavItem>
                <NavLink
                    tag={Link}
                    active={url === "/portfolio"}
                    className={"text-link"}
                    to={"/portfolio"}
                >
                    Portfolio
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink
                    tag={Link}
                    active={url === "/dashboard"}
                    className={"text-link"}
                    to={"/dashboard"}
                >
                    Dashboard
                </NavLink>
            </NavItem>
        </Nav>;

    const navMenuAdmin =
        <Nav fill pills>
            <NavItem>
                <NavLink
                    tag={Link}
                    active={url === "/portfolio"}
                    className={"text-link"}
                    to={"/portfolio"}
                >
                    Portfolio
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink
                    tag={Link}
                    active={url === "/dashboard"}
                    className={"text-link"}
                    to={"/dashboard"}
                >
                    Dashboard
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink
                    tag={Link}
                    active={url === "/configuration"}
                    className={"text-link"}
                    to={"/configuration"}
                >
                    Administration
                </NavLink>
            </NavItem>
        </Nav>;

    const navMenuNone = <hr/>;

    const getMenu = (kind) => {
        switch (kind) {
            case "main":
                return navMenuMain;

            case "admin":
                return navMenuAdmin;

            default: 
                return navMenuNone;
        }
    };

    const navMenu = getMenu(props.kind);

    return (
        <>
            {navMenu}
        </>
    );
}

export default NavMenu;
