import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/Settings.css';


const Error = () => {
    const location = useLocation();
    let errorMessage = location.state?.props;

    if (location.pathname === '/oidcnotfound') {
        errorMessage = 'Your account was not found.';
    }

    return (
      <div>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-lg-12 info-page">
                        <div>Cannot proceed. If assistance is needed, contact system administrator.</div>
                        <br />                     
                        <div className="error-text">{errorMessage}</div>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default Error;
