import React, { useContext, useCallback, useState, useRef } from 'react';
import FileManager, { Permissions, Toolbar as FMToolbar, Item as FMItem, FileSelectionItem as FMFileSelectionItem, } from 'devextreme-react/file-manager';
import DataGrid, { Column as DataGridColumn, Editing as DataGridEditing, Item, Toolbar } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import grantApi from '../../api/GrantApi.js';
import '../css/Settings.css';
import moment from 'moment';
import notify from 'devextreme/ui/notify';
import { LoadPanel } from 'devextreme-react/load-panel';
import { STEPS, PHASES, MESSAGES, DATES, STATUS, FILETYPES, ALLOWEDFILEEXTENSIONS, FILEACTION, getGrantPhase, getLastDate, handleError, uploadAccessAllowed } from '../shared/Utils.js';
import { Popup } from 'devextreme-react/popup';
import GrantDetailsFileUpload from './GrantDetailsFileUpload.js';
import { AppContext } from '../shared/AppContext.js';
import Guid from "devextreme/core/guid";

function GrantDetailStep({ grantData, phaseData, updateValues, disableSaving, phaseIndex }) {
    const context = useContext(AppContext);

    let data = phaseData;
    let steps = (data.Steps) ? data.Steps : null;

    const dataFields = (data && data.DataFields) ? data.DataFields : null;
    const phase = (data && data.Phase) ? data.Phase : null;
    const phaseId = (data && data.PhaseId) ? data.PhaseId : null;
    const grantId = (data && data.GrantId) ? data.GrantId : null;
    const constructionDocuments = (data && data.constructionDocuments && data.constructionDocuments.items && data.constructionDocuments.items.length > 0) ? data.constructionDocuments.items[0] : null;

    const grantStepRef = useRef(null);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false);
    const [showUploadPopup, setShowUploadPopup] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(data.AllowRootUpload);
    const [documentDetails, setDocumentDetails] = useState((data && data.DocumentDetails && data.DocumentDetails.length > 0) ? data.DocumentDetails[0].Documents.items : []);
    const [currentPath, setCurrentPath] = useState('Files');
    const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
    const [phaseStep, setPhaseStep] = useState();
    const [selectedUploadFiles, setSelectedUploadFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [deleteFile, setDeleteFile] = useState(false);
    const [editingRowId, setEditingRowId] = useState();
    const [uploadFileSelected, setUploadFileSelected] = useState(false);
    const [loadSPIndicatorVisible, setLoadSPIndicatorVisible] = useState(false);

    async function onError(error) {
        const message = handleError(error);
        console.log(message);
        notify(MESSAGES.FILEUPLOADERROR);
    }

    async function onSelectedFilesChanged(e) {
        const stepId = e.target.id.replace('change', '');
        const file = e.target.files[0];

        let newSelectedFiles = selectedFiles.map(a => ({ ...a }));
        var existingFile = newSelectedFiles.find((nsf) => nsf.stepId === stepId);

        if (existingFile !== undefined) {
            existingFile.file = file;
        } else {
            newSelectedFiles.push({ stepId, file });
        }

        setSelectedFiles(newSelectedFiles);

        // If this step already exists and we're adding a file, then get the step and update it
        var step = steps.find((s) => s.id === stepId);

        if (step !== undefined) {
            // We added a file to an existing step
            const stepDate = step.fileDate;
            const stepComments = step.comments;
            const stepName = step.name;

            await updateStep(file, null, null, stepId, stepDate, stepComments, stepName);
        } else {
            // We added a file to a new step before saving the step
            const stepDate = moment();
            const stepComments = null;
            const stepName = null;

            await updateStep(file, null, null, stepId, stepDate, stepComments, stepName);
        }
    };

    async function uploadSelectedFile(stepId, file) {
        let fileName = file.name;
        const fileDate = file.lastModifiedDate;
        let fileId = null;

        const response = await grantApi.uploadGrantFile(file, grantId, stepId, null, phase, fileName, '', moment(), onError.bind(this));

        let statusText = '';

        if (response != null && response.result != null) {
            const result = response.result;
            const phaseComments = response.phaseComments;
            stepId = response.stepId;
            fileId = result.id;
            fileName = response.result.fileName;

            statusText = MESSAGES.FILEUPLOADSUCCESS;

            // Update step with new file data
            let step = steps.find((s) => s.id === stepId);

            if (step !== undefined) {
                step.fileName = fileName;
                step.fileId = fileId;

                grantStepRef.current.instance.refresh();

                //  Update phase dates
                const phaseName = step.phaseName;
                const fileDate = step.fileDate;

                switch (phaseName) {
                    case PHASES.DC.NAME:
                        // Get LastDate of CD Submissions
                        if (constructionDocuments) {
                            const lastCDSubmissionDate = getLastDate(constructionDocuments.items, DATES.MINIMUM);
                            dataFields.constructionDocuments.completion = await GetPhaseDate(lastCDSubmissionDate);
                        }

                        // Get First Date of DC Submissions
                        let firstDCSubmissionDate = moment();
                        for (let i = 0; i < steps.length; i++) {
                            if (moment(steps[i].fileDate).isBefore(firstDCSubmissionDate)) {
                                firstDCSubmissionDate = steps[i].fileDate;
                            }
                        }

                        dataFields.designCompletion.comments = phaseComments;
                        dataFields.bidding.submitted = firstDCSubmissionDate;
                        break;

                    case PHASES.B.NAME:
                        // Get Last Date of BD Submissions
                        let lastDCSubmissionDate = moment(DATES.MINIMUM);

                        for (let i = 0; i < steps.length; i++) {
                            if (moment(steps[i].fileDate).isAfter(lastDCSubmissionDate)) {
                                lastDCSubmissionDate = steps[i].fileDate;
                            }
                        }

                        dataFields.bidding.comments = phaseComments;
                        dataFields.bidding.completion = await GetPhaseDate(lastDCSubmissionDate);
                        dataFields.construction.submitted = await GetPhaseDate(lastDCSubmissionDate);
                        break;

                    case PHASES.C.NAME:
                        dataFields.construction.comments = phaseComments;
                        break;
                }

                await updateValues(dataFields);
            }

            notify(statusText, 'success', 1000);
        } else {
            statusText = MESSAGES.FILEUPLOADERROR;
            notify(statusText, 'error', 1000);
        }

        return { stepId, fileId, fileName, fileDate };
    };

    function GrantDetailsStepDateColumn(cellData) {
        if (cellData.data.fileDate && cellData.data.fileDate !== '') {
            return moment(cellData.data.fileDate).format(DATES.FORMAT);
        }
        else {
            return '';
        }
    }

    function GrantDetailsStepDocumentEditColumn(cellData) {
        const cellId = (cellData.data.id !== undefined) ? cellData.data.id : new Guid().toString();
        const selectedFile = selectedFiles.find((sf) => sf.stepId === cellId);
        const fileName = (selectedFile !== undefined && selectedFile.file !== null) ? selectedFile.file.name : "";

        if (cellData.data.fileId && cellData.data.fileId != '') {
            return (

                <div className='stepCellEdit'>
                    <input
                        type="file"
                        onChange={onSelectedFilesChanged.bind(this)}
                        height={20}
                        style={{ display: 'none' }}
                        accept="application/pdf"
                        id={cellId + 'change'}
                    />
                    <div style={{ width: '287px' }}>
                        <a href='' id={cellData.data.fileId} onClick={onStepFileClick}>{cellData.data.fileName}</a>
                        <div style={{ left: '2px', position: 'relative', float: 'right' }}>
                            <button disabled={changeButtonDisabled(cellData)} onClick={() => document.getElementById(cellId + 'change').click()} id={cellId + 'changeButton'}>
                                Change File
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='stepCellEdit'>
                    <input
                        type="file"
                        onChange={onSelectedFilesChanged.bind(this)}
                        height={20}
                        style={{ display: 'none' }}
                        accept="application/pdf"
                        id={cellId}
                    />
                    <div style={{ width: '289px' }}>
                        {selectedFile && <span>   {selectedFile.file.name}</span>}
                        <div style={{ float: 'right' }}>
                            <button disabled={chooseButtonDisabled(cellId)} onClick={() => document.getElementById(cellId).click()} id={cellId + 'chooseButton'}>
                                Choose File
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    }


    function GrantDetailsStepDocumentColumn(cellData) {
        const cellId = (cellData.data.id !== undefined) ? cellData.data.id : new Guid().toString();
        const selectedFile = selectedFiles.find((sf) => sf.stepId === cellId);
        const fileName = (selectedFile !== undefined && selectedFile.file !== null) ? selectedFile.file.name : "";

        if (cellData.data.fileId && cellData.data.fileId != '') {
            return (

                <div className='stepCell' >
                    <a href='' id={cellData.data.fileId} onClick={onStepFileClick}>{cellData.data.fileName}</a>
                    <input
                        type="file"
                        onChange={onSelectedFilesChanged.bind(this)}
                        height={20}
                        style={{ display: 'none' }}
                        accept="application/pdf"
                        id={cellId + 'change'}
                    />
                    <div style={{ width: '100px', float: 'right' }}>
                        <button disabled={changeButtonDisabled(cellData)} onClick={() => document.getElementById(cellId + 'change').click()} id={cellId + 'changeButton'}>
                            Change File
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='stepCell' >
                    <input
                        type="file"
                        onChange={onSelectedFilesChanged.bind(this)}
                        height={20}
                        style={{ display: 'none' }}
                        accept="application/pdf"
                        id={cellId}
                    />
                    <div style={{ width: '289px' }}>
                        {selectedFile && <span>   {fileName}</span>}
                        <div style={{ float: 'right' }}>
                            <button disabled={chooseButtonDisabled(cellId)} onClick={() => document.getElementById(cellId).click()} id={cellId + 'chooseButton'}>
                                Choose File
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    };

    function StepsVisible() {
        if (phase == PHASES.SD.NAME ||
            phase == PHASES.DD.NAME ||
            phase == PHASES.CD.NAME ||
            phase == PHASES.RD.NAME) {
            return false;
        } else {
            return true;
        }
    }

    function AllowChanges() {
        if (phase == PHASES.SD.NAME ||
            phase == PHASES.DD.NAME ||
            phase == PHASES.CD.NAME ||
            phase == PHASES.RD.NAME) {
            return false;
        } else {
            return !data.DisableFields;
        }
    }

    function AllowUpdating() {
        if (phase == PHASES.DC.NAME)
            return true;
        return !phaseUploadDisabled();
    }

    function AllowAdding() {
        return !phaseUploadDisabled();
    }

    function AllowDeleting() {
        return AllowChanges();
    }

    async function onStepFileClick(e) {
        if (e && e.target) {
            e.preventDefault();

            if (e.target.id) {
                await grantApi.getStepFile(e.target.id, onError.bind(this));
            }
        }
    }

    async function onStepInserted(e) {
        const stepId = e.data.id;
        const selectedFile = selectedFiles[0];

        var step = steps.find((s) => s.id === stepId);

        if (step !== undefined) {
            if (selectedFile !== undefined) {
                step.fileName = selectedFile.file.name;
                step.fileDate = (step.fileDate == null) ? moment() : step.fileDate;
            }

            await updateStep(null, step.fileName, step.fileDate, step.id, step.fileDate, step.comments, step.name);
        }

        grantStepRef.current.instance.refresh();
    }

    async function onEditCanceled(e) {
        setEditingRowId();
    }

    async function onEditingStart(e) {
        setEditingRowId(e.data.id);
    }

    async function onInitNewRow(e) {
        e.data.id = new Guid().toString();
    }

    async function onStepDeleted(e) {
        let id = (e.data.id) ? e.data.id : null;

        if (id) {
            const response = await grantApi.deleteGrantStep(id, onError);

            if (response != null && response.result != null && response.result == STATUS.SUCCESS) {
                const phaseComments = response.phaseComments;

                switch (phase) {
                    case PHASES.DC.NAME:
                        if (!grantData.override) {
                            dataFields.designCompletion.comments = phaseComments;
                            await updateValues(dataFields);
                        }

                        break;

                    case PHASES.B.NAME:
                        if (!grantData.override) {
                            dataFields.bidding.comments = phaseComments;
                            await updateValues(dataFields);
                        }

                        break;

                    case PHASES.C.NAME:
                        if (!grantData.override) {
                            dataFields.construction.comments = phaseComments;
                            await updateValues(dataFields);
                        }

                        break;
                }
            } else {
                console.log(MESSAGES.ERRORDELETINGSTEP);
                console.log(response);
            }
        }
    }

    async function GetPhaseDate(phaseDate) {
        let formattedDate = moment(phaseDate).format("M/D/YYYY");  
        return ((formattedDate !== DATES.MINIMUM) && (phaseDate !== moment(DATES.MINIMUM))) ? phaseDate : null;
    }

    async function updateStep(file, fileName, fileDate, stepId, stepDate, stepComments, stepName) {
        let fileId = null;
        setLoadIndicatorVisible(true);
        setEditingRowId();

        if (file) {
            const response = await uploadSelectedFile(stepId, file);

            fileId = response.fileId;
            stepId = response.stepId;
            fileName = response.fileName;
            fileDate = response.fileDate;
            stepDate = moment();
        }

        if (stepId || stepComments || stepName) {
            const response = await grantApi.updateGrantStep(stepId, grantId, phaseId, phase, fileId, stepDate, fileName, stepName, fileDate, stepComments, onError);

            // phaseComments
            if (response != null) {
                const phaseComments = response.phaseComments;
                fileId = response.result.fileId;

                var step = steps.find((s) => s.id === stepId);

                if (step !== undefined) {
                    step.id = stepId;
                    step.fileId = fileId;
                    step.fileName = fileName;
                    step.fileDate = stepDate;
                    step.phaseName = phase;
                    step.userId = context.userId;
                    step.userName = context.userName;
                }

                grantStepRef.current.instance.refresh();

                //  Update phase dates
                switch (phase) {
                    case PHASES.DC.NAME:
                        // Get Last Date of CD Submissions
                        if (constructionDocuments) {
                            const lastCDSubmissionDate = getLastDate(constructionDocuments.items, DATES.MINIMUM);
                            dataFields.constructionDocuments.completion = await GetPhaseDate(lastCDSubmissionDate);
                        }

                        // Get First Date of DC Submissions
                        let firstDCSubmissionDate = moment();
                        for (let i = 0; i < steps.length; i++) {
                            if (moment(steps[i].fileDate).isBefore(firstDCSubmissionDate)) {
                                firstDCSubmissionDate = steps[i].fileDate;
                            }
                        }

                        if (!grantData.override) {
                            dataFields.designCompletion.comments = phaseComments;
                        }

                        dataFields.bidding.submitted = firstDCSubmissionDate;
                        break;

                    case PHASES.B.NAME:
                        // Get Last Date of DC Submissions
                        let lastDCSubmissionDate = (steps.length > 0) ? moment(DATES.MINIMUM) : null;

                        for (let i = 0; i < steps.length; i++) {
                            if (moment(steps[i].fileDate).isAfter(lastDCSubmissionDate)) {
                                lastDCSubmissionDate = steps[i].fileDate;
                            }
                        }

                        if (!grantData.override) {
                            dataFields.bidding.comments = phaseComments;
                        }

                        dataFields.bidding.completion = await GetPhaseDate(lastDCSubmissionDate);
                        dataFields.construction.submitted = await GetPhaseDate(lastDCSubmissionDate);
                        break;

                    case PHASES.C.NAME:
                        // Get Last Date of Bidding Submissions
                        let lastBiddingSubmissionDate = moment(DATES.MINIMUM);

                        for (let i = 0; i < steps.length; i++) {
                            if (moment(steps[i].fileDate).isAfter(lastBiddingSubmissionDate)) {
                                lastBiddingSubmissionDate = steps[i].fileDate;
                            }
                        }

                        if (!grantData.override) {
                            dataFields.construction.comments = phaseComments;
                        }

                        dataFields.bidding.completion = await GetPhaseDate(lastBiddingSubmissionDate);
                        dataFields.construction.submitted = await GetPhaseDate(lastBiddingSubmissionDate);
                        break;
                }

                await updateValues(dataFields);
            }
        }

        setLoadIndicatorVisible(false);
    }

    async function onSaved(e) {
        if (e.changes[0] !== undefined && (e.changes[0].type === 'update' || e.changes[0].type === 'insert')) {
            const stepId = e.changes[0].data.id;
            const selectedFile = selectedFiles[selectedFiles.length - 1];

            var step = steps.find((s) => s.id === stepId);

            if (step !== undefined) {
                if (selectedFile !== undefined) {
                    step.fileName = selectedFile.file.name;
                    step.fileDate = (step.fileDate == null) ? moment() : step.fileDate;
                }

                await updateStep(null, step.fileName, step.fileDate, step.id, step.fileDate, step.comments, step.name);
            }
        }

        grantStepRef.current.instance.refresh();

        setEditingRowId();
    }

    async function onStepSaved(e) {
        if (e.changes && e.changes.length > 0 && (e.changes[0].type == 'update' || e.changes[0].type == 'insert')) {
            setEditingRowId();

            const stepDate = (e.changes[0].data.fileDate) ? e.changes[0].data.fileDate : moment();
            const stepComments = (e.changes[0].data.comments) ? e.changes[0].data.comments : null;
            const stepName = (e.changes[0].data.name) ? e.changes[0].data.name : null;

            let stepId = e.changes[0].key;
            if (stepId === undefined || stepId === null) {
                stepId = e.changes[0].key;
            }

            const selectedFile = selectedFiles.find((sf) => sf.stepId === stepId);
            const file = (selectedFile !== undefined) ? selectedFile.file : null;
            let fileName = (file != null) ? file.name : null;
            let lastModifiedDate = (file != null) ? file.lastModifiedDate : null;

            if (fileName == null) {
                fileName = (e.changes[0].data.fileName != null) ? e.changes[0].data.fileName : null;
            }

            if (lastModifiedDate == null) {
                lastModifiedDate = (e.changes[0].data.uploadDate != null) ? e.changes[0].data.uploadDate : null;
            }

            await updateStep(null, fileName, lastModifiedDate, stepId, stepDate, stepComments, stepName);
            grantStepRef.current.instance.refresh();
        }
        else if (e.changes && e.changes.length > 0 && (e.changes[0].type == 'remove')) {
            //  Update phase dates
            switch (phase) {
                case PHASES.DC.NAME:
                    // Get Last Date of CD Submissions
                    if (constructionDocuments) {
                        const lastCDSubmissionDate = getLastDate(constructionDocuments.items, DATES.MINIMUM);
                        dataFields.constructionDocuments.completion = await GetPhaseDate(lastCDSubmissionDate);
                    }

                    // Get First Date of DC Submissions
                    let firstDCSubmissionDate = moment();
                    for (let i = 0; i < steps.length; i++) {
                        if (moment(steps[i].fileDate).isBefore(firstDCSubmissionDate)) {
                            firstDCSubmissionDate = steps[i].fileDate;
                        }
                    }

                    dataFields.bidding.submitted = firstDCSubmissionDate;
                    break;

                case PHASES.B.NAME:
                    // Get Last Date of DC Submissions
                    let lastDCSubmissionDate = (steps.length > 0) ? moment(DATES.MINIMUM) : null;

                    for (let i = 0; i < steps.length; i++) {
                        if (moment(steps[i].fileDate).isAfter(lastDCSubmissionDate)) {
                            lastDCSubmissionDate = steps[i].fileDate;
                        }
                    }

                    dataFields.bidding.completion = await GetPhaseDate(lastDCSubmissionDate);
                    dataFields.construction.submitted = await GetPhaseDate(lastDCSubmissionDate);
                    break;

                case PHASES.C.NAME:
                    // Get Last Date of Bidding Submissions
                    let lastBiddingSubmissionDate = moment(DATES.MINIMUM);

                    for (let i = 0; i < steps.length; i++) {
                        if (moment(steps[i].fileDate).isAfter(lastBiddingSubmissionDate)) {
                            lastBiddingSubmissionDate = steps[i].fileDate;
                        }
                    }

                    dataFields.bidding.completion = await GetPhaseDate(lastBiddingSubmissionDate);
                    dataFields.construction.submitted = await GetPhaseDate(lastBiddingSubmissionDate);
                    break;

                    await updateValues(dataFields);
                    grantStepRef.current.instance.refresh();
            }
        }
    }
    async function onSelectedFileOpened(e) {
        if (e && e.file && e.file.dataItem.id) {
            if (e.file.dataItem.id) {
                await grantApi.getStepFile(e.file.dataItem.id, onError.bind(this));
            }
        } else if (e && e.file && e.file.key) {
            if (e.file.key) {
                await grantApi.getStepFile(e.file.key, onError.bind(this));
            }
        } 
    };

    async function uploadFile(file, parentFolder, newPath) {
        const fileName = file.name;
        const creationDate = moment();
        let statusText = MESSAGES.FILEUPLOADSUCCESS;
        let status = STATUS.SUCCESS;
        
        // Handle files outside of root folder
        if (parentFolder.indexOf('(') > -1) {
            setLoadIndicatorVisible(true);

            const stepName = parentFolder.substring(0, parentFolder.indexOf('(')).trim();

            const response = await grantApi.uploadGrantFile(file, grantId, null, stepName, phase, fileName, '', creationDate, onError);

            if (response) {
                let newDocumentDetails = documentDetails.map(dd => {
                    var temp = Object.assign({}, dd);

                    if (temp.name === parentFolder) {
                        temp.name = stepName + ' (' + temp.items.length + ')';
                        newPath = temp.name;

                        if (stepName === STEPS.E.NAME) {
                            // Set the name in case we had to rename the file due to duplication
                            temp.items[temp.items.length - 1].name = response.result.fileName;
                            temp.items[temp.items.length - 1].userId = context.userId;
                            temp.items[temp.items.length - 1].userName = context.userEmailAddress;
                            temp.items[temp.items.length - 1].id = response.result.id;
                            temp.items[temp.items.length - 1].dateModified = response.result.updateDate;
                        }
                    }

                    return temp;
                });

                setDocumentDetails(newDocumentDetails);
                setCurrentPath(newPath);
                await updateValues(newDocumentDetails, phase, phaseIndex);
            } else {
                statusText = MESSAGES.FILEUPLOADERROR;
                status = STATUS.ERROR;
            }

            setLoadIndicatorVisible(false);
            notify(statusText, status, 1000);
        } else {
            // Handle files in a root folder
            setLoadIndicatorVisible(true);

            const stepName = "";

            const response = await grantApi.uploadGrantFile(file, grantId, null, stepName, phase, fileName, '', creationDate, onError);

            if (response) {
                let newDocumentDetails = structuredClone(documentDetails);

                // Set the name in case we had to rename the file due to duplication
                newDocumentDetails[newDocumentDetails.length - 1].name = response.result.fileName;
                newDocumentDetails[newDocumentDetails.length - 1].userId = context.userId;
                newDocumentDetails[newDocumentDetails.length - 1].userName = context.userEmailAddress;
                newDocumentDetails[newDocumentDetails.length - 1].id = response.result.id;
                newDocumentDetails[newDocumentDetails.length - 1].dateModified = response.result.updateDate;

                setDocumentDetails(newDocumentDetails);
                setCurrentPath(newPath);
                await updateValues(newDocumentDetails, phase, phaseIndex);
            } else {
                statusText = MESSAGES.FILEUPLOADERROR;
                status = STATUS.ERROR;
            }

            setLoadIndicatorVisible(false);
            notify(statusText, status, 1000);
        }

    }

    const onFileUploaded = async (e) => {
        await uploadFile(e.fileData, e.parentDirectory.name, e.component.option('currentPath'));
    }

    const changeButtonDisabled = (cellData) => {
        let changeDisabled = true;

        if (context.userPermissions.allowDeleteAll) {
            changeDisabled = false;
        } else if (cellData.data.userId == context.userId) {
            if (!phaseUploadDisabled()) {
                changeDisabled = false;
            }
        }

        return changeDisabled
    }

    const chooseButtonDisabled = (cellId) => {
        return phaseUploadDisabled();
    }

    const phaseUploadDisabled = () => {
        return !uploadAccessAllowed(grantId, context, phase);
    }

    const fileChangeDeleteAllowed = useCallback((e) => {
        let deleteAllowed = false;

        if (context.userPermissions.allowDeleteAll) {
            deleteAllowed = true;
        } else if (e.row.data.userId == context.userId) {
            deleteAllowed = true;
        }

        return deleteAllowed;
    }, []);

    const onDirectoryChanged = (e) => {
        let showPopup = false;
        let showDialog = false;
        const stepName = e.directory.name;
        let phaseStep = null;

        if (stepName !== '') {
            switch (stepName.substring(0, 1)) {
                case STEPS.A.ABBREV:
                    showDialog = false;
                    showPopup = uploadAccessAllowed(grantId, context, STEPS.A.NUMBER);

                    phaseStep = STEPS.A;
                    break;

                case STEPS.B.ABBREV:
                    showDialog = false;
                    showPopup = uploadAccessAllowed(grantId, context, STEPS.B.NUMBER);
                    phaseStep = STEPS.B;
                    break;

                case STEPS.C.ABBREV:
                    showDialog = false;
                    showPopup = uploadAccessAllowed(grantId, context, STEPS.C.NUMBER);
                    phaseStep = STEPS.C;
                    break;

                case STEPS.D.ABBREV:
                    showDialog = false;
                    showPopup = uploadAccessAllowed(grantId, context, STEPS.D.NUMBER);
                    phaseStep = STEPS.D;
                    break;

                case STEPS.E.ABBREV:
                    showPopup = false;
                    showDialog = uploadAccessAllowed(grantId, context, STEPS.E.NUMBER);
                    phaseStep = STEPS.E;
                    break;

            }
        }

        setDeleteFile(false);
        setShowUploadPopup(showPopup);
        setShowUploadDialog(showDialog);
        setPhaseStep(phaseStep);
        populateSelectedUploadFiles(phaseStep);
        setCurrentPath(e.component.option('currentPath'));
    }

    const addUploadButtonOptions = {
        icon: 'upload',
        text: 'Upload files',
        id: 'fileUploadPopupButton',
        onClick: (e) => {
            setShowFileUploadPopup(true);
        },
    };

    async function getComment  (phaseDocuments) {
        if (phaseDocuments !== undefined) {
            if (phaseDocuments.items) {
                const lastDate = getLastDate(phaseDocuments.items, DATES.MINIMUM);

                if (lastDate === null || lastDate === DATES.MINIMUM) {
                    return "";
                } else {
                    return (phaseDocuments.items) ? "Last Update: " + moment(lastDate).format(DATES.FORMAT) : "";
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    async function onOkPopupClick(e) {
        let statusText = MESSAGES.FILEUPLOADSUCCESS;
        let status = STATUS.SUCCESS;

        setLoadIndicatorVisible(true);

        for (const selectedUploadFile of selectedUploadFiles) {
            if (selectedUploadFile.fileAction == FILEACTION.NEW) {
                const file = selectedUploadFile.file;
                const fileType = selectedUploadFile.fileType;

                // Set file name
                const fileExtension = selectedUploadFile.file.name.split('.').pop();
                const fileTypeAbbrev = FILETYPES.find(({ Name }) => Name == fileType).Abbrev;

                const fileName = `${fileTypeAbbrev}.${fileExtension}`;

                const creationDate = moment();
                let newPath = currentPath;
                const stepName = currentPath.substring(0, currentPath.indexOf('(')).trim();

                const response = await grantApi.uploadGrantFile(file, grantId, null, stepName, phase, fileName, '', creationDate, onError);

                if (response) {
                    const result = response.result;
                    const newDocumentDetails = documentDetails.map(dd => {
                        var temp = Object.assign({}, dd);

                        if (temp.name === currentPath) {
                            // Remove any existing item for this file type
                            for (var i = 0; i < temp.items.length; i++) {
                                const item = temp.items[i];

                                if (item.name.indexOf(fileTypeAbbrev) > -1) {
                                    // Found an existing file for this item, so remove it
                                    temp.items.splice(i, 1);
                                    break;
                                }
                            }

                            // Add new item
                            const newFile = {
                                dateModified: result.updateDate,
                                id: result.id,
                                isDirectory: false,
                                items: [],
                                name: result.fileName,
                                size: result.fileSize,
                                userId: context.userId,
                                userName: context.userEmailAddress
                            }

                            temp.items.push(newFile);

                            // Update path name to have new number
                            temp.name = stepName + ' (' + temp.items.length + ')';
                            newPath = temp.name;
                        }

                        return temp;
                    });

                    setDocumentDetails(newDocumentDetails);

                    await updateValues(newDocumentDetails, phase, phaseIndex);

                    setCurrentPath(newPath);
                } else {
                    statusText = MESSAGES.FILEUPLOADERROR;
                    status = STATUS.ERROR;
                }
            }
        }

        setLoadIndicatorVisible(false);
        notify(statusText, status, 1000);

        setErrorMessage('');
        setUploadFileSelected(false);

        populateSelectedUploadFiles(phaseStep);
        setShowFileUploadPopup(false);
    };

    const onCancelPopupClick = (e) => {
        setErrorMessage('');
        setUploadFileSelected(false);
        populateSelectedUploadFiles(phaseStep);
        setShowFileUploadPopup(false);
    };

    const onItemDownloading = async (e) => {
        if (e && e.item && e.item.dataItem.id) {
            e.cancel = true;

            setLoadSPIndicatorVisible(true);
            await grantApi.getStepFile(e.item.dataItem.id, onError.bind(this));
            setLoadSPIndicatorVisible(false);
        } 
    }

    const onItemDeleting = async (e) => {
        setLoadSPIndicatorVisible(true);

        const fileId = e.item.dataItem.id;
        const parentFolder = e.item.parentPath;

        const response = await grantApi.deleteGrantFile(fileId, onError);

        if (response != null && response == STATUS.SUCCESS) {
            let newPath = currentPath;
            const stepName = parentFolder.substring(0, parentFolder.indexOf('(')).trim();

            const newDocumentDetails = documentDetails.map(dd => {
                var temp = Object.assign({}, dd);

                if (temp.name === parentFolder) {
                    temp.name = stepName + ' (' + temp.items.length + ')';
                    newPath = temp.name;
                }

                return temp;
            });

            setDocumentDetails(newDocumentDetails);

            await updateValues(newDocumentDetails, phase, phaseIndex);

            setCurrentPath(newPath);


            if (stepName !== '') {
                let phaseStep = "";

                switch (stepName.substring(0, 1)) {
                    case STEPS.A.ABBREV:
                        phaseStep = STEPS.A;
                        break;

                    case STEPS.B.ABBREV:
                        phaseStep = STEPS.B;
                        break;

                    case STEPS.C.ABBREV:
                        phaseStep = STEPS.C;
                        break;

                    case STEPS.D.ABBREV:
                        phaseStep = STEPS.D;
                        break;

                    case STEPS.E.ABBREV:
                        phaseStep = STEPS.E;
                        break;

                }

                if (phaseStep != '') {
                    populateSelectedUploadFiles(phaseStep);
                }
            }

            notify(MESSAGES.FILEDELETESUCCESS, STATUS.SUCCESS, 1000);
            e.cancel = false;
        } else if (response != null && response == STATUS.NOPERMISSIONS) {
            notify(MESSAGES.FILEDELETENOPERMISSIONS, STATUS.ERROR, 2000);

            // Add item back to documentDetails
            const newDocumentDetails = documentDetails.map(dd => {
                var temp = Object.assign({}, dd);

                if (temp.name === parentFolder) {
                    temp.items.push(e.item.dataItem);
                }

                return temp;
            });

            setDocumentDetails(newDocumentDetails);

            e.cancel = true;
        } else {
            notify(MESSAGES.FILEDELETEERROR, STATUS.ERROR, 1000);
            e.cancel = true;
        }

        setLoadSPIndicatorVisible(false);
    }

    const populateSelectedUploadFiles = (phaseStep) => {
        let uploadedFiles = [];

        if (phaseStep !== undefined) {
            for (var j = 0; j < documentDetails.length; j++) {
                const documentDetail = documentDetails[j];

                for (var i = 0; i < documentDetail.items.length; i++) {
                    const item = documentDetail.items[i];
                    const fileName = item.name;
                    let fileType = FILETYPES.find((ft) => fileName.indexOf(ft.Abbrev) > -1);

                    if (fileType !== undefined) {
                        uploadedFiles.push(
                            {
                                phaseStep: phaseStep,
                                fileType: fileType.Name,
                                file: item,
                                fileAction: FILEACTION.EXISTING,
                                userId: item.userId
                            }
                        );
                    }
                }
            }
        }

        setSelectedUploadFiles(uploadedFiles);
    }

    const uploadFilesChanged = () => {
        setUploadFileSelected(true);
    }

    const allowedFileExtensions = ALLOWEDFILEEXTENSIONS;

    const customizeDetailColumns = (columns) => {
        // Set file column width
        columns[1].width = 600;

        const userNameColumn = {
            caption: "Owner",
            dataField: "userName",
            width: 230,
            hidingPriority: 1
        };

        columns.push(userNameColumn);

        return columns;
    }

    const onFocusedItemChanged = (e) => {
        if (e.item != null) {
            const userId = e.item.dataItem.userId;

            if ((!e.item.isDirectory) && (context.userPermissions.allowDeleteAll || (userId == context.userId))) {
                setDeleteFile(true);
            } else {
                setDeleteFile(false);
            }
        } else {
            setDeleteFile(false);
        }
    }

    return (
        <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={loadIndicatorVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Popup
                visible={showFileUploadPopup}
                dragEnabled={false}
                position="center"
                hideOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Upload Files"
                width={615}
                height='auto'>
                <br />
                <div className="container-fluid formReqPanelTop">
                    <div className="row filePopupRow">
                        <div className="dx-field col-12">
                            <GrantDetailsFileUpload phaseStep={phaseStep} uploadFilesChanged={uploadFilesChanged} selectedUploadFiles={selectedUploadFiles} setSelectedUploadFiles={setSelectedUploadFiles} grantId={grantId} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
                        </div>
                    </div>
                    <br />
                    <div className="row popupRow">
                        <div className="dx-field col-3">
                            <Button
                                width={100}
                                className="fileUploadOkButton"
                                text="Ok"
                                disabled={!uploadFileSelected}
                                onClick={async (e) => await onOkPopupClick(e)} />
                        </div>
                        <div className="dx-field col-3">
                            <Button
                                width={100}
                                className="fileUploadOkButton"
                                text="Cancel"
                                onClick={onCancelPopupClick} />
                        </div>
                    </div>
                    </div>
            </Popup>

            <div style={{ marginLeft: 0, marginBottom: 0, marginTop: 5 }}>
                <div style={{ marginBottom: 15, display: documentDetails.length > 0 ? 'block' : 'none', cursor: loadSPIndicatorVisible ? 'wait' : 'default' }}>
                    <br />
                    <FileManager  
                        id="spFileManager"
                        currentPath={currentPath}
                        fileSystemProvider={documentDetails}
                        visible={documentDetails.length > 0}
                        selectionMode={'single'}
                        onSelectedFileOpened={onSelectedFileOpened}
                        onCurrentDirectoryChanged={onDirectoryChanged}
                        onFileUploaded={onFileUploaded}
                        onItemDeleting={onItemDeleting}
                        onItemDownloading={onItemDownloading}
                        customizeDetailColumns={customizeDetailColumns}
                        allowedFileExtensions={allowedFileExtensions}
                        onFocusedItemChanged={onFocusedItemChanged}
                    >
                        <FMToolbar>
                            <FMItem name='upload'
                                location="after"
                                visible={showUploadDialog} />
                            <FMItem
                                widget="dxButton"
                                location="after"
                                visible={showUploadPopup}
                                options={addUploadButtonOptions}
                            />
                            <FMItem name='switchView' />
                        </FMToolbar>
                        <Permissions
                            create={false}
                            copy={false}
                            move={false}
                            delete={deleteFile}
                            rename={false}
                            download={true}
                        />
                    </FileManager>
                </div>
                <div style={{ marginBottom: 15, display: ((!documentDetails || documentDetails.length == 0) && (!StepsVisible())) ? 'block' : 'none' }}>
                    <br />
                    <div style={{ border: '1px solid #ddd', width: '100%', paddingTop: '7px', paddingBottom: '7px', paddingLeft: '500px', color: '#999', fontSize: '17px' }}>No data</div>
                </div>

                <DataGrid
                    ref={grantStepRef}
                    dataSource={steps}
                    showBorders={true}
                    visible={StepsVisible()}
                    onSaved={async (e) => await onStepSaved(e)}
                    onRowRemoved={(e) => onStepDeleted(e)}
                    onInitNewRow={(e) => onInitNewRow(e)}
                    onEditingStart={(e) => onEditingStart(e)}
                    onEditCanceled={(e) => onEditCanceled(e)}
                    width={'1140px'}
                    keyExpr='id'
                >
                    <DataGridEditing
                        mode="cell"
                        allowUpdating={AllowUpdating()}
                        allowAdding={AllowAdding()}
                        allowDeleting={fileChangeDeleteAllowed}
                        useIcons={true}
                    />
                    <DataGridColumn
                        dataField="name"
                        width={'200px'}
                        caption="Step"
                        allowEditing={AllowAdding()} />
                    <DataGridColumn
                        dataType="date"
                        dataField="fileDate"
                        width={'100px'}
                        caption="Date"
                        allowEditing={true}
                        cellRender={GrantDetailsStepDateColumn} />
                    <DataGridColumn
                        dataField="userName"
                        width={'100px'}
                        caption="Owner"
                        allowEditing={false} />
                    <DataGridColumn
                        dataField="fileId"
                        width={'330px'}
                        caption="Document Link"
                        cellRender={GrantDetailsStepDocumentColumn}
                        editCellRender={GrantDetailsStepDocumentEditColumn} />
                    <DataGridColumn
                        dataField="comments"
                        width={'260px'}
                        caption="Comments" />

                </DataGrid>
            </div>
        </>
    );
}

export default GrantDetailStep;