import React, { useState, useEffect } from 'react';
import { Button as ReactButton } from 'devextreme-react/button';
import { AppContext } from './AppContext.js';
import { withRouter } from './WithRouter.js';
import FileUploader from 'devextreme-react/file-uploader';
import TextBox from 'devextreme-react/text-box';
import configurationApi from '../../api/ConfigurationApi.js';
import { handleError, UPLOADFILETYPE, UPLOADSTATUS } from './Utils.js';
import notify from 'devextreme/ui/notify';
import '../css/Configuration.css';
import { Link } from "react-router-dom";

const Configuration = (props) => {
    const context = AppContext;


        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12" style={{ marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className="grantVisualizationCanvas">
                                <div className="grantVisualizationPanelTop">
                                    <div className="panelTitle">
                                        Administration
                                    </div>
                                    {/*<hr />*/}
                                    {/*<SpreadsheetUpload*/}
                                    {/*    fileType={UPLOADFILETYPE.TRIMBLE}*/}
                                    {/*    headingLabel="Upload Trimble Data"*/}
                                    {/*    prompt="Please select a spreadsheet with Trimble data for a specific Fiscal Year to upload"*/}
                                    {/*    warning="All prior data for the fiscal year will be overwritten.&nbsp;Please be sure to include all data for the fiscal year."*/}
                                    {/*    invalidMessage="Your file contained more than one fiscal year.  Please select a different file and try again."*/}
                                    {/*    uploadFile={configurationApi.uploadFile} />*/}
                                    <hr />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <SpreadsheetUpload
                                                fileType={UPLOADFILETYPE.EXTERNALUSERS}
                                                headingLabel="Upload External Users Spreadsheet"
                                                prompt="Please select a spreadsheet with External Users to upload"
                                                warning="All prior External Users will be overwritten for grants included in the file."
                                                invalidMessage="Your file contained one or more invalid email addresses.  Please update the file and try again."
                                                uploadFile={configurationApi.uploadFile} />
                                        </div>
                                        <div class="col-md-4">
                                            <div class="downloadTemplate">
                                                <div class="downloadTemplateLink"><Link to="/ExternalUsers_Template.xlsx" target="_blank" download>Download sample template</Link></div>
                                                <div>Reviewers and AE Designers allows multiple emails to be entered using any of the following delimiters: &lt;semicolon&gt; &lt;comma&gt; or &lt;space&gt;. Short institution and project name are optional, blank values for the short names on the import, get ignored.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default withRouter(Configuration);

const SpreadsheetUpload = ({ fileType, headingLabel, prompt, warning, invalidMessage, uploadFile }) => {
    const [selectedFile, setSelectedFile] = useState({});
    const [status, setStatus] = useState('');
    const [fileUploadStatusClass, setFileUploadStatusClass] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);

    const onError = async (error) => {
        const message = handleError(error);

        notify("Error during file upload");
    }

    const onSelectedFilesChanged = (e) => {
        setSelectedFile(e.value[0]);
    };

    const onUploadFileClick = async (e) => {
        const response = await uploadFile(selectedFile, fileType, onError.bind(this));

        let status = '';
        let fileUploadStatusClass = '';
        let errorMessages = [];

        if (response != null) {
            if (response.status == UPLOADSTATUS.SUCCESS) {
                status = 'File successfully uploaded';
                fileUploadStatusClass = 'uploadSuccess';
            } else if (response.status == UPLOADSTATUS.INVALIDDATA) {
                status = 'Your file had some invalid data.  Please update it and try again.';
                errorMessages = response.errorMessages;
                fileUploadStatusClass = 'uploadError';
                notify("Error during file upload");
            } else {
                status = 'File upload error, make sure that the file you are uploading has valid data.';
                fileUploadStatusClass = 'uploadError';
                notify("Error during file upload");
            }
        } else {
            status = 'File upload error, make sure that the file you are uploading has valid data.';
            fileUploadStatusClass = 'uploadError';
            notify("Error during file upload");
        }

        setStatus(status);
        setErrorMessages(errorMessages);
        setFileUploadStatusClass(fileUploadStatusClass);
    }

    return (<>
        <div className="container-fluid panelBody">
            <div className="row grantDetailsRow">
                <div className="dx-field col-12">
                    <div className="dx-field-label"><h4>{headingLabel}</h4></div>
                    <div className="grantDetails-created-value">
                        <span>{prompt}</span>
                        <br />
                        <span>
                            <b>{warning}</b>
                        </span>
                        <FileUploader
                            selectButtonText="Select document"
                            labelText=""
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            uploadMode="instantly"
                            height={50}
                            uploadMode={'useForm'}
                            onValueChanged={onSelectedFilesChanged.bind(this)}
                        />
                    </div>
                </div>
            </div>
            <div className="row grantDetailsRow">
                <div className="dx-field col-6">
                    <TextBox
                        value={(selectedFile) ? selectedFile.name : ''}
                        disabled={true}
                    />
                </div>
                <div className="dx-field col-4">
                    <ReactButton
                        className="saveButton"
                        width={100}
                        text="Upload"
                        type="normal"
                        stylingMode="contained"
                        disabled={(selectedFile) ? (selectedFile.length == 0) : true}
                        onClick={onUploadFileClick.bind(this)} />
                    <br />
                </div>
            </div>
            <div className="row grantDetailsRow">
                <div className="dx-field col-12">
                    <span className={fileUploadStatusClass} ><b>{status}</b></span>
                    <br />
                    <div className='uploadErrorMessages'>{errorMessages.map(message => <p>{message}</p>)}</div>
                </div>
            </div>
        </div>
    </>);
}