import Dashboard from "./components/dashboard/Dashboard";
import Portfolio from "./components/portfolio/Portfolio";
import GrantDetails from "./components/grantDetails/GrantDetails"; 
import Configuration from "./components/shared/Configuration";
import Logout from "./components/shared/Logout";
import Error from "./components/shared/Error";
import OIDCLogin from "./components/shared/OIDCLogin";

const AppRoutes = [
    /*{
        index: true,
        element: <Logout />
    },*/
    {
        path: '/login',
        element: <Logout />
    },
    {
        path: '/login/oidc-callback',
        element: <OIDCLogin />
    },
    {
        path: '/dashboard',
        element: <Dashboard />
    },
    {
        path: '/configuration',
        element: <Configuration />
    },
    {
        index: true,
        path: '/',
        element: <Portfolio />
    },
    {
        path: '/portfolio',
        element: <Portfolio />
    },
    {
        path: '/grantdetails/:id',
        element: <GrantDetails />
    },
    {
        path: '/logout',
        element: <Logout />
    },
    {
        path: '/oidcnotfound',
        element: <Error />
    },
    {
        path: '/oidcerror',
        element: <Error />
    },
    {
        path: '/error',
        element: <Error />
    }
];

export default AppRoutes;
