import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button as ReactButton } from 'devextreme-react/button';
import { DateBox } from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import GrantDetailsTimeline from './GrantDetailsTimeline.js';
import { Popup } from 'devextreme-react/popup';
import GrantDetailsExternalUsers from './GrantDetailsExternalUsers.js';
import { AppContext } from '../shared/AppContext.js';
import ScrollView from 'devextreme-react/scroll-view';


const GrantDetailHeader = ({ grantData, phaseData, disableSaving, setShortInstitution, setShortTitle, setBudgetEnd }) => {
    const context = useContext(AppContext);

    const [showExternalUsersPopup, setShowExternalUsersPopup] = useState(false);

    const onShowExternalUsersPopup = (e) => {
        setShowExternalUsersPopup(true);
    }

    const onOkPopupClick = (e) => {
        setShowExternalUsersPopup(false);
    }

    const getGrantDetailsTitle = () => {
        if (grantData && grantData.fy) {
            return grantData.fy + ' - ' + grantData.institution;
        } else {
            return "";
        }
    }

    useEffect(() => {
        const closeOnEscapePressed = (e) => {
            if (e.key === "Escape") {
                setShowExternalUsersPopup(false);
            }
        };

        window.addEventListener("keydown", closeOnEscapePressed);
        return () =>
            window.removeEventListener("keydown", closeOnEscapePressed);
    }, [])

    if (!grantData) {
        return;
    } else {
        return (
            <>
                <Popup
                    visible={showExternalUsersPopup}
                    dragEnabled={false}
                    position="center"
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={true}
                    title="Manage External Users"
                    width={705}
                    height="auto"
                    maxHeight="90vh" >
                    <ScrollView height="91%" width="100%">
                        <div className="row popupRow">
                            <div className="dx-field col-12">
                                <GrantDetailsExternalUsers grantId={grantData.id} />
                            </div>
                        </div>
                    </ScrollView>
                    <div className="row">
                        <div className="dx-field col-12" style={{ paddingTop: '10px' }}>
                            <ReactButton
                                width={100}
                                className="externalUsersOkButton"
                                text="Ok"
                                onClick={onOkPopupClick} />
                        </div>
                    </div>
                </Popup>
                <TabPanel id='grantVisualizationsPanel'>
                    <Item title={getGrantDetailsTitle()} >
                        <div className="container-fluid panelBody">
                            <div className="row grantDetailsRow">
                                <div className="dx-field col-1">
                                    <div className="dx-field-label">Fiscal Year</div>
                                    <div className="grantDetails-created-value">
                                        <NumberBox
                                            value={grantData.fy}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="dx-field col-2">
                                    <div className="dx-field-label">Budget End Date</div>
                                    <div className="grantDetails-created-value">
                                        <DateBox
                                            type="date"
                                            className="grantDetails-editable"
                                            value={grantData.budgetEndDate}
                                            displayFormat="MM-dd-yyyy"
                                            disabled={disableSaving}
                                            onValueChanged={(e) => { setBudgetEnd(e.value) }}
                                        />
                                    </div>
                                </div>
                                <div className="dx-field col-1">
                                    <div className="dx-field-label">Grant Number</div>
                                    <div className="grantDetails-created-value">
                                        <TextBox
                                            value={grantData.grantNumber}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="dx-field col-4">
                                    <div className="dx-field-label">Institution</div>
                                    <div className="grantDetails-created-value">
                                        <div className="row grantDetailsTitleRow">
                                            <div className="dx-field col-9">
                                                <TextBox
                                                    value={grantData.shortInstitution}
                                                    onChange={(e) => { setShortInstitution(e.event.target.value) }}
                                                    disabled={!context.userPermissions.allowChangeTitleInstitution} />
                                            </div>
                                            <div className="dx-field col-3">
                                                <ReactButton
                                                    className="titleResetButton"
                                                    width={80}
                                                    text="Reset"
                                                    onClick={() => { setShortInstitution(grantData.institution) }}
                                                    disabled={!context.userPermissions.allowChangeTitleInstitution} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dx-field col-4">
                                    <div className="dx-field-label">Project Title</div>
                                    <div className="grantDetails-created-value">
                                        <div className="row grantDetailsTitleRow">
                                            <div className="dx-field col-9">
                                                <TextBox
                                                    value={grantData.shortTitle}
                                                    onChange={(e) => { setShortTitle(e.event.target.value) }}
                                                    disabled={!context.userPermissions.allowChangeTitleInstitution} />
                                            </div>
                                            <div className="dx-field col-3">
                                                <ReactButton
                                                    className="titleResetButton"
                                                    width={80}
                                                    text="Reset"
                                                    onClick={() => { setShortTitle(grantData.title) }}
                                                    disabled={!context.userPermissions.allowChangeTitleInstitution} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row grantDetailsRow">
                                <div className="dx-field col-2">
                                    <div className="dx-field-label">AOR</div>
                                    <div className="grantDetails-created-value">
                                        <TextBox
                                            value={grantData.aor}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="dx-field col-2">
                                    <div className="dx-field-label">GMS</div>
                                    <div className="grantDetails-created-value">
                                        <TextBox
                                            value={grantData.gms}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="dx-field col-2">
                                    <div className="dx-field-label">PI</div>
                                    <div className="grantDetails-created-value">
                                        <TextBox
                                            value={grantData.pi}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="dx-field col-2">
                                    <div className="dx-field-label">PO</div>
                                    <div className="grantDetails-created-value">
                                        <TextBox
                                            value={grantData.po}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="dx-field col-2">
                                    <div className="dx-field-label">FOA/NOFO</div>
                                    <div className="grantDetails-created-value">
                                        <TextBox
                                            value={grantData.foA_NOFO}
                                            disabled={true} />
                                    </div>
                                </div>
                                <div className="dx-field col-2">
                                    <ReactButton
                                        width={202}
                                        disabled={disableSaving}
                                        className="externalUsersButton"
                                        text="Manage External Users"
                                        onClick={onShowExternalUsersPopup}
                                        disabled={!context.userPermissions.allowManageExternalUsers}
                                    />
                                </div>
                            </div>
                        </div>
                    </Item>

                    <Item title='&nbsp;Progress'>
                        <GrantDetailsTimeline phaseData={phaseData} />
                    </Item>
                </TabPanel>
            </>
        );
    }
}

export default GrantDetailHeader;