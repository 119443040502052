import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../shared/AppContext.js';
import PortfolioGrid from './PortfolioGrid';
import { GRANTSTATUS, ROLE } from '../shared/Utils.js';
import { handleError } from '../shared/Utils.js';
import { withRouter } from '../shared/WithRouter.js';
import '../css/Portfolio.css';
import TabPanel, { Item } from 'devextreme-react/tab-panel';


const Portfolio = ({ props }) => {
    const context = useContext(AppContext);

    const [showApplicationsTab, setShowApplicationsTab] = useState(false);

    const showApplicationsTabClass = () =>{
        const showTab = (context.userRole == ROLE.ORF || context.userRole == ROLE.PO || context.userRole == ROLE.SU);
        setShowApplicationsTab(showTab);
    }

    useEffect(() => {
        showApplicationsTabClass();
    });


    if (!context.loginUser) {
        return (<div></div>);
    } else {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12" style={{ marginTop: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
                            <TabPanel id='grantVisualizationsPanel'>
                                <Item title='&nbsp;Awards&nbsp;'>
                                    <PortfolioGrid grantStatus={GRANTSTATUS.AWARDED}></PortfolioGrid>
                                </Item>
                                <Item title='&nbsp;Applications&nbsp;' visible={showApplicationsTab}>
                                    <PortfolioGrid grantStatus={GRANTSTATUS.APPLICATION}></PortfolioGrid>
                                </Item>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const onError = async(error) =>{
        const message = handleError(error);
        props.navigate('/error', { state: { props: message } });
    }
}

export default withRouter(Portfolio);


