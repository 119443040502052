import React, { useEffect, useState } from 'react';
import '../css/Settings.css';
import { useNavigate } from 'react-router-dom';
import userApi from '../../api/UserApi.js';
import configurationApi from '../../api/ConfigurationApi';
import notify from 'devextreme/ui/notify';

const Logout = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        configuration: {},
    });

    useEffect(() => {
        userApi.clearToken();

        (async () => {
            let config = state.configuration;

            if (!state.configuration.oidcConfig) {
                config = await configurationApi.retrieveConfiguration();

                setState({
                    ...state,
                    configuration: config
                });
            }

            if (config.useNIHLogin) {
                const token = userApi.getIDToken();
                let result = await userApi.logoutOIDC(token);

                if (!result)
                    notify("Error during NIH logut");

                document.location.href = config.oidcConfig.logoutRedirectUrl;
            } else {
                navigate('/');
            }
        })();
    }, []);

    return (
        <div>
        </div>
    )
}

export default Logout;
