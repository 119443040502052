import sharedApi from './SharedApi.js';
import { FY } from '../components/shared/Utils.js';

class DashboardApi {
    fiscalYears = [
        '2023',
        '2024',
        'All FY',
        'All Active',
    ];

    async getData(fy, onError) {
        return sharedApi.get(`/api/dashboard/${fy}`, onError);
    }

    async getFiscalYears(onError) {
        let fiscalYears = await sharedApi.get('/api/grant/retrieveFiscalYears', onError);
        fiscalYears.push(FY.ALL);
        fiscalYears.push(FY.ACTIVE);

        return fiscalYears;
    }
}

export default new DashboardApi();
