import React, { Component } from 'react';
import DataGrid, { Column, Editing, FilterRow, Item, Paging, Toolbar, Sorting } from 'devextreme-react/data-grid';
import { AppContext } from '../shared/AppContext.js';
import { handleError } from '../shared/Utils.js';
import { withRouter } from '../shared/WithRouter.js';
import PortfolioGridCell from './PortfolioGridCell.js';
import grantApi from '../../api/GrantApi.js';
import sharedApi from '../../api/SharedApi.js';
import userApi from '../../api/UserApi.js';
import '../css/Portfolio.css';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Popup } from 'devextreme-react/popup';
import { GRANTSTATUS, ROLE } from '../shared/Utils.js';

const dsAwards = (userId) => {
    return createStore({
        key: 'id',
        loadUrl: sharedApi.generateURL('api/portfolio/awards'),
        updateUrl: sharedApi.generateURL('api/portfolio/awards'),
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: true };
            ajaxOptions.headers = { 'Authorization': 'Bearer ' + userApi.getAccessToken() };
        }
    });
}

const dsApplications = (userId) => {
    return createStore({
        key: 'id',
        loadUrl: sharedApi.generateURL('api/portfolio/applications'),
        updateUrl: sharedApi.generateURL('api/portfolio/applications'),
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: true };
            ajaxOptions.headers = { 'Authorization': 'Bearer ' + userApi.getAccessToken() };
        }
    });
}            

class PortfolioGrid extends Component {
    static contextType = AppContext;
    static state = {};

    constructor(props) {
        super(props);

        this.grantStatus = props.grantStatus;
        this.state = {
            loading: true,
            loadingReport: false,
            grants: [],
        };

    }

    renderGrantStatus(cellData) {
        var statusNumber = cellData.data.status;
        var statusText = "";

        switch (statusNumber) {
            case GRANTSTATUS.AWARDED:
                statusText = "Awarded";
                break;

            case GRANTSTATUS.APPLICATION:
                statusText = "Pending Award";

        }

        return (
            statusText
        );
    };

    allowEditingTitle() {
        return (this.context.userRole == ROLE.PO || this.context.userRole == ROLE.PI); 
    };

    renderGrantNumber(cellData) {
        var cellText = cellData.data.grantNumber;

        return (
            <div>
                <span className='GrantNumber'>{cellText}</span>
            </div>
        );
    };

    async getGrantReport(id, value) {
        await grantApi.getGrantReport(id, value, this.onError.bind(this));

        this.setState({ ...this.state, loadingReport: false });
    }

    async onCellClick(e) {
        if (e.columnIndex === 1 && e.rowType !== "filter" && e.rowType !== "header") {
            await this.setState({ ...this.state, loadingReport: true }, () => {
                this.getGrantReport(e.data.id, e.value, this.onError.bind(this));
            });
        }
    }

    populateGrantData(items) {
        return items.map((item) => {
            return {
                ...item,
                grants: item.grants.substring(0, 10),
            };
        });
    }

    portfolioDataSet() {
        return (this.grantStatus === GRANTSTATUS.AWARDED) ? dsAwards(this.context.userId) : dsApplications(this.context.userId);
    }

    render() {
        const data = this.state.grants;

        return (
            <div>
                <Popup
                    visible={this.state.loadingReport}
                    dragEnabled={false}
                    position="center"
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={false}
                    width={220}
                    height={100}>
                    <div className="row">
                        <div className="col-lg-12" style={{ marginTop: '15px', marginLeft: '60px' }}>
                            <img src='Loading.gif' alt='Loading...' style={{ marginLeft: '15px', height: '30px', width: '30px' }} />
                            <div style={{ fontSize: '12pt' }} >Loading...</div>
                        </div>
                    </div>
                </Popup>
                <DataGrid
                    height={window.innerHeight - 200}
                    dataSource={this.portfolioDataSet()}
                    showColumnLines={false}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    onEditorPrepared={this.onEditorPrepared}
                    onCellPrepared={this.onCellPrepared}
                    onCellClick={this.onCellClick.bind(this)}
                    remoteOperations={true}
                >
                    <FilterRow visible={true} applyFilter={"auto"} />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                    />
                    <Paging enabled={false} />
                    <Sorting mode="multiple" />
                    <Column
                        width={'124px'}
                        dataField="applicationId"
                        visible={false}
                    />
                    <Column
                        dataField="fy"
                        width={'45px'}
                        allowEditing={false}
                        sortIndex={0}
                        sortOrder="desc"
                        caption="FY" />
                    <Column
                        dataField="grantNumber"
                        width={'125px'}
                        allowEditing={false}
                        caption="Grant #"
                        cellRender={this.renderGrantNumber} />
                    <Column
                        dataField="shortInstitution"
                        width={'285px'}
                        caption="Institution"
                        allowEditing={false}
                        sortIndex={1}
                        sortOrder="asc"
                        cellRender={PortfolioGridCell} />
                    <Column
                        dataField="shortTitle"
                        allowEditing={this.allowEditingTitle()}
                        width={'667px'}
                        caption="Project Title" />
                    <Column
                        dataField="po"
                        width={'125px'}
                        allowEditing={false}
                        cellRender={PortfolioGridCell}
                        caption="PO" />
                    <Column
                        dataField="gms"
                        width={'125px'}
                        allowEditing={false}
                        cellRender={PortfolioGridCell}
                        caption="GMS" />
                    <Column
                        dataField="status"
                        width={'120px'}
                        allowEditing={false}
                        caption="Status"
                        cellRender={this.renderGrantStatus} />
                </DataGrid>
            </div>
        );
    }

    async populateData() {
        const grants = await grantApi.getGrants(this.onError.bind(this));

        if (grants) {
            this.setState({ grants: grants, loading: false });
        }
    }

    async onError(error) {
        const message = handleError(error);
        this.props.navigate('/error', { state: { props: message } });
    }
}

export default withRouter(PortfolioGrid);

