import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import userApi from '../../api/UserApi.js';
import configurationApi from '../../api/ConfigurationApi.js';
import notify from 'devextreme/ui/notify';

const OIDCLogin = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setState] = useState({
        configuration: {},
    });

    useEffect(() => {
        (async () => {
            let config = state.configuration;

            if (!state.configuration.oidcConfig) {
                config = await configurationApi.retrieveConfiguration();

                setState({
                    ...state,
                    configuration: config
                });
            }

            if (config.useNIHLogin) {
                const code = searchParams.get('code');
                const state = searchParams.get('state');

                if (code) {
                    const token = await userApi.processOIDCAuth(config, code, onError.bind(this));

                    if (token) {
                        navigate('/token/' + token + '/' + encodeURIComponent(state) );
                    } else {
                        navigate('/oidcerror');
                    }
                } else {
                    navigate('/oidcerror');
                }
            } else {
                navigate('/');
            }
        })();

    }, []);

    
    function onError(error) {
        const message = handleError(error);
        this.props.navigate('/error', { state: { props: message } });
    }   
}

export default OIDCLogin;