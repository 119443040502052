import React, { useState, useEffect, useContext } from 'react';
import TextBox from 'devextreme-react/text-box';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { CheckBox } from 'devextreme-react/check-box';
import { Button as ReactButton } from 'devextreme-react/button';
import GrantDetailTitle from './GrantDetailTitle.js';
import GrantDetailStep from './GrantDetailStep.js';
import '../css/Settings.css';
import { AppContext } from '../shared/AppContext.js';
import { PHASES, CLASSES, ROTATE, checkViewAccess } from '../shared/Utils.js';

const GrantDetailPhases = ({ grantData, phaseData, disableSaving, setData, setOverride }) => {
    const context = useContext(AppContext);

    const [showStaticEntries, setShowStaticEntries] = useState(false);
    const [loading, setLoading] = useState(true);

    const getViewClasses = (showStaticEntries, persist, widthContracted, widthExpanded) => {
        if (showStaticEntries) {
            return "dx-field col-" + widthContracted;
        } else if (persist) {
            return "dx-field col-" + widthExpanded;
        } else {
            return "hideFields";
        }
    }

    const onShowStaticEntries = () => {
        setShowStaticEntries(!showStaticEntries);
    }

    const updateValues = async (updatedDataFields, phaseName, phaseIndex) => {
        await setData(updatedDataFields, phaseName, phaseIndex);
    }

    const onOverrideChanged = (e) => {
        setOverride(e.value);
    }

    const getSpinnerClass = () => {
        if (phaseData.grantPhases && phaseData.grantPhases.length == 0) {
            return CLASSES.DETAILSLOADED;
        } else {
            return CLASSES.DETAILSLOADING;
        }
    }

    const getTitleLoaderClass = () => {
        if (phaseData.grantPhases && phaseData.grantPhases.length == 0) {
            return CLASSES.DETAILSLOADING;
        } else {
            return CLASSES.DETAILSLOADEDTIMELINE;
        }
    }

    const getDetailsLoaderClass = () => {
        if (phaseData.grantPhases && phaseData.grantPhases.length == 0) {
            return CLASSES.DETAILSLOADING;
        } else {
            return CLASSES.DETAILSLOADED;
        }
    }

    const phaseVisible = (phaseNumber) => {
        return checkViewAccess(grantData.id, context, phaseNumber);
    }

    const onClickAccordion = (e) => {
        let index = parseInt(e.element.id);
        e.element.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = e.element.nextElementSibling;

        if (panel.style.display === "block") {
            e.element.style.webkitTransform = ROTATE.ZERO;
            e.element.style.mozTransform = ROTATE.ZERO;
            e.element.style.msTransform = ROTATE.ZERO;
            e.element.style.oTransform = ROTATE.ZERO;
            e.element.style.transform = ROTATE.ZERO;

            panel.style.display = "none";
        } else {
            e.element.style.webkitTransform = ROTATE.ONEEIGHTY;
            e.element.style.mozTransform = ROTATE.ONEEIGHTY;
            e.element.style.msTransform = ROTATE.ONEEIGHTY;
            e.element.style.oTransform = ROTATE.ONEEIGHTY;
            e.element.style.transform = ROTATE.ONEEIGHTY;

            panel.style.display = "block";
        }
    }

    if (!grantData.id) {
        return;
    } else {
        //console.log(phaseData);

        return (
            <TabPanel selectedIndex={1} id='grantVisualizationsPanelSmaller' style={{ marginTop: 5 }}>
                <Item disabled={true} className='grantDetailsTabItem' title='&nbsp;Pre-award&nbsp;'>
                </Item>
                <Item className='grantDetailsTabItem' title='&nbsp;Design/Construction&nbsp;'>
                    <div className={getSpinnerClass()} style={{ marginTop: '200px', marginLeft: '580px', height: '300px' }} >
                        <b>Loading Grant Data...</b>
                    </div>
                    <br />
                    <div className="container-fluid panelBody">
                        <div className={getTitleLoaderClass()} style={{ "marginLeft": 45 }}>
                            <TextBox
                                id="noa"
                                value={grantData.noa}
                                visible={false}
                            />

                            <div className="dx-field col-2">
                                <div className="dx-field-label">Design/Construction</div>
                            </div>
                            <div className={getViewClasses(showStaticEntries, false, 1)}>
                                <div className="dx-field-label">NoA +<br />Months</div>
                            </div>
                            <div className={getViewClasses(showStaticEntries, true, 6, 3)}>
                                <div className="row detailsRow">
                                    <div className={getViewClasses(showStaticEntries, false, 3)}>
                                        <div className="dx-field-label">Projected<br />Start</div>
                                    </div>
                                    <div className={getViewClasses(showStaticEntries, false, 3)}>
                                        <div className="dx-field-label">Projected<br />Completion</div>
                                    </div>
                                    <div className={getViewClasses(showStaticEntries, true, 3, 6)}>
                                        <div className="dx-field-label">Start<br />Date</div>
                                    </div>
                                    <div className={getViewClasses(showStaticEntries, true, 3, 6)}>
                                        <div className="dx-field-label">Completion<br />Date</div>
                                    </div>
                                </div>
                            </div>
                            <div className={getViewClasses(showStaticEntries, true, 3, 7)}>
                                <div className="dx-field-label">
                                    <span>Comments</span>
                                    <div style={{ float: 'right' }}>
                                        <ReactButton
                                            className="backButton"
                                            width={100}
                                            height={20}
                                            text={(showStaticEntries) ? "Contract" : "Expand"}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onShowStaticEntries.bind(this)} />
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <div className="grantDetails-created-value">
                                            <CheckBox
                                                disabled={!context.userPermissions.allowOverride}
                                                value={grantData.override}
                                                onValueChanged={onOverrideChanged.bind(this)}
                                            />
                                            <div className="dx-field-label" style={{ width: '90px' }}>Allow Override</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={getDetailsLoaderClass()}>
                            <div id='schematic-design' className={`row grantDetailsRowStepAccordionFirst ${phaseVisible(PHASES.SD.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={0}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='schematic-design-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[0] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='design-development' className={`row grantDetailsRowStepAccordion ${phaseVisible(PHASES.DD.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={1}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='design-development-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[1] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='construction-documents' className={`row grantDetailsRowStepAccordion ${phaseVisible(PHASES.CD.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={2}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='construction-documents-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[2] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={2}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='backcheck-submission' className={`row grantDetailsRowStepAccordion ${phaseVisible(PHASES.BS.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={3}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='backcheck-submission-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[3] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='record-documents' className={`row grantDetailsRowStepAccordion ${phaseVisible(PHASES.RD.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={4}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='record-documents-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[4] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={4}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='design-completion' className={`row grantDetailsRowStepAccordion ${phaseVisible(PHASES.DC.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={5}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='design-completion-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[5] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='bidding' className={`row grantDetailsRowStepAccordion ${phaseVisible(PHASES.B.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={6}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='bidding-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[6] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={6}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='construction' className={`row grantDetailsRowStepAccordion ${phaseVisible(PHASES.C.NUMBER) ? CLASSES.PHASESHOW : CLASSES.PHASEHIDE}`}>
                                <div className="row" style={{ marginLeft: 16 }} >
                                    <div className="dx-field col-12">
                                        <GrantDetailTitle
                                            loading={loading}
                                            updateValues={updateValues}
                                            grantData={grantData}
                                            phaseData={phaseData}
                                            phaseIndex={7}
                                            showStaticEntries={showStaticEntries}
                                            disableFields={disableSaving}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="dx-field col-12">
                                        <ReactButton
                                            id='construction-button'
                                            className="accordionButton"
                                            width={50}
                                            text={'\u2303'}
                                            type="normal"
                                            stylingMode="contained"
                                            onClick={onClickAccordion.bind(this)} />
                                        <div className="panel">
                                            <GrantDetailStep
                                                updateValues={updateValues}
                                                grantData={grantData}
                                                phaseData={(phaseData.grantPhases) ? phaseData.grantPhases[7] : null}
                                                disableSaving={disableSaving}
                                                phaseIndex={7}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                </Item>
                <Item disabled={true} className='grantDetailsTabItem' title='&nbsp;Closeout&nbsp;'>
                </Item>

                <Item disabled={true} className='grantDetailsTabItem' title='&nbsp;Federal Oversight&nbsp;'>
                </Item>

                <Item disabled={true} className='grantDetailsTabItem' title='&nbsp;Programmatic Activities&nbsp;'>
                </Item>

                <Item disabled={true} className='grantDetailsTabItem' title='&nbsp;Documents&nbsp;'>
                </Item>

            </TabPanel>
        );
    }
}

export default GrantDetailPhases;